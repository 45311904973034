import React, { useCallback, useEffect, useState } from "react";
import { useInput } from "react-admin";
import AsyncSelect from "react-select/async";
import axios from 'axios';
import { apiUrl } from "../../dataProvider";
import './index.css';

const CustomStoreSelect = () => {
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState();

  const { input } = useInput({ source: 'store_id' })

  const getStoreOptions = (params) => axios.get(`${apiUrl}/stores`, { params }).then(({ data }) =>  data.data.filter(item => item.is_parent_store))
  const getSearchOptions = value => getStoreOptions({'filter[store_name]': value});

  const setStoreList = useCallback(async(params) => {
      const response = await getStoreOptions(params);
      setList(response);
  }, [])

  useEffect(() => {
      if(input.value) {
          setStoreList({'filter[id]': input.value})
      }
  }, []);

  /** This effect calls initial load with  and when you clear value  */
  useEffect(() => {
      if(!input.value) {
          setStoreList();
      }
  }, [input.value, setStoreList])


  const handleChange = callback => selectedopt => {
      if(searchValue) {
          setStoreList({'filter[store_name]' : searchValue});
      }
      callback(selectedopt?.id);
  }

  return (<AsyncSelect
      {...input}
      isClearable
      cacheOptions
      styles={{
        menu: provided => ({ ...provided, zIndex: 9999 }),
      }}
      defaultOptions={list}
      getOptionLabel={e => e.store_name}
      getOptionValue={e => e.id}
      value={list.find(item => item.id === input.value)} // value must be object, so get selected opt from input.value
      loadOptions={getSearchOptions}
      onChange={handleChange(input.onChange)}
      onInputChange={setSearchValue}
  />)
}

export default CustomStoreSelect;