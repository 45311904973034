import { Box, Card, IconButton, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSignetStudio } from '../SignetStudioContext';
import { extractSvgAttributes, processSvgContent } from '../utils/svgUtils';
import InnerShadowFilter from './svg/InnerShadowFilter';
import TextureFilter from './svg/TextureFilter';

const useStyles = makeStyles((theme) => ({
  variantCard: {
    height: '100%',
    position: 'relative',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fafafa',
    boxShadow: 'none',
    transition: 'all 0.2s ease',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      // borderColor: theme.palette.primary.main,
      '& $deleteButton': {
        opacity: 1,
      },
    },
  },
  materialTitle: {
    padding: theme.spacing(1),
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1rem',
  },
  imageContainer: (props) => ({
    height: props.isLarge ? 350 : 150,
    width: props.isLarge ? 350 : 150,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    '& img, & svg': {
      maxWidth: '90%',
      maxHeight: '90%',
      objectFit: 'contain',
    },
  }),
  deleteButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    opacity: 0,
    transition: 'opacity 0.2s ease',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
    },
  },
  variantInfo: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    gap: theme.spacing(0.75),
    boxSizing: 'border-box',
    alignItems: 'center',
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'auto',
  },
  tag: {
    padding: theme.spacing(0.25, 0.5),
    borderRadius: 12,
    fontSize: '0.7rem',
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.divider}`,
    maxWidth: '32%',
    display: 'inline-block',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  previewContainer: {
    width: '600px',
    height: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

const MATERIAL_TEXTURE_MAP = {
  // Titanium
  T: 'T',

  // Cobalt Chrome
  CC: 'CC',

  // Zirconium
  Z: 'Z',

  // Yellow Gold variants
  '10KY': '14KY',
  '14KY': '14KY',
  '18KY': '14KY',

  // Rose Gold variants
  '14KR': '14KR',
  '18KR': '14KR',

  // White metals
  '10KW': '14KW',
  '14KW': '14KW',
  '18KW': '14KW',
  PLAT: '14KW',
  SILVER: '14KW',
};

const VariantCard = ({
  mapping,
  svgFile,
  onDelete,
  isLarge = false,
  showPathPreview = true,
  className,
  isCapturingImage,
}) => {
  const classes = useStyles({ isLarge });
  const svgRef = useRef(null);
  const gRef = useRef(null);
  const pathRef = useRef(null);
  const [svgContent, setSvgContent] = useState(null);
  const [transforms, setTransforms] = useState({
    contentTransform: '',
    pathTransform: '',
    pathDimensions: null,
    maskTransform: '',
  });
  const [showPreview, setShowPreview] = useState(false);

  const { state, getTextureFromCache } = useSignetStudio();
  const [isHovered, setIsHovered] = useState(false);

  const ringImage = useMemo(() => {
    return mapping?.material?.material_swatch_urls?.[mapping?.shape?.sku]?.[mapping?.size?.sku];
  }, [mapping]);

  const SVG_SIZE = 1200;

  const getShapeConfig = useCallback(
    (shapeSku) => {
      const config = state?.shapeConfigs?.find((config) => config.shape_sku === shapeSku);
      if (!config) return null;

      const sizeConfig = config.sizes.find((size) => size.size_sku === mapping.size?.sku);
      if (!sizeConfig) return null;

      const shankConfig = sizeConfig.shanks.find((shank) => shank.shank_sku === 'T');
      if (!shankConfig) return null;

      return {
        ...shankConfig,
        width: shankConfig.width,
        height: shankConfig.height,
        centerOffsetX: 0,
        centerOffsetY: 0,
        scaleFactor: 1,
      };
    },
    [state.shapeConfigs, mapping.size?.sku],
  );

  const config = useMemo(() => getShapeConfig(mapping?.shape?.sku), [getShapeConfig, mapping?.shape?.sku]);

  // Load SVG content when element becomes visible and file is available
  useEffect(() => {
    if (svgFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = processSvgContent(e.target.result, mapping.color?.hexcode || '#FFF');
        setSvgContent(content);
      };
      reader.readAsText(svgFile);
    }
  }, [svgFile, mapping.color]);

  // Calculate transforms when content is loaded
  useEffect(() => {
    if (!pathRef.current || !config || !gRef.current || !svgContent) return;

    requestAnimationFrame(() => {
      const bbox = pathRef.current.getBBox();
      if (!bbox || !isFinite(bbox.width) || !isFinite(bbox.height)) return;

      // Calculate path transform
      const maxShapeDimension = Math.max(config.width, config.height);
      const pathMaxDimension = Math.max(bbox.width, bbox.height);
      const pathScale = maxShapeDimension / pathMaxDimension;

      const scaledWidth = bbox.width * pathScale;
      const scaledHeight = bbox.height * pathScale;
      const translateX = (SVG_SIZE - scaledWidth) / 2 - bbox.x * pathScale;
      const translateY = (SVG_SIZE - scaledHeight) / 2 - bbox.y * pathScale;

      // Calculate mask transform with padding consideration
      let maskTransform = `translate(${translateX}, ${translateY}) scale(${pathScale})`;

      if (config.padding > 0) {
        const centerX = bbox.x + bbox.width / 2;
        const centerY = bbox.y + bbox.height / 2;
        const paddingScale = 1 - config.padding / 100;

        maskTransform = [
          `translate(${translateX}, ${translateY})`,
          `scale(${pathScale})`,
          `translate(${centerX}, ${centerY})`,
          `scale(${paddingScale})`,
          `translate(${-centerX}, ${-centerY})`,
        ].join(' ');
      }

      // Calculate content transform
      const contentBBox = gRef.current.getBBox();
      if (!contentBBox || !isFinite(contentBBox.width) || !isFinite(contentBBox.height)) return;

      // Additional user scale factor (like if config.scale = 20 => +20%)
      const userScaleFactor = 1 + (config.scale || 0) / 100;

      // The real "available" size is the shape's width/height times userScaleFactor
      const availableW = config.width * userScaleFactor;
      const availableH = config.height * userScaleFactor;

      // Compute how much to scale the shape to fit in that area
      const shapeFitScale = Math.min(
        availableW / contentBBox.width,
        availableH / contentBBox.height
      );

      // Combine with pathScale to align with the main path in the root coordinate system
      const finalShapeScale = shapeFitScale * pathScale;

      // Center the shape in SVG_SIZE × SVG_SIZE
      const finalW = contentBBox.width * finalShapeScale;
      const finalH = contentBBox.height * finalShapeScale;

      const contentTranslateX = (SVG_SIZE - finalW) / 2 - contentBBox.x * finalShapeScale;
      const contentTranslateY = (SVG_SIZE - finalH) / 2 - contentBBox.y * finalShapeScale;

      // Calculate shape's center point for rotation
      const shapeCenterX = contentBBox.x + contentBBox.width / 2;
      const shapeCenterY = contentBBox.y + contentBBox.height / 2;
      
      // Get rotation angle from config
      const rotation = config?.shape_rotation || 0;

      setTransforms({
        pathTransform: `translate(${translateX}, ${translateY}) scale(${pathScale})`,
        contentTransform: [
          `translate(${contentTranslateX}, ${contentTranslateY})`,
          `translate(${shapeCenterX * finalShapeScale}, ${shapeCenterY * finalShapeScale})`,
          `rotate(${rotation})`,
          `translate(${-shapeCenterX * finalShapeScale}, ${-shapeCenterY * finalShapeScale})`,
          `scale(${finalShapeScale})`
        ].join(' '),
        pathDimensions: { width: scaledWidth, height: scaledHeight, scale: pathScale },
        maskTransform: maskTransform,
      });
    });
  }, [config, svgContent]);

  // Get rotation and position from config
  const getTransformConfig = useCallback((config) => {
    if (!config) return { rotation: 0, x: 0, y: 0 };
    return {
      rotation: config.rotation || 0,
      x: config.x || 0,
      y: config.y || 0,
    };
  }, []);

  // Calculate group transform based on hover state
  const getGroupTransform = useCallback(
    (config, isHovered) => {
      if (!config || !isHovered || !transforms.pathDimensions) return '';

      const { rotation, x, y } = getTransformConfig(config);
      return `
        rotate(${-rotation})
        translate(${x}, ${y})
        
    `;
    },
    [transforms.pathDimensions, getTransformConfig],
  );

  const handlePreviewClick = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onDelete();
  };

  // Add background image pattern ID
  const backgroundPatternId = useMemo(
    () => `ring-background-${mapping?.material?.id}-${mapping?.shape?.sku}-${mapping?.size?.sku}`,
    [mapping],
  );

  // Get texture URL based on color SKU
  const textureUrl = useMemo(() => {
    if (!state.textureImages) return null;

    const getTextureUrl = (previewUrl) => {
      if (!previewUrl) return null;
      return getTextureFromCache(previewUrl) || previewUrl;
    };

    // Handle material-based texture for NONE color SKU
    if (mapping.color?.sku === 'NONE' && mapping.material?.sku_code) {
      const textureKey = MATERIAL_TEXTURE_MAP[mapping.material.sku_code];
      return getTextureUrl(state.textureImages?.[textureKey]?.preview_url);
    }

    // Handle regular color-based texture
    return getTextureUrl(state.textureImages[mapping.color?.sku]?.preview_url);
  }, [state.textureImages, mapping.color?.sku, mapping.material?.sku_code, getTextureFromCache]);

  const renderSVG = (isHovered) => {
    if (!svgFile || !svgContent) return null;

    // Treat the SVG as hovered when capturing
    const isEffectivelyHovered = isHovered || isCapturingImage;

    return (
      <svg
        {...extractSvgAttributes(svgContent)}
        width="100%"
        height="100%"
        viewBox={`0 0 ${SVG_SIZE} ${SVG_SIZE}`}
        preserveAspectRatio="xMidYMid meet"
        style={{
          border: showPathPreview && !isEffectivelyHovered ? '1px solid #CCC' : 'none',
          padding: showPathPreview && isHovered && !isCapturingImage ? '1px' : '0',
        }}
        className={`variant-svg ${className || ''}`}
        data-shape={mapping.shape.sku}
        data-size={mapping.size.sku}
        data-color={mapping.color.sku}
        data-material={JSON.stringify({
          id: mapping.material.id,
          name: mapping.material.name,
          sku: mapping.material.sku_code,
        })}
        data-shank="T"
        data-school={state.selectedEngraving?.school || ''}
        data-engraving={state.selectedEngraving?.sku_code || ''}
      >
        <defs>
          <InnerShadowFilter id={`innerShadow-${mapping.key}`} shadowColor={'#000000'} strength={config?.shadow || 'standard'} />
          <TextureFilter
            id={`texture-${mapping.key}`}
            textureUrl={textureUrl}
            color={mapping.color?.hexcode || '#FFF'}
          />
          {/* Only add pattern if not capturing and is hovered */}
          {isHovered && !isCapturingImage && ringImage && (
            <pattern
              id={`${backgroundPatternId}-${mapping.key}`}
              patternUnits="userSpaceOnUse"
              width={SVG_SIZE}
              height={SVG_SIZE}
            >
              <image
                href={decodeURIComponent(ringImage)}
                width={SVG_SIZE}
                height={SVG_SIZE}
                preserveAspectRatio="xMidYMid meet"
              />
            </pattern>
          )}
          <mask
            id={`mask-${mapping.key}`}
            maskUnits="userSpaceOnUse"
            maskContentUnits="userSpaceOnUse"
            x="0" y="0" width="1200" height="1200"
          >
            <rect x="0" y="0" width="1200" height="1200" fill="black" />
            <path 
              d={config?.path}
              fill="white"
              transform={transforms.maskTransform} 
            />
          </mask>
        </defs>

        {/* Only show background ring image if not capturing and is hovered */}
        {isHovered && !isCapturingImage && ringImage && (
          <rect width={SVG_SIZE} height={SVG_SIZE} fill={`url(#${backgroundPatternId}-${mapping.key})`} />
        )}

        {/* Rest of the SVG content */}
        <g transform={getGroupTransform(config, isEffectivelyHovered)} style={{ transformOrigin: 'center' }}>
          <g transform={transforms.pathTransform}>
            <path
              ref={pathRef}
              d={config?.path}
              width={config?.width}
              height={config?.height}
              stroke={showPathPreview && !isEffectivelyHovered ? '#000' : 'transparent'}
              fill="transparent"
            />
          </g>
          <g
            mask={config?.padding > 0 ? `url(#mask-${mapping.key})` : null}
          >
            <g
              ref={gRef}
              transform={transforms.contentTransform}
              filter={`url(#texture-${mapping.key}) url(#innerShadow-${mapping.key})`}
              dangerouslySetInnerHTML={{
                __html: svgContent.replace(/<svg[^>]*>|<\/svg>/g, ''),
              }}
            />
          </g>
        </g>
      </svg>
    );
  };

  return (
    <>
      <Card
        className={classes.variantCard}
        onClick={handlePreviewClick}
        key={`${mapping?.shape?.sku}-${mapping?.material?.sku_code}-${mapping?.color?.sku}-${mapping?.size?.sku}`}
      >
        <IconButton className={classes.deleteButton} onClick={handleDelete} size="small">
          <DeleteIcon fontSize="small" />
        </IconButton>
        {isLarge && (
          <Typography variant="h6" className={classes.materialTitle}>
            {mapping.material.name}
          </Typography>
        )}
        <div
          className={classes.imageContainer}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          ref={svgRef}
        >
          {renderSVG(isHovered && !showPreview)}
        </div>
        <div className={classes.variantInfo}>
          <span className={classes.tag} title={String(mapping.material?.name || '')}>
            {String(mapping.material?.name || '')}
          </span>
          <span className={classes.tag} title={String(mapping.size?.name || '')}>
            {String(mapping.size?.name || '')}
          </span>
          <span className={classes.tag} title={String(mapping.color?.school_color_name || mapping.color?.name || '')}>
            {String(mapping.color?.school_color_name || mapping.color?.name || '')}
          </span>
        </div>
      </Card>
      <Modal open={showPreview} onClose={handleClosePreview} className={classes.modal}>
        <Box className={classes.modalContent}>
          <IconButton className={classes.closeButton} onClick={handleClosePreview}>
            <CloseIcon />
          </IconButton>
          <Box
            className={classes.previewContainer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {renderSVG(isHovered && showPreview)}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default VariantCard;
