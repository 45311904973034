import React from "react";
import {
    Create,
    Notification,
    NumberInput,
    SimpleForm,
    useNotify,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import CreateToolbar from "../../Custom/create-save-button";
import {
    validateNumberGreaterThanZero,
    validatePositiveNumber,
    validateRequired
} from "../../../utils/Validations";
import { ThemeProvider } from "@material-ui/styles";

import { makeStyles } from "@material-ui/core/styles";
import { CustomBooleanInput, SignetShapeResources, SignetSizeResources, SignetShankResources, SignetFinishesDataResources } from "../util";
import CustomSchoolSelect from "../CustomSchoolSelect";
import '../style.css';
const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

const SignetRingCreate = props => {
    const classes = useStyles();

    const notify = useNotify();
    const onFailure = (error) => {
        notify(error.response.data.message ? error.response.data.message : 'Something went wrong', { type: 'warning' });
    };
    return (
        <ThemeProvider>
            <Create onFailure={onFailure} {...props} className="overflow-visible" >
                <SimpleForm toolbar={<CreateToolbar />} >
                    <Grid container className={classes.container} justify="space-between">

                        <Grid item xs={5}>
                            <p className="label">Shank</p>
                            <CustomSchoolSelect idsource='shank_id' source="shank" resource={SignetShankResources} validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <p className="label">First Finish Group</p>
                            <CustomSchoolSelect idsource='first_finish_group_id' source="first_finish_group" resource={SignetFinishesDataResources} validate={[validateRequired]} fullWidth/>
                        </Grid>
                      
                       
                        <Grid item xs={5}>
                            <p className="label">Size</p>
                            <CustomSchoolSelect  idsource='size_id' source="size" resource={SignetSizeResources} validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <p className="label">Second Finish Group</p>
                            <CustomSchoolSelect idsource='second_finish_group_id' source="second_finish_group" resource={SignetFinishesDataResources} validate={[validateRequired]} fullWidth/>
                        </Grid>
                        
                        <Grid item xs={5}>
                            <p className="label">Shape</p>
                            <CustomSchoolSelect idsource='shape_id' source="shape" resource={SignetShapeResources} validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <p className="label">Inlay Finish Group</p>
                            <CustomSchoolSelect idsource='inlay_finish_group_id' source="inlay_finish_group" resource={SignetFinishesDataResources} />
                        </Grid>
                      
                       
                       
                        <Grid item xs={5}>
                            <NumberInput label="Inlay Volume" source="inlay_volume" defaultValue="0" validate={[validateRequired,validatePositiveNumber]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberInput label="Thickness" source="thickness" defaultValue="0"  validate={[validateRequired,validatePositiveNumber]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberInput label="Inlay Thickness" source="inlay_thickness" defaultValue="0"  validate={[validateRequired,validatePositiveNumber]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberInput source="volume_size_3" label="Volume Size 3" defaultValue="0" validate={[validateRequired,validatePositiveNumber]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberInput source="volume_size_10" label="Volume Size 10" defaultValue="0" validate={[validateRequired,validatePositiveNumber]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberInput source="volume_size_17" label="Volume Size 17" defaultValue="0" validate={[validateRequired,validatePositiveNumber]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberInput label="Min Finger Size" source="min_finger_size" defaultValue="0" validate={[validateRequired, validateNumberGreaterThanZero]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberInput label="Max Finger Size" source="max_finger_size" defaultValue="0" validate={[validateRequired, validateNumberGreaterThanZero]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomBooleanInput source="allow_machine_pattern" label="LCV"  />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomBooleanInput source="allow_inlay" label="Allow Inlay" />
                        </Grid>
                    

                    </Grid>
                </SimpleForm>
            </Create>
            <Notification />
        </ThemeProvider>
    )
};

export default SignetRingCreate;