import React, { forwardRef } from "react";
import { useLogout, MenuItemLink } from "react-admin";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";

const LogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = () => logout();

  return (
    <MenuItemLink
        onClick={handleClick}
        to="/login"
        ref={ref}
        primaryText="Logout"
        leftIcon={<ExitIcon/>}
    />
  );
});

export default LogoutButton;
