import React from "react";
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  SaveButton,
} from "react-admin";

const CustomToolbar = props => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
);

const ShippingEdit = props => (
    <Edit {...props} title={props.resource === "account/shipping" ? "Store Shipping" : "Guest Shipping"}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextField source="service" />
        <TextInput source="cost" />
      </SimpleForm>
    </Edit>
);


export default ShippingEdit;
