import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  SingleFieldList,
  ChipField,
  EditButton,
  DeleteButton,
  Filter,
  TextInput,
  ReferenceArrayField,
  Loading
} from "react-admin";
import CustomBulkActionButtons from "../Custom/CustomBulkActionsButtons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  email: {
    textDecoration: "none",
    color: theme.palette.primary.dark
  },
  chips: {
    backgroundColor: theme.palette.secondary.light
  }
}));

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" defaultValue="" />
    <TextInput label="Email" source="email" defaultValue="" />
  </Filter>
);

const UserList = ({ permissions, ...props }) => {
  const classes = useStyles();

  if (!permissions) return <Loading />;
  const permissionsList = permissions.split(",");

  return (
    <List
      {...props}
      title="Users"
      filters={<PostFilter />}
      bulkActionButtons={
        permissionsList.includes("delete_users") ? (
          <CustomBulkActionButtons />
        ) : (
          false
        )
      }
      exporter={false}
    >
      <Datagrid rowClick={null}>
        <TextField source="name" />
        <TextField source="email" className={classes.email} />
        <DateField source="created_at" showTime />
        <ReferenceArrayField reference="roles" source="roles" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" className={classes.chips} />
          </SingleFieldList>
        </ReferenceArrayField>
        {permissionsList.includes("edit_users") && <EditButton />}
        {permissionsList.includes("delete_users") && (
          <DeleteButton undoable={false} />
        )}
      </Datagrid>
    </List>
  );
};

export default UserList;
