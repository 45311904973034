import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import GetAWSMetrics from "../GetAWSMetrics";
import DateFilters from "../DateFilters";
import Latency from "./Latency";
import EnvironmentHealth from "./EnvironmentHealth";
import TotalRequests from "./TotalRequest";
import InstanceHealthLine from "./InstanceHealthLine";
import {HEALTH_COLORS} from "../Constants";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 18,
    },
    pos: {
        marginBottom: 12,
    },
});

const AWSMetrics = () => {
    const classes = useStyles();
    const {
        loading,
        latencyTrend,
        envHealth,
        totalRequestsTrend,
        nodataInstanceTrend,
        unknownInstanceTrend,
        pendingInstanceTrend,
        warningInstanceTrend,
        severeInstanceTrend,
        okInstanceTrend,
        degradedInstanceTrend,
    } = GetAWSMetrics();

    if (loading) return null;

    return (
        <Grid container justifyContent="space-evenly" spacing={3}>
            <Grid xs={12} item>
                <DateFilters />
            </Grid>
            <Grid xs={12} item>
                <Latency data={latencyTrend} />
                <Typography className={classes.title} color="textSecondary" align="center">
                    Latency
                </Typography>
            </Grid>
            <Grid xs={6} item>
                <EnvironmentHealth data={envHealth} />
                <Typography className={classes.title} color="textSecondary" align="center">
                    Environment Health
                </Typography>
            </Grid>
            <Grid xs={6} item>
                <TotalRequests data={totalRequestsTrend} />
                <Typography className={classes.title} color="textSecondary" align="center">
                    Total Requests
                </Typography>
            </Grid>
            <Grid xs={8} item>
                <Typography className={classes.title} color="textSecondary" align="center">
                    Instance Health
                </Typography>
            </Grid>
            <Grid xs={6} item>
                <InstanceHealthLine data={okInstanceTrend} color={HEALTH_COLORS.ok} dataKey="okInstance" />
            </Grid>
            <Grid xs={6} item>
                <InstanceHealthLine data={nodataInstanceTrend} color={HEALTH_COLORS.nodata} dataKey="nodataInstance" />
            </Grid>
            <Grid xs={6} item>
                <InstanceHealthLine data={unknownInstanceTrend} color={HEALTH_COLORS.nodata} dataKey="unknownInstance" />
            </Grid>
            <Grid xs={6} item>
                <InstanceHealthLine data={pendingInstanceTrend} color={HEALTH_COLORS.nodata} dataKey="pendingInstance" />
            </Grid>
            <Grid xs={6} item>
                <InstanceHealthLine data={warningInstanceTrend} color={HEALTH_COLORS.warning} dataKey="warningInstance" />
            </Grid>
            <Grid xs={6} item>
                <InstanceHealthLine data={severeInstanceTrend} color={HEALTH_COLORS.severe} dataKey="severeInstance" />
            </Grid>
            <Grid xs={6} item>
                <InstanceHealthLine data={degradedInstanceTrend} color={HEALTH_COLORS.degraded} dataKey="degradedInstance" />
            </Grid>
        </Grid>
    )
};

export default AWSMetrics;