import React, {useCallback, useState} from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CategoryToolbar from "./CustomSave";
import WithProps from "../Custom/WithProps";
import {validateRequired} from "../../utils/Validations";
import { SelectInput } from "react-admin";

const useStyles = makeStyles(() => ({
    root: {
        width: "90%",
        margin: "auto"
    },
    warnLabel: {
        fontSize: "10px",
        color: "red",
        display: "block"
    },
}));

const CategoryEdit = props => {
    const classes = useStyles();
    const [images, setImages] = useState({});
    const [checked, setChecked] = useState();
    const [filters, setFilters] = useState();
    const [hidden, setHidden] = useState();


    const handleChange = useCallback(newChecked => setChecked(newChecked), []);
    const handleFilterChange = useCallback(newChecked => setFilters(newChecked), []);
    const handleHiddenChange = useCallback(newChecked => setHidden(newChecked), []);

    const handleImageChange = image => {
        setImages(oldValue => ({...oldValue, [image]: true}))
    };

    return <Edit {...props}>
        <WithProps>
            {({record, ...props}) => (
                <SimpleForm record={record} {...props} toolbar={<CategoryToolbar action="edit"/>}>
                    <Grid
                        container
                        spacing={2}
                        className={classes.root}
                        justify="space-between"
                    >
                        <Grid item xs={6}>
                            <TextInput source="name" validate={validateRequired} />
                        </Grid>
                        <Grid item xs={6}>
                            <BooleanInput
                                source="active"
                                label={((typeof checked === 'undefined' && record.active) || checked) ? "Active" : "Inactive"}
                                onChange={handleChange}
                                initialValue={record.active}
                                parse={v => (v ? 1 : 0)}
                            />
                            <BooleanInput
                                source="display_filters"
                                label={((typeof filters === 'undefined' && record.display_filters) || filters) ? "Filters Enabled" : "Filters Disabled"}
                                onChange={handleFilterChange}
                                initialValue={record.display_filters}
                                parse={v => (v ? 1 : 0)}
                            />
                            <BooleanInput
                                source="hidden"
                                label= "Hidden"
                                onChange={handleHiddenChange}
                                initialValue={record.hidden}
                                parse={v => (v ? 1 : 0)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="category_sku" validate={validateRequired} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="details_link_url" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="swatch_url" />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput
                                source="section"
                                choices={[
                                    { id: 'materials', name: 'Materials' },
                                    { id: 'mens', name: 'Mens' },
                                    { id: 'womens', name: 'Womens' },
                                ]}
                                validate={validateRequired}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput source="tagline" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput source="description"fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageInput
                                source="icon_image_url"
                                accept="image/png"
                                maxSize={2000000}
                                validate={validateRequired}
                                options={{ onDrop: () => handleImageChange('icon_image_url') }}
                                placeholder={
                                    <p>
                                        Upload Image
                                        <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                    </p>
                                }
                            >
                                <ImageField source="src" title="icon_image_url" />
                            </ImageInput>
                            {!images.hasOwnProperty('icon_image_url') && (
                                <img
                                    src={record.icon_image_url}
                                    width={250}
                                    height={250}
                                    alt="static ring"
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <ImageInput
                                source="ring_image"
                                accept="image/png"
                                maxSize={2000000}
                                validate={validateRequired}
                                options={{ onDrop: () => handleImageChange('ring_image') }}
                                placeholder={
                                    <p>
                                        Upload Image
                                        <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                    </p>
                                }
                            >
                                <ImageField source="src" title="ring_image" />
                            </ImageInput>
                            {!images.hasOwnProperty('ring_image') && (
                                <img
                                    src={record.ring_image}
                                    width={250}
                                    height={250}
                                    alt="static ring"
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <ImageInput
                                source="background_image"
                                accept="image/png"
                                maxSize={2000000}
                                validate={validateRequired}
                                options={{ onDrop: () => handleImageChange('background_image') }}
                                placeholder={
                                    <p>
                                        Upload Image
                                        <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                    </p>
                                }
                            >
                                <ImageField source="src" title="background_image" />
                            </ImageInput>
                            {!images.hasOwnProperty('background_image') && (
                                <img
                                    src={record.background_image}
                                    width={250}
                                    height={250}
                                    alt="static ring"
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <ImageInput
                                source="header_background_image"
                                options={{ onDrop: () => handleImageChange('header_background_image') }}
                                accept="image/png"
                                maxSize={2000000}
                                validate={validateRequired}
                                placeholder={
                                    <p>
                                        Upload Image
                                        <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                    </p>
                                }
                            >
                                <ImageField source="src" title="header_background_image" />
                            </ImageInput>
                            {!images.hasOwnProperty('header_background_image') && (
                                <img
                                    src={record.header_background_image}
                                    width={250}
                                    height={250}
                                    alt="static ring"
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <ImageInput
                                source="footer_background_image"
                                options={{ onDrop: () => handleImageChange('footer_background_image') }}
                                accept="image/png"
                                maxSize={2000000}
                                validate={validateRequired}
                                placeholder={
                                    <p>
                                        Upload Image
                                        <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                    </p>
                                }
                            >
                                <ImageField source="src" title="footer_background_image" />
                            </ImageInput>
                            {!images.hasOwnProperty('footer_background_image') && (
                                <img
                                    src={record.footer_background_image}
                                    width={250}
                                    height={250}
                                    alt="static ring"
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <ImageInput
                                source="footer_banner_image"
                                accept="image/png"
                                maxSize={2000000}
                                validate={validateRequired}
                                options={{ onDrop: () => handleImageChange('footer_banner_image') }}
                                placeholder={
                                    <p>
                                        Upload Image
                                        <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                    </p>
                                }
                            >
                                <ImageField source="src" title="footer_banner_image" />
                            </ImageInput>
                            {!images.hasOwnProperty('footer_banner_image') && (
                                <img
                                    src={record.footer_banner_image}
                                    width={250}
                                    height={250}
                                    alt="static ring"
                                />
                            )}
                        </Grid>
                    </Grid>
                </SimpleForm>
            )}
        </WithProps>
    </Edit>
};


export default CategoryEdit;