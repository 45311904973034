import React, {useState} from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    BooleanInput,
    SelectInput,
    PasswordInput, AutocompleteInput,
    useDataProvider, useNotify
} from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import theme from "../../customTheme";
import CreateToolbar from "./AccountCreateButton";
import {
    validateRequired as validation,
    validateEmail,
    validateUsername,
} from "../../utils/Validations";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

export const StoreLocationCreate = props => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isParentStore, setParent] = useState(false);

    const selection = store_id => {
        dataProvider.getOne('stores', { id: store_id })
            .then(({ data }) => {
                setParent(data.is_parent_store);
            })
            .catch(error => {
                notify("Something went wrong, please refresh", 2000);
            })
    };

    return (
        <Create {...props}>
            <SimpleForm toolbar={<CreateToolbar isParentStore={isParentStore}/>}>
                <Grid container className={classes.container} justify="space-between">
                    <Grid item xs={5}>
                        <TextInput source="name" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="store_number"  fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="netsuite_id"  fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="address" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="city" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="state" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="zip" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="country" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="phone" fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="website" fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="logo"  fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="hours_open"  fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="lat" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="lng" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="materials" fullWidth/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
)};