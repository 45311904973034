import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SingleFieldList,
  ChipField,
  EditButton,
  ReferenceArrayField,
  Loading
} from "react-admin";
import "../../App.css";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  chips: {
    backgroundColor: theme.palette.secondary.light
  }
}));

const RoleList = ({ permissions, ...props }) => {
  const classes = useStyles();

  if (!permissions) return <Loading />;
  const permissionsList = permissions.split(",");

  return (
    <List {...props} title="Roles" bulkActionButtons={false} exporter={false}>
      <Datagrid rowClick={null}>
        <TextField source="name" />
        <ReferenceArrayField
          reference="permissions"
          source="permissions"
          sortable={false}
          perPage={50}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" className={classes.chips} />
          </SingleFieldList>
        </ReferenceArrayField>
        {permissionsList.includes("edit_users") && <EditButton />}
      </Datagrid>
    </List>
  );
};

export default RoleList;
