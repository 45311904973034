import React from "react";
import { Datagrid, DeleteButton, List, Loading, TextField, TextInput, EditButton } from "react-admin";
import ListActions from "../Signet/ListActions";

const postFilters = [
    <TextInput source="title" defaultValue="" />,
    <TextInput source="magic_number" defaultValue="" />,
    <TextInput source="ring_material" defaultValue="" />
];

const CustomPricingList = props => {
    const { permissions } = props;

    if (!permissions) return <Loading />;
    return (
        <List
            {...props}
            exporter={false}
            actions={<ListActions filters={postFilters} hideImportExport={true} />}
        >
            <Datagrid rowClick={null}>
                <TextField source="title" sortable={false}  />
                <TextField source="magic_number" sortable={false}  />
                <TextField source="ring_material" sortable={false} />
                <TextField source="thickness" sortable={false} />
                <TextField source="price" sortable={false} />
                <TextField source="weight" sortable={false} />
                <TextField source="volume" sortable={false} />
                {/* <ShowButton  /> */}
                <EditButton />
                <DeleteButton label="" undoable={false} />
            </Datagrid>
        </List>
    );
};

export default CustomPricingList;