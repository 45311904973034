import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer} from "recharts";
import React from "react";
import {GRAPH_COLORS, labelFormatter} from "../Constants";

const TotalSessions = ({ trends }) => (
    <ResponsiveContainer width="100%" height={250}>
        <BarChart
            width={500}
            data={trends}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis stroke={GRAPH_COLORS.solid1} />
            <Tooltip labelFormatter={labelFormatter} />
            <Legend />
            <Bar dataKey="sessions" fill={GRAPH_COLORS.solid1} />
        </BarChart>
    </ResponsiveContainer>
);

export default TotalSessions;