import React from "react";
import {FunnelChart, Tooltip, Funnel, LabelList, ResponsiveContainer} from "recharts";

const TopBrowsers = ({ data= [] }) => {
    return (
        <ResponsiveContainer width="100%" height={250}>
                <FunnelChart width={500} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <Tooltip />
                    <Funnel
                        dataKey="value"
                        data={data}
                        isAnimationActive
                    >
                        <LabelList position="right" fill="#000" stroke="none" dataKey="name" />
                    </Funnel>
                </FunnelChart>
        </ResponsiveContainer>
    );
}

export default TopBrowsers;