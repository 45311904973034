
import React, {useCallback} from "react";
import { SaveButton, Toolbar, useCreate, useRedirect, useNotify } from "react-admin";
import { useForm } from 'react-final-form';

const CustomSave = ({ handleSubmitWithRedirect, resource, ...props }) => {
    const [create] = useCreate(resource);
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath } = props;
    const form = useForm();

    const handleClick = useCallback(() => {
        const values = form.getState().values;
        values.sku_code = values.sku_code.split(',').map(item => item.trim());
        values.material = values.material.map(item => item.value);

        create(
            {
                payload: { data: { ...values } },
            },
            {
                onSuccess: ({ data }) => {
                    notify(data.message, 2000);
                    redirectTo(basePath);
                },
                onFailure: ({response: {data: {errors}}}) => {
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message, 2000);
                },
            }
        );

    },[form]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} disabled={form.getState().hasValidationErrors} />;
};

export const CreateMaterialToolbar = props => (
    <Toolbar {...props}>
        <CustomSave
            redirect="list"
            submitOnEnter={false}
            variant="text"
        />
    </Toolbar>
);



export const MaterialResources = 'material-data'
