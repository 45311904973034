import React, { useState, useEffect, Fragment } from 'react';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: '0 0 1% 0',
        boxShadow: 'none',
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    input: {
        marginLeft: theme.spacing(1),
        padding: 10,
        flex: 1,
    },
    iconButton: {
        padding: 10,
        color: "green",
    },
}));

const ChipsArray = props => {
    const classes = useStyles();
    const notify = useNotify();
    const {chipData, setChipData, initialValue} = props;
    const [currentTerm, setCurrentTerm] = useState("");

    useEffect(() => {
        if(initialValue) {
            const chips = initialValue.reduce((obj, value) => [...obj,{ label: value, key: value }], []);
            setChipData(chips);
        }
    },[]);

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const handleSubmit = event => {
        if(typeof event !== 'undefined') {
            event.preventDefault()
        };
        setChipData(oldArray => [...oldArray, { label: currentTerm, key: currentTerm }]);
        setCurrentTerm("");
    };

    const handleChange = event => {
        event.preventDefault();
        setCurrentTerm(event.target.value);
    };

    const handleKeyDown = function(event, submitCallback) {
        if (event.key === "Enter" && event.shiftKey === false) {
            event.preventDefault();
            const regex = new RegExp("^[a-zA-Z0-9 ]+$");
            if(!regex.test(currentTerm)) {
                notify("Search tags cannot contain special characters", 2000);
                return;
            }
            submitCallback();
        }
    };
    return (
        <>
            <Paper component="ul" className={classes.root}>
                {chipData.map((data) => {
                    return (
                        <li key={data.key}>
                            <Chip
                                label={data.label}
                                onDelete={handleDelete(data)}
                                className={classes.chip}
                            />
                        </li>
                    );
                })}
            </Paper>
                <InputBase
                    className={classes.input}
                    placeholder="Add Search Terms"
                    inputProps={{ 'aria-label': 'Add search terms' }}
                    value={currentTerm}
                    onChange={handleChange}
                    onKeyDown={event => {
                        handleKeyDown(event, handleSubmit);
                    }}
                />
                <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={handleSubmit}
                    disabled={currentTerm.length === 0}
                >
                    <CheckIcon />
                </IconButton>
            <FormHelperText className={classes.helpText} id="search-helper-text">Press enter to save tag</FormHelperText>
        </>
    );
};

export default ChipsArray;
