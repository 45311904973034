import React, {Fragment} from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  EditButton,
  Filter,
  TextInput,
  SelectInput,
  Loading,
  useRedirect,
  useMutation,
  useNotify,
  useRefresh,
  useUpdate, BulkDeleteButton, useListContext, useUpdateMany,
} from "react-admin";
import Button from '@material-ui/core/Button';
import RestoreIcon from '@material-ui/icons/Restore';
import { makeStyles } from "@material-ui/core/styles";
import AccountPanel from "./AccountsPanel";
// import ReactivateAccount from "./ReactivateAccount";

const useStyles = makeStyles(theme => ({
  email: {
    textDecoration: "none",
    color: theme.palette.primary.dark
  }
}));

const AccountsFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" defaultValue="" />
    <TextInput label="Netsuite Id" source="netsuite_id" defaultValue="" />
    <TextInput label="Account Number" source="store_number" defaultValue="" />
    <SelectInput
      label="Deleted Stores"
      source="deleted_at"
      choices={[
        { name: "Show only Deleted", value: "only" },
        { name: "Show All", value: "with" }
      ]}
      optionValue="value"
    />
  </Filter>
);

const CustomEdit = ({ record: { deleted_at, id }, resource }) => {
  const redirect = useRedirect();
  const [getDetails] = useMutation({
    type: "getOne",
    resource: "store-locations",
    payload: { id: id }
  });

  const handleEdit = async e => {
    e.preventDefault();
    getDetails();
    redirect(`/store-locations/${id}`);
  };
  return deleted_at ? null : <EditButton onClick={handleEdit} />;
};

const CustomBulkActionButtons = props => {
  const { filterValues, selectedIds, onUnselectItems } = useListContext();

  // const restoreHandler = () => {
  //     if (selectedIds.length > 0) {
  //         props.reactivateManyAccounts(selectedIds)
  //             .then(() => onUnselectItems())
  //     }
  // };

 return <Fragment>
     { props.delete && <BulkDeleteButton {...props} undoable={false}/>}
  </Fragment>
};

const AccountList = props => {
  const classes = useStyles();
  const {permissions} = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate("accounts/restore");
  const [updateMany] = useUpdateMany("accounts/restore");

  if (!permissions) return <Loading />;
  const permissionsList = permissions.split(",");

  // const reactivateAccount = async data => {
  //   await update(
  //       {
  //         payload: { id: data }
  //       },
  //       {
  //         onSuccess: () => {
  //           refresh();
  //           notify("Account Restored", 2000);
  //         },
  //         onFailure: ({response: { data }}) => {
  //             const {message} = data;
  //             notify(message || "Something went wrong", 2000);
  //         }
  //       }
  //   );
  // };

  // const reactivateManyAccounts = async data => {
  //     await updateMany(
  //         {
  //             payload: { ids: [...data] }
  //         },
  //         {
  //             onSuccess: () => {
  //                 refresh();
  //                 notify("Accounts Restored", 2000);
  //             },
  //             onFailure: ({response: { data }}) => {
  //                 const {message} = data;
  //                 notify(message || "Something went wrong", 2000);
  //             }
  //         }
  //     );
  // };
  return (
    <List
      {...props}
      exporter={false}
      filters={<AccountsFilter />}
      sort={{ field: "id", order: "ASC" }}
      bulkActionButtons={<CustomBulkActionButtons  delete={permissionsList.includes("delete_accounts")} />}
    >
      <Datagrid rowClick={null} expand={<AccountPanel hasEdit={false}/>}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="website" sortable={false}/>
        <TextField source="city" sortable={false}/>
        <TextField source="country" sortable={false}/>
        <TextField source="zip" sortable={false}/>
        <DateField source="created_at" showTime />
        {permissionsList.includes("edit_accounts") && <CustomEdit />}
      </Datagrid>
    </List>
  );
};

export default AccountList;
