import React from 'react'
import { ChipField, ReferenceArrayInput, SelectArrayInput, useInput } from 'react-admin'
import { validateRequired } from '../../../utils/Validations'

const MultiSelect = ({ source = '', isDisable = false, label = '', perPage = 100, reference = '', optionText, optionValue, ...props }) => {
    const { input } = useInput({ source: source })

    const filter = {}


    return (
        <ReferenceArrayInput
            label={label}
            source={source}
            reference={reference}
            perPage={perPage}
            filter={filter}
            sort={{ field: 'id', order: 'ASC' }}
        >
            <SelectArrayInput
                label={label}
                optionText={optionText || 'name'}
                disabled={isDisable}
                optionValue={optionValue || 'id'}
                fullWidth
                initialValue=""
                className="width-full" validate={validateRequired}>
                <ChipField source="name" />
            </SelectArrayInput>
        </ReferenceArrayInput>
    )
}

export default MultiSelect;