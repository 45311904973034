import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  table: {
    textAlign: "left",
    marginTop: "2%"
  },
  row: {
    verticalAlign: "top"
  }
}));

const ItemsTable = ({ record: { items }, isStoreOrder }) => {
  const classes = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        {items && items.length > 0 && (
          <TableRow>
            <th>Product Name</th>
            <th>SKU</th>
            <th>Qty</th>
            <th>Price</th>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {items &&
          items.length > 0 &&
          items.map(data => (
            <>
              <TableRow className={classes.row} key={data.sku}>
                <TableCell className="name-width">
                  <p>{data.name}</p>
                  {data.properties &&
                    data.properties.length > 0 &&
                    data.properties
                      .map(subData => (
                        <dl className="d-flex" key={subData.name}>
                          {subData.name !== "Product Url" && <dt className="bolder-font">{subData.name} : </dt>}
                          <dd>{
                              subData.name === "Product Url" ?
                                  (<a target="_blank" href={subData.value} rel="noreferrer">View Product</a>) :
                              typeof subData.value === 'boolean' ? subData.value.toString() : subData.value
                          }</dd>
                        </dl>
                      ))}
                      {isStoreOrder && <dl className="d-flex">
                        <dt className="bolder-font">Partner Name : </dt>
                        <dd>{data.partner_name?.join(', ') || 'N/A'}</dd>
                      </dl>}
                </TableCell>

                <TableCell className="sku">{data.sku}</TableCell>
                <TableCell className="qty">{data.quantity}</TableCell>
                <TableCell className="unit_price">{data.unit_price}</TableCell>
              </TableRow>
              {data.rush_fee && (
                <TableRow className={classes.row}>
                  <TableCell className="bolder-font">Rush Priority</TableCell>
                  <TableCell className="sku">RUSH</TableCell>
                  <TableCell className="qty">1</TableCell>
                  <TableCell className="unit_price">{data.rush_fee}</TableCell>
                </TableRow>
              )}
            </>
          ))}
      </TableBody>
    </Table>
  );
};

export default ItemsTable;
