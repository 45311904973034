import React from "react";
import { Datagrid, DeleteButton, EditButton, List, Loading, TextField, useMutation, useRedirect } from "react-admin";

import { PERMISSION_LIST_SIGNET, SignetShankResources } from "../util";
import ListActions from "../ListActions";

const CustomEdit = ({ record: { id } }) => {
    const redirect = useRedirect();
    const [getDetails] = useMutation({
        type: "getOne",
        resource: SignetShankResources,
        payload: { id: id }
    });

    const handleEdit = async e => {
        e.preventDefault();
        getDetails();
        redirect(`/${SignetShankResources}/${id}`);
    };

    return <EditButton onClick={handleEdit} />;
};

const SignetShankList = props => {
    const { permissions } = props;

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");
    return (
        <List
            {...props}
            exporter={false}
            actions={<ListActions />}
        >
            <Datagrid rowClick={null}>
                <TextField source="name" label="Name" />
                <TextField source="sku_code" sortable={false} label="Sku Code" />
                <TextField source="active" sortable={false} label="Active" />
                {permissionsList.includes("delete_signet_data") && <CustomEdit />}
                {permissionsList.includes(PERMISSION_LIST_SIGNET.delete_signet_data) && (
                    <DeleteButton label="" undoable={false} />
                )}
            </Datagrid>
        </List>
    );
};

export default SignetShankList;