import React, { useState, useCallback } from "react";
import {
    SimpleForm,
    Create,
    TextInput,
    BooleanInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ChipField,
    Notification,
    useNotify
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/styles";
import customTheme from "../../customTheme";
import {validateProductDescription, validateRequired} from "../../utils/Validations";

const useStyles = makeStyles(() => ({
    root: {
        width: "90%",
        margin: "auto"
    },
    grouping: {
        border: "1px solid lightgray",
        borderRadius: "3px",
        margin: "1% auto"
    },
    warnLabel: {
        fontSize: "10px",
        color: "red",
        display: "block"
    },
    displayFlex: {
        display: "flex"
    },
    equalWidth: {
        maxWidth: "33%"
    }
}));

const CreateDynamicProduct = props => {
    const classes = useStyles();
    const [checked, setChecked] = useState(true);

    const handleChange = useCallback(newChecked => setChecked(newChecked), []);

    const notify = useNotify();
    const onFailure = (error) => {
        notify(error.response.data.message ? error.response.data.message : 'Something went wrong', {type:'warning'});
    };

    return (
        <ThemeProvider theme={customTheme}>
            <Create onFailure={onFailure} {...props}>
                <SimpleForm redirect="list">
                    <Grid
                        container
                        spacing={2}
                        className={classes.root}
                        justify="space-between"
                    >
                        <Grid item xs={12}>
                            <ReferenceArrayInput
                                source="categories"
                                reference="categories"
                                resource="categories"
                                validate={validateRequired}
                                perPage={1000}
                            >
                                <SelectArrayInput
                                    label="Category"
                                    optionText="name"
                                    optionValue="sku_code"
                                    fullWidth
                                    initialValue=""
                                >
                                    <ChipField source="name" />
                                </SelectArrayInput>
                            </ReferenceArrayInput>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            className={classes.displayFlex}
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                <TextInput
                                    source="magic_number"
                                    label="Magic Number"
                                    validate={validateRequired}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput
                                    initialValue="customize"
                                    source="action_type"
                                    label="Action Type"
                                    validate={validateRequired}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <BooleanInput
                                    label={checked ? "Active" : "InActive"}
                                    source="active"
                                    initialValue={1}
                                    onChange={handleChange}
                                    checked={checked}
                                    parse={v => (v ? 1 : 0)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    source="description"
                                    label="Description"
                                    fullWidth
                                    multiline
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
            <Notification />
        </ThemeProvider>
    );
};

export default CreateDynamicProduct;
