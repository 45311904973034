import React from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';
import {labelFormatter, LIST_COLORS} from "../Constants";

const Latency = ({ expanded = false, data }) => {
    return (
        <ResponsiveContainer width="100%" height={400}>
        <LineChart
            data={data}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip labelFormatter={labelFormatter} />
            <Legend />
            <Line type="monotone" dataKey="P10" stroke={LIST_COLORS[0]} />
            <Line type="monotone" dataKey="P50" stroke={LIST_COLORS[1]} />
            <Line type="monotone" dataKey="P75" stroke={LIST_COLORS[2]} />
            <Line type="monotone" dataKey="P85" stroke={LIST_COLORS[3]} />
            <Line type="monotone" dataKey="P90" stroke={LIST_COLORS[4]} />
            <Line type="monotone" dataKey="P95" stroke={LIST_COLORS[5]} />
            <Line type="monotone" dataKey="P99" stroke={LIST_COLORS[6]} />
            <Line type="monotone" dataKey="P999" stroke={LIST_COLORS[0]} />
        </LineChart>
        </ResponsiveContainer>
    )
};

export default Latency;