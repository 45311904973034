import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router";
import { fetchEnd, fetchStart, useNotify } from "react-admin";
import { apiUrl } from "../../dataProvider";

function GetAWSMetrics() {
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const notify = useNotify();
    const { search } = useLocation();

    useEffect(() => {
        fetchSiteMetrics(search || '').then(() => setLoading(false));
    }, [search]);

    useEffect(() => {
        latencyTrend();
        envHealthTrend();
        totalRequestsTrend();
        degradedInstanceTrend();
        nodataInstanceTrend();
        warningInstanceTrend();
        pendingInstanceTrend();
        unknownInstanceTrend();
        okInstanceTrend();
        severeInstanceTrend();
    }, [state.metrics]);

    const fetchSiteMetrics = async (dates) => {
        setLoading(true);
        dispatch(fetchStart());

        await axios.get(`${apiUrl}/aws/metrics${dates}`)
            .then(({data}) => {
                setState(state => ({ ...state, metrics: data }));
                return {
                    data: {
                        ...data
                    }
                };
            })
            .catch(({response}) => {
                const message = response?.statusText;
                const status = response?.status;
                if (status === 401 || status === 403) {
                    window.location.replace('/#/login');
                }
                notify(message, 'warning');
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd());
            });
    }

    const latencyTrend = () => {
        const trend = state?.metrics?.applicationLatencyPXX && Object.keys(state?.metrics?.applicationLatencyPXX).map(date => ({
            date,
            P10: state.metrics.applicationLatencyPXX[date].P10,
            P50: state.metrics.applicationLatencyPXX[date].P50,
            P75: state.metrics.applicationLatencyPXX[date].P75,
            P85: state.metrics.applicationLatencyPXX[date].P85,
            P90: state.metrics.applicationLatencyPXX[date].P90,
            P95: state.metrics.applicationLatencyPXX[date].P95,
            P99: state.metrics.applicationLatencyPXX[date].P99,
            P999: state.metrics.applicationLatencyPXX[date].P999,
        }));
        setState(state => ({ ...state, latencyTrend: trend }));
    };

    const envHealthTrend = () => {
        const trend = state?.metrics?.environmentHealth && Object.keys(state?.metrics?.environmentHealth).map(date => ({
            date,
            health: state.metrics.environmentHealth[date],
        }));
        setState(state => ({ ...state, envHealthTrend: trend }));
    };

    const totalRequestsTrend = () => {
        const trend = state?.metrics?.totalRequests && Object.keys(state?.metrics?.totalRequests).map(date => ({
            date,
            requests: state.metrics.totalRequests[date],
        }));
        setState(state => ({ ...state, totalRequestsTrend: trend }));
    };

    const okInstanceTrend = () => {
        const trend = state?.metrics?.okInstance && Object.keys(state?.metrics?.okInstance).map(date => ({
            date,
            okInstance: state.metrics.okInstance[date],
        }));
        setState(state => ({ ...state, okInstanceTrend: trend }));
    };

    const degradedInstanceTrend = () => {
        const trend = state?.metrics?.degeradedInstance && Object.keys(state?.metrics?.degeradedInstance).map(date => ({
            date,
            degradedInstance: state.metrics.degeradedInstance[date],
        }));
        setState(state => ({ ...state, degradedInstanceTrend: trend }));
    };

    const nodataInstanceTrend = () => {
        const trend = state?.metrics?.nodataInstance && Object.keys(state?.metrics?.nodataInstance).map(date => ({
            date,
            nodataInstance: state.metrics.nodataInstance[date],
        }));
        setState(state => ({ ...state, nodataInstanceTrend: trend }));
    };

    const pendingInstanceTrend = () => {
        const trend = state?.metrics?.pendingInstance && Object.keys(state?.metrics?.pendingInstance).map(date => ({
            date,
            pendingInstance: state.metrics.pendingInstance[date],
        }));
        setState(state => ({ ...state, pendingInstanceTrend: trend }));
    };

    const severeInstanceTrend = () => {
        const trend = state?.metrics?.severeInstance && Object.keys(state?.metrics?.severeInstance).map(date => ({
            date,
            severeInstance: state.metrics.severeInstance[date],
        }));
        setState(state => ({ ...state, severeInstanceTrend: trend }));
    };

    const unknownInstanceTrend = () => {
        const trend = state?.metrics?.unknownInstance && Object.keys(state?.metrics?.unknownInstance).map(date => ({
            date,
            unknownInstance: state.metrics.unknownInstance[date],
        }));
        setState(state => ({ ...state, unknownInstanceTrend: trend }));
    };

    const warningInstanceTrend = () => {
        const trend = state?.metrics?.warningInstance && Object.keys(state?.metrics?.warningInstance).map(date => ({
            date,
            warningInstance: state.metrics.warningInstance[date],
        }));
        setState(state => ({ ...state, warningInstanceTrend: trend }));
    };

    return ({
        metrics: state.metrics,
        latencyTrend: state.latencyTrend,
        envHealth: state.envHealthTrend,
        totalRequestsTrend: state.totalRequestsTrend,
        severeInstanceTrend: state.severeInstanceTrend,
        warningInstanceTrend: state.warningInstanceTrend,
        degradedInstanceTrend: state.degradedInstanceTrend,
        okInstanceTrend: state.okInstanceTrend,
        pendingInstanceTrend: state.pendingInstanceTrend,
        unknownInstanceTrend: state.unknownInstanceTrend,
        nodataInstanceTrend: state.nodataInstanceTrend,
        loading
    });
}

export default GetAWSMetrics;