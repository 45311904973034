import React from "react";
import { Datagrid, DeleteButton, EditButton, List, Loading, TextField, useMutation, useRedirect} from "react-admin";
import { ShopifyStoreFilter } from "./ShopifyStoreFilter";
import { PERMISSION_LIST_SIGNET } from "../Signet/util";


const CustomEdit = ({ record: { id } }) => {
  const redirect = useRedirect();
  const [getDetails] = useMutation({
      type: "getOne",
      resource: "builder-platform",
      payload: { id: id }
  });

  const handleEdit = async e => {
      e.preventDefault();
      getDetails();
      redirect(`/builder-platform/${id}`);
  };

  return <EditButton onClick={handleEdit} />;
};

const ShopifyStoreList = props => {
  const {permissions} = props;
  if (!permissions) return <Loading />;
  const permissionsList = permissions.split(",");

  return (
    <List
      {...props}
      exporter={false}
      filters={<ShopifyStoreFilter />}
    >
            <Datagrid rowClick={null}>
              <TextField source="store_name" label="Store name" />
              <TextField source="base_url"  label="Store url"/>
              <TextField source="subpath" label="Subpath"/>
              {permissionsList.includes("edit_stores") && <CustomEdit />}
              {permissionsList.includes(PERMISSION_LIST_SIGNET.delete_signet_data) && (
                    <DeleteButton undoable={false} />
                )}
            </Datagrid>
        </List>
  );
};

export default ShopifyStoreList;