import React from 'react';
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import StoreLogins from "./Dashboard/Site Metrics/StoreLogins";
import GetSiteMetrics from "./Dashboard/GetSiteMetrics";
import Revenues from "./Dashboard/Site Metrics/Revenues";
import {Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Users from "./Dashboard/Site Metrics/Users";
import DateFilters from "./Dashboard/DateFilters";
import GetGoogleAnalytics from "./Dashboard/GetGoogleAnalytics";
import Loader from "./Loader";
import GaSummary from "./Dashboard/Site Metrics/GaSummary";
import GetAWSMetrics from "./Dashboard/GetAWSMetrics";
import StoreIcon from '@material-ui/icons/Store';
import CardWithIcon from "./Dashboard/CardWithIcon";
import ConsolidatedList from "./Dashboard/AWS/ConsolidatedList";

export default () => {
    const { loading, metrics } = GetSiteMetrics();
    const { loading: gaLoading, avgPagesPerSession, bounceRate, avgSessionDuration, usersCount, totalNewUsers } = GetGoogleAnalytics();
    const { loading: awsLoading, metrics: awsMetrics } = GetAWSMetrics();

    if (loading && gaLoading && awsLoading) return <Loader />;
  return (
      <>
          <Box display="flex">
              <Box display="flex" flex="1" minWidth="30%" mt="1em" alignItems="center"><Typography variant="h6" component="h1">Dashboard: At a Glance</Typography></Box>
             <DateFilters />
          </Box>
          <Box display="flex" mt="2em">
              <Box flex="1" display="flex">
                  <Box flex="1" mr="1em">
                      {typeof metrics?.net_users === 'number' && <StoreLogins net={metrics.net_users} />}
                      <Box mt="1.5em">
                          {typeof metrics?.net_new_store_users === 'number' && <Users net={metrics.net_new_store_users} />}
                      </Box>
                      <Box mt="1.5em">
                          {metrics?.net_total_stores &&
                              <CardWithIcon
                              to="/dashboard/site"
                              icon={StoreIcon}
                              title="Total Store Users"
                              subtitle={metrics?.net_total_stores}
                          />}
                      </Box>
                  </Box>
              </Box>
              <Box flex="1" mr="1em">
                  <Card>
                      <CardContent>
                          <Typography
                              color="textPrimary"
                              component="h2"
                              variant="subtitle1"
                          >
                              Revenue
                          </Typography>
                          {metrics && <Revenues b2b={metrics.net_revenue_b2b} b2c={metrics.net_revenue_b2c} />}
                      </CardContent>
                  </Card>
              </Box>
          </Box>
          <Box display="flex" mt="2em">
              <Box flex="1" mr="1em">
                  {!!avgPagesPerSession &&
                      <GaSummary
                          avgPagesPerSession={avgPagesPerSession}
                          avgSessionDuration={avgSessionDuration}
                          bounceRate={bounceRate}
                          totalUsers={usersCount}
                          totalNewUsers={totalNewUsers}
                      />}
              </Box>
              <Box flex="1" mr="1em">
                  {awsMetrics?.consolidatedData?.consolidatedApplicationLatency &&
                      <ConsolidatedList info={awsMetrics?.consolidatedData?.consolidatedApplicationLatency} title="Application Latency"/> }
              </Box>
              <Box flex="1" mr="1em">
                  {awsMetrics?.consolidatedData?.consolidatedInstanceHealth &&
                      <ConsolidatedList info={awsMetrics?.consolidatedData?.consolidatedInstanceHealth} title="Instance Health"/> }
              </Box>
              <Box flex="1" mr="1em">
                  {awsMetrics?.consolidatedData?.consolidatedRequests &&
                      <ConsolidatedList info={{Requests: awsMetrics?.consolidatedData?.consolidatedRequests}} title="Total Requests"/> }
              </Box>
          </Box>
      </>
  );
};
