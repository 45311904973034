import React from "react";
import { useShowController } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import theme from "../../customTheme";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexFlow: "column-wrap"
  },
  bold: {
    fontWeight: "bold",
    textTransform: "capitalize",
    color: theme.palette.primary.main
  },
  border: {
    border: "1px solid #a9964f"
  },
  customAnchor: {
    color: theme.palette.primary.main,
  },
  secondaryColor: {
    backgroundColor: theme.palette.secondary.light
  },
  tertiaryColor: {
    backgroundColor: "#ffffdf",
    border: "1px solid #ffffdf"
  }
}));

const ProductPanel = props => {
  const classes = useStyles();
  const { record } = useShowController(props);

  const {
    name: ring_name,
    ring_material,
    updated_at,
    ring_options_url,
    employee_price,
    id,
    hashed_url,
    created_at,
    category_id,
    sku,
    pictures,
    description,
    retail_price,
    wholesale_price,
    active,
      meta_info,
      tags,
    ...ring_options
  } = record;

  if (!record) return null;

  const renderOption = () => {
    return Object.keys(ring_options).map(
      option =>
        ring_options[option] && (
          <Grid item xs={4} key={option}>
            <Typography
              className={classes.bold}
              component="span"
            >{`${option.replace(/_/g, " ")}: `}</Typography>
            <Typography component="span">{ring_options[option]}</Typography>
          </Grid>
        )
    );
  };

  return (
    <Card>
      <CardContent className={classes.tertiaryColor}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography className={classes.bold} component="span">
              Name:{" "}
            </Typography>
            <Typography component="span">
              {ring_name || "Custom Ring"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.bold} component="span">
              Material:{" "}
            </Typography>
            <Typography component="span">{ring_material}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.bold} component="span">
              Last updated:{" "}
            </Typography>
            <Typography component="span">
              {new Date(updated_at).toLocaleDateString("en-US")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              component="a"
              href={ring_options_url}
              className={classes.customAnchor}
            >
              View Product
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.bold} component="span">
              Employee Price:{" "}
            </Typography>
            <Typography component="span">{employee_price}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.bold} component="span">
              Description:{" "}
            </Typography>
            <Typography component="span">{description}</Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={1}
            className={`${classes.border} ${classes.secondaryColor}`}
          >
            {renderOption()}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductPanel;
