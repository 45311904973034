import React from "react";
import {
    ListBase,
    Datagrid,
    BooleanField,
    TextField,
    ImageField,
    Filter,
    SelectInput,
    TopToolbar,
    Pagination,
    useUpdate,
    useNotify,
    useRefresh,
    usePermissions,
    ListToolbar,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PositionEdit from "./PositionEdit";
import FilterSidebar from "./FilterAside";

const useStyles = makeStyles(() => ({
    image: {
        width: "60px",
    },
    table: {
        background: '#fafafa',
        flexGrow: 1,
        width: 'auto',
        overflow: 'auto',
    }
}));

const ProductDetails = props => {
    const { permissionsList } = props;
    const reference = props.record.type === 'static' ? 'products' : 'dynamic/products';
    const access = props.record.type === 'static' ?
        permissionsList.includes('edit_products') && permissionsList.includes('view_products') :
        permissionsList.includes('edit_dynamic-products') && permissionsList.includes('view_dynamic-products');
    const id = props.record.id.split("_")[0];
    return access ? <Button
            size="small"
            color="primary"
            component={Link}
            to={`/${reference}/${id}`}
        >
            Product Details
        </Button> : null;
};

const ProductFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Active"
            source="active"
            choices={[
                { name: "Active", value: 1 },
                { name: "Inactive", value: 0 }
            ]}
            optionValue="value"
        />
    </Filter>
);

const ListActions = () => (
    <TopToolbar>
        <ProductFilter context="button" />
    </TopToolbar>
);

const ProductsList = props => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate("position", "");
    const { permissions } = usePermissions();
    if (!permissions) return null;
    const permissionsList = permissions.split(",");

    const updatePosition = data => {
        update(
            {
                payload: { data: { ...data } }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("Position Updated", 2000);
                }
            }
        );
    };

    return <ListBase
                {...props}
                 exporter={false}
                 bulkActionButtons={false}
                 sort={{ field: "position", order: "ASC" }}
                 basePath='/position'
                 resource='position'
    >
        <ListToolbar
            filters={<ProductFilter/>}
            actions={<ListActions/>}
        />
        <Box display="flex" alignItems="flex-start">
            <FilterSidebar/>
            <Datagrid rowClick={null} className={classes.table}>
                <ImageField className={classes.image} source="image_url" label="Ring Preview" sortable={false}/>
                <TextField source="wholesale_price" label="Wholesale Price" sortable={false}/>
                <TextField source="retail_price" label="Retail Price" sortable={false} />
                <BooleanField source="active" sortable={false} />
                <TextField source="type" sortable={false} />
                {permissionsList.includes('view_positions') && <PositionEdit source="position" updatePosition={updatePosition}/>}
                <ProductDetails permissionsList={permissionsList}/>
            </Datagrid>
        </Box>
        <Pagination />
        <Button
            color="primary"
            component={Link}
            to="/categories"
        >
            Back to Categories
        </Button>
        </ListBase>
};

export default ProductsList;