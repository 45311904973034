import React from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
} from "react-admin";
import {validateRequired} from "../../utils/Validations";

const RoleCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={validateRequired} />
        <ReferenceArrayInput
          label="Permissions"
          source="permissions"
          reference="permissions"
          perPage={50}
        >
          <SelectArrayInput validate={validateRequired}>
            <ChipField source="name" />
          </SelectArrayInput>
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default RoleCreate;
