import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {GRAPH_COLORS, labelFormatter} from "../Constants";

const EnvironmentHealth = ({ data }) => {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart
                data={data}
                margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis dataKey="health" type="category" />
                <Tooltip labelFormatter={labelFormatter} />
                <Legend />
                <Line dataKey="health" stroke={GRAPH_COLORS.line1} />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default EnvironmentHealth;