import React, {Fragment} from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  EditButton,
  Filter,
  TextInput,
  SelectInput,
  Loading,
  useRedirect,
  useMutation,
  useNotify,
  useRefresh,
  useUpdate, BulkDeleteButton, useListContext, useUpdateMany,
} from "react-admin";
import Button from '@material-ui/core/Button';
import RestoreIcon from '@material-ui/icons/Restore';
import { makeStyles } from "@material-ui/core/styles";
import AccountPanel from "./AccountsPanel";
import ReactivateAccount from "./ReactivateAccount";

const useStyles = makeStyles(theme => ({
  email: {
    textDecoration: "none",
    color: theme.palette.primary.dark
  }
}));

const AccountsFilter = props => (
  <Filter {...props}>
    <TextInput label="First Name" source="first_name" defaultValue="" />
    <TextInput label="Last Name" source="last_name" defaultValue="" />
    <TextInput label="Email" source="email" defaultValue="" />
    <TextInput label="Username" source="username" defaultValue="" />
    <SelectInput
        label="Currently logged in"
        source="logged_in"
        choices={[
          { name: "Show currently logged in users", value: "1" },
        ]}
        optionValue="value"
        defaultValue="1"
    />
    <SelectInput
      label="Deleted Accounts"
      source="deleted_at"
      choices={[
        { name: "Show only Deleted", value: "only" },
        { name: "Show All", value: "with" }
      ]}
      optionValue="value"
    />
  </Filter>
);

const CustomEdit = ({ record: { deleted_at, id }, resource }) => {
  const redirect = useRedirect();
  const [getDetails] = useMutation({
    type: "getOne",
    resource: "accounts",
    payload: { id: id }
  });

  const handleEdit = async e => {
    e.preventDefault();
    getDetails();
    redirect(`/accounts/${id}`);
  };

  return deleted_at ? null : <EditButton onClick={handleEdit} />;
};

const CustomBulkActionButtons = props => {
  const { filterValues, selectedIds, onUnselectItems } = useListContext();
  const showMultiReactivate = filterValues && filterValues.deleted_at && filterValues.deleted_at === 'only';

  const restoreHandler = () => {
      if (selectedIds.length > 0) {
          props.reactivateManyAccounts(selectedIds)
              .then(() => onUnselectItems())
      }
  };

 return <Fragment>
     {!showMultiReactivate && props.delete && <BulkDeleteButton {...props} undoable={false}/>}
   {showMultiReactivate &&
   <Button
       size="small"
       color="secondary"
       startIcon={<RestoreIcon />}
       onClick={restoreHandler}
   >
     Reactivate
   </Button>}
  </Fragment>
};

const AccountList = props => {
  const classes = useStyles();
  const {permissions} = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate("accounts/restore");
  const [updateMany] = useUpdateMany("accounts/restore");

  if (!permissions) return <Loading />;
  const permissionsList = permissions.split(",");

  const reactivateAccount = async data => {
    await update(
        {
          payload: { id: data }
        },
        {
          onSuccess: () => {
            refresh();
            notify("Account Restored", 2000);
          },
          onFailure: ({response: { data }}) => {
              const {message} = data;
              notify(message || "Something went wrong", 2000);
          }
        }
    );
  };

  const reactivateManyAccounts = async data => {
      await updateMany(
          {
              payload: { ids: [...data] }
          },
          {
              onSuccess: () => {
                  refresh();
                  notify("Accounts Restored", 2000);
              },
              onFailure: ({response: { data }}) => {
                  const {message} = data;
                  notify(message || "Something went wrong", 2000);
              }
          }
      );
  };

  return (
    <List
      {...props}
      exporter={false}
      filters={<AccountsFilter />}
      sort={{ field: "last_login_at", order: "DESC" }}
      bulkActionButtons={<CustomBulkActionButtons reactivateManyAccounts={reactivateManyAccounts} delete={permissionsList.includes("delete_accounts")} />}
    >
      <Datagrid rowClick={null} expand={<AccountPanel hasEdit={false} />}>
        <TextField source="first_name" />
        <TextField source="last_name"/>
        <TextField source="username" />
        <TextField source="email" className={classes.email} />
        <BooleanField label="Parent User" source="store.is_parent_store" sortable={false} />
        <BooleanField source="show_price_toggle" sortable={false} />
        <DateField source="created_at" showTime />
        <DateField source="last_login_at" showTime />
        {permissionsList.includes("edit_accounts") && <CustomEdit />}
        <ReactivateAccount reactivateAccount={reactivateAccount}/>
      </Datagrid>
    </List>
  );
};

export default AccountList;
