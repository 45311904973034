import React, {useState} from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    BooleanInput,
    SelectInput,
    PasswordInput, AutocompleteInput,
    useDataProvider, useNotify
} from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import theme from "../../customTheme";
import CreateToolbar from "./AccountCreateButton";
import {
    validateRequired as validation,
    validateEmail,
    validateUsername,
} from "../../utils/Validations";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

export const AccountCreate = props => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isParentStore, setParent] = useState(false);

    const selection = store_id => {
        dataProvider.getOne('stores', { id: store_id })
            .then(({ data }) => {
                setParent(data.is_parent_store);
            })
            .catch(error => {
                notify("Something went wrong, please refresh", 2000);
            })
    };

    return (
        <Create {...props}>
            <SimpleForm toolbar={<CreateToolbar isParentStore={isParentStore}/>}>
                <Grid container className={classes.container} justify="space-between">
                    <Grid item xs={5}>
                        <TextInput source="first_name" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="last_name" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="email" validate={validateEmail} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="username" validate={validateUsername} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <ReferenceInput
                            reference="stores"
                            source="store_id"
                            label="Store Name"
                            filterToQuery={searchText => ({ store_name: searchText })}
                            shouldRenderSuggestions={(val) => { return val.trim().length >= 1 }}
                            fullWidth
                            validate={validation}
                            onChange={selection}
                        >
                            <AutocompleteInput optionText="store_name" optionValue="id" />
                        </ReferenceInput>
                    </Grid>
                    <Grid xs={10} container item alignItems="center">
                        <PasswordInput source="password" validate={validation} />
                        <Tooltip
                            title="Password must have at least 8 characters with one uppercase letter and one number"
                            arrow
                            leaveDelay={200}
                        >
                            <InfoIcon htmlColor={theme.palette.secondary.dark}/>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={5}>
                        <ReferenceInput source="account_type_id" reference="accounts/types" validate={validation} fullWidth><SelectInput optionText="name" /></ReferenceInput>
                    </Grid>
                    <Grid item container xs={5}>
                        <Grid item>
                            <BooleanInput source="requires_parent_approval" defaultValue={false} disabled={isParentStore} />
                            <BooleanInput source="show_price_toggle" defaultValue={false} />
                            <BooleanInput source="customer_portal_access" defaultValue={false} />
                            <BooleanInput source="show_data_tab" defaultValue={false} />
                            <BooleanInput source="show_store_toggle" defaultValue={false} label="Show store toggle & notes"/>
                            <BooleanInput source="show_store_success_page" defaultValue={false}/>
                            <BooleanInput source="show_sales_dashboard" defaultValue={false}/>
                        </Grid>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
)};