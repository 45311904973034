import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    Loading,
    Toolbar,
    SaveButton,
    DeleteButton,
} from "react-admin";
import Grid from '@material-ui/core/Grid';
import WithProps from "../Custom/WithProps";
import { makeStyles } from '@material-ui/core/styles';
import {
    validateRequired,
} from "../../utils/Validations";
import './index.css';

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});

const StoreEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine} />
        {props.delete && <DeleteButton undoable={false} />}
    </Toolbar>
);



export const MaterialDataEdit = props => {
    const classes = useStyles();
    const { permissions } = props;

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    return (
        <Edit {...props} className='overflow-visible'>
            <WithProps>
                {({ record, ...props }) =>
                (<SimpleForm record={record} {...props} undoable={false} toolbar={<StoreEditToolbar delete={permissionsList.includes("delete_material_data")} />}>
                    <Grid container className={classes.container} justify="space-between">
                        <Grid item xs={5}>
                            <TextInput disabled source="material" label="Material" fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput disabled source="sku_code" label="Sku Code" validate={validateRequired} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput source="image_url" label="Image url" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>

                            <TextInput source="emblem_image_url" label="Emblem Image url" validate={[validateRequired]} fullWidth />
                        </Grid>


                        <Grid item xs={5}>
                            <TextInput source="scratch_resistant" type="number" label="Scratch Resistant" validate={validateRequired} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput placeholder="/pages/titanium" source="link" label="Link" fullWidth />
                        </Grid>
                        
                        <Grid item xs={5}>
                            <TextInput source="weight" label="Weight" type="number" validate={validateRequired} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput multiline source="short_description" label="Description" validate={validateRequired} fullWidth />
                        </Grid>
                    </Grid>
                </SimpleForm>)
                }
            </WithProps>
        </Edit>
    )
};