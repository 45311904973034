import React from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {GRAPH_COLORS, labelFormatter} from "../Constants";

const BounceRate = ({ trends }) => (
    <ResponsiveContainer width="100%" height={250}>
        <AreaChart
            width={500} data={trends}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis unit="%"/>
            <Tooltip formatter={(label) => label + "%"} labelFormatter={labelFormatter} />
            <Area name="Bounce Rate" type="monotone" dataKey="bounceRateTrend" stroke={GRAPH_COLORS.solid1} fill={GRAPH_COLORS.solid1} />
        </AreaChart>
    </ResponsiveContainer>
);

export default BounceRate;