import React from "react";
import {
    Edit,
    SimpleForm,
    Loading,
    useNotify,
    Notification,
} from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import WithProps from "../../Custom/WithProps";
import { makeStyles } from '@material-ui/core/styles';

import {  EditToolBar, PERMISSION_LIST_SIGNET } from "../util";
import PriceForm from "./PriceForm";

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});


const SignetPricingEdit = props => {
    const classes = useStyles();
    const { permissions } = props;
    const notify = useNotify();

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");


    const onFailure = (error) => {
        notify(error.response.data.message ? error.response.data.message : 'Something went wrong', { type: 'warning' });
    };

    return (
        <ThemeProvider >
            <Edit mutationMode="pessimistic" {...props} onFailure={onFailure} className='overflow-visible'>
                <WithProps>
                    {({ record, ...props }) =>
                    (<SimpleForm record={record} {...props} undoable={false} toolbar={<EditToolBar delete={permissionsList.includes(PERMISSION_LIST_SIGNET.delete_signet_data)} />}>
                        <Grid container className={classes.container} justify="space-between">
                        <PriceForm isEdit={true} />
                        </Grid>
                    </SimpleForm>)
                    }
                </WithProps>
            </Edit>
            <Notification />
        </ThemeProvider>
    )
};

export default SignetPricingEdit;