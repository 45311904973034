import React from 'react';

const getShadowConfig = (strength) => {
  switch (strength) {
    case 'shallow':
      return {
        layers: [
          { blur: 1.2, offset: 4, intensity: 1.2 },
          { blur: 1.2, offset: 8, intensity: 1.2 },
          { blur: 1.2, offset: 12, intensity: 1.2 },
        ]
      };
    case 'deep':
      return {
        layers: [
          { blur: 1.2, offset: 4, intensity: 1.2 },
          { blur: 1.2, offset: 8, intensity: 1.2 },
          { blur: 1.2, offset: 12, intensity: 1.2 },
          { blur: 1.2, offset: 16, intensity: 1.2 },
          { blur: 1.2, offset: 20, intensity: 1.2 },
          { blur: 1.2, offset: 24, intensity: 1.2 },
        ]
      };
    case 'standard':
    default:
      return {
        layers: [
          { blur: 1.2, offset: 4, intensity: 1.2 },
          { blur: 1.2, offset: 8, intensity: 1.2 },
          { blur: 1.2, offset: 12, intensity: 1.2 },
          { blur: 1.2, offset: 16, intensity: 1.2 },
        ]
      };
  }
};

const InnerShadowFilter = ({ id, shadowColor, strength = 'standard' }) => {
  const config = getShadowConfig(strength);

  return (
    <filter id={id} x0="-50%" y0="-50%" width="200%" height="200%">
      {config.layers.map((layer, index) => (
        <React.Fragment key={index}>
          <feGaussianBlur 
            in={index === 0 ? "SourceAlpha" : `firstLayer${index - 1}`} 
            stdDeviation={layer.blur} 
            result={`blur${index}`} 
          />
          <feOffset dy={layer.offset} dx={index === config.layers.length - 1 ? -2 : 0} />
          <feComposite 
            in2={index === 0 ? "SourceAlpha" : `firstLayer${index - 1}`} 
            operator="arithmetic" 
            k2="-1" 
            k3="1" 
            result={`shadowDiff${index}`} 
          />

          <feFlood 
            floodColor={shadowColor} 
            floodOpacity={layer.intensity} 
          />
          <feComposite in2={`shadowDiff${index}`} operator="in" />
          <feComposite 
            in2={index === 0 ? "SourceGraphic" : `firstLayer${index - 1}`} 
            operator="over" 
            result={`firstLayer${index}`} 
          />
        </React.Fragment>
      ))}

      {/* Add 1px black stroke */}
      <feFlood floodColor="#000000" result="black" />
      <feComposite in="black" in2="SourceAlpha" operator="in" result="stroke" />
      <feComposite 
        in={`firstLayer${config.layers.length - 1}`} 
        in2="stroke" 
        operator="over" 
      />
    </filter>
  );
};

export default InnerShadowFilter;
