import { Slider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { getValueLabelFormat } from '../utils/formatter';

const useStyles = makeStyles((theme) => ({
  relativePaddingSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: 'auto',
    marginLeft: theme.spacing(2),
  },
  relativePaddingLabel: {
    whiteSpace: 'nowrap',
  },
  sliderWrapper: {
    width: 200,
  },
  paddingBadge: {
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.75rem',
    fontWeight: 500,
    minWidth: 60,
    textAlign: 'center',
  },
}));

const RelativePaddingSlider = ({ shape, shapeConfig, onPaddingChange, disabled }) => {
  const classes = useStyles();
  const [padding, setPadding] = useState(undefined);

  useEffect(() => {
    const enabledSizes = shape?.children.filter((size) => size.checked);
    if (!enabledSizes.length) {
      setPadding(0);
    } else if (padding === undefined) {
      setPadding(
        Math.max(
          ...enabledSizes.map((size) => {
            const sizeConfig = shapeConfig?.sizes.find((s) => s.size_sku === size?.sku);
            return sizeConfig?.shanks[0]?.padding || 0;
          }),
        ),
      );
    }
  }, [shape, shapeConfig, padding]);

  const handleChange = (_, newValue, event) => {
    event?.stopPropagation();
    const difference = newValue - padding;
    onPaddingChange(shape.id, difference, event);
    setPadding(newValue);
  };

  return (
    <div className={classes.relativePaddingSection} onClick={(e) => e?.stopPropagation()}>
      <Typography variant="body2" className={classes.relativePaddingLabel}>
        Relative Padding
      </Typography>
      <div className={classes.sliderWrapper}>
        <Slider
          value={padding || 0}
          onChange={handleChange}
          min={0}
          max={50}
          step={1}
          disabled={disabled}
          valueLabelDisplay="auto"
          valueLabelFormat={getValueLabelFormat}
        />
      </div>
      <Typography variant="body2" className={classes.paddingBadge}>
        {getValueLabelFormat(padding)}
      </Typography>
    </div>
  );
};

export default RelativePaddingSlider;
