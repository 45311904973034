import React, {useCallback, useState} from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CategoryToolbar from "./CustomSave";
import {validateRequired} from "../../utils/Validations";
import { SelectInput } from "react-admin";

const useStyles = makeStyles(() => ({
    root: {
        width: "90%",
        margin: "auto"
    },
    warnLabel: {
        fontSize: "10px",
        color: "red",
        display: "block"
    },
}));


const CategoryCreate = props => {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const [filters, setFilters] = useState(false);
    const [hidden, setHidden] = useState(false);
    const handleChange = useCallback(newChecked => setChecked(newChecked), []);
    const handleFilterChange = useCallback(newChecked => setFilters(newChecked), []);
    const handleHiddenChange = useCallback(newChecked => setHidden(newChecked), []);


    return (
        <Create {...props}>
            <SimpleForm redirect="list" toolbar={<CategoryToolbar action="create" />} >
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                    justify="space-between"
                >
                    <Grid item xs={6}>
                        <TextInput source="name" validate={validateRequired} />
                    </Grid>
                    <Grid item xs={6}>
                        <BooleanInput
                            label={checked ? "Active" : "Inactive"}
                            source="active"
                            initialValue={0}
                            onChange={handleChange}
                            checked={checked}
                            parse={v => (v ? 1 : 0)}
                        />
                        <BooleanInput
                            label={filters ? "Filters Enabled" : "Filters Disabled"}
                            source="display_filters"
                            initialValue={0}
                            onChange={handleFilterChange}
                            checked={filters}
                            parse={v => (v ? 1 : 0)}
                        />
                        <BooleanInput
                            label="Hidden"
                            source="hidden"
                            initialValue={0}
                            onChange={handleHiddenChange}
                            checked={hidden}
                            parse={v => (v ? 1 : 0)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="category_sku" validate={validateRequired} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="details_link_url" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="swatch_url" />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            source="section"
                            choices={[
                                { id: 'materials', name: 'Materials' },
                                { id: 'mens', name: 'Mens' },
                                { id: 'womens', name: 'Womens' },
                            ]}
                            validate={validateRequired}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="tagline" fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="description" multiline fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageInput
                            validate={validateRequired}
                            source="icon_image_url"
                            accept="image/png"
                            maxSize={2000000}
                            placeholder={
                                <p>
                                    Upload Image
                                    <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                </p>
                            }
                        >
                            <ImageField source="src" title="ring_image" />
                        </ImageInput>
                    </Grid>
                    <Grid item xs={6}>
                        <ImageInput
                            validate={validateRequired}
                            source="ring_image"
                            accept="image/png"
                            maxSize={2000000}
                            placeholder={
                                <p>
                                    Upload Image
                                    <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                </p>
                            }
                        >
                            <ImageField source="src" title="ring_image" />
                        </ImageInput>
                    </Grid>
                    <Grid item xs={6}>
                        <ImageInput
                            validate={validateRequired}
                            source="background_image"
                            accept="image/png"
                            maxSize={2000000}
                            placeholder={
                                <p>
                                    Upload Image
                                    <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                </p>
                            }
                        >
                            <ImageField source="src" title="background_image" />
                        </ImageInput>
                    </Grid>
                    <Grid item xs={6}>
                        <ImageInput
                            validate={validateRequired}
                            source="header_background_image"
                            accept="image/png"
                            maxSize={2000000}
                            placeholder={
                                <p>
                                    Upload Image
                                    <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                </p>
                            }
                        >
                            <ImageField source="src" title="header_background_image" />
                        </ImageInput>
                    </Grid>
                    <Grid item xs={6}>
                        <ImageInput
                            validate={validateRequired}
                            source="footer_background_image"
                            accept="image/png"
                            maxSize={2000000}
                            placeholder={
                                <p>
                                    Upload Image
                                    <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                </p>
                            }
                        >
                            <ImageField source="src" title="footer_background_image" />
                        </ImageInput>
                    </Grid>
                    <Grid item xs={6}>
                        <ImageInput
                            validate={validateRequired}
                            source="footer_banner_image"
                            accept="image/png"
                            maxSize={2000000}
                            placeholder={
                                <p>
                                    Upload Image
                                    <span className={classes.warnLabel}>
                                        *File size should not exceed 2MB and file format must be png
                                    </span>
                                </p>
                            }
                        >
                            <ImageField source="src" title="footer_banner_image" />
                        </ImageInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CategoryCreate;
