import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Typography, Slider, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useSignetStudio } from '../SignetStudioContext';
import SizePreview from './SizePreview';
import CircularRange from './CircularRange';
import { formatScaleValue, getValueLabelFormat } from '../utils/formatter';

const useStyles = makeStyles((theme) => ({
  sizeCard: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  sizeHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  sizeName: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  sliderContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 60px 1fr',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
  },
  sliderLabel: {
    whiteSpace: 'nowrap',
    minWidth: 60,
  },
  scaleBadge: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.75rem',
    fontWeight: 500,
    minWidth: 60,
    textAlign: 'center',
    justifySelf: 'end',
  },
  sliderWrapper: {
    width: '100%',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  previewContainer: {
    marginTop: 8,
  },
  shadowContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  shadowLabel: {
    fontWeight: 500,
    alignSelf: 'center',
  },
  radioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  radioOption: {
    marginRight: theme.spacing(1.5),
  },
  rotationContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  rotationLabel: {
    fontWeight: 500,
  },
}));

const ShapeSizeCard = ({ 
  size, 
  sizeConfig, 
  shape, 
  shapeEnabled, 
  onSizeToggle, 
  onScaleChange, 
  onPaddingChange,
  onShadowChange,
  onRotationChange 
}) => {
  const classes = useStyles();
  const { state } = useSignetStudio();

  return (
    <div className={`${classes.sizeCard} ${!shapeEnabled ? classes.disabled : ''}`}>
      <div className={classes.sizeHeader}>
        <Switch
          checked={size.checked}
          onChange={(e) => onSizeToggle(shape?.id, size.id, e.target.checked)}
          color="primary"
          disabled={!shapeEnabled}
        />
        <Typography className={classes.sizeName}>{size.name}</Typography>
      </div>

      <div className={classes.sliderContainer}>
        <Typography variant="body2" className={classes.sliderLabel}>
          Scale
        </Typography>
        <span className={classes.scaleBadge}>
          {formatScaleValue(sizeConfig?.shanks[0]?.scale || 0, !size.checked || !shapeEnabled)}
        </span>
        <div className={classes.sliderWrapper}>
          <Slider
            value={sizeConfig?.shanks[0]?.scale || 0}
            onChange={(_, value) => onScaleChange(shape?.id, size.id, value)}
            min={-50}
            max={50}
            step={1}
            disabled={!size.checked || !shapeEnabled}
            valueLabelDisplay="auto"
            valueLabelFormat={getValueLabelFormat}
          />
        </div>
      </div>

      <div className={classes.sliderContainer}>
        <Typography variant="body2" className={classes.sliderLabel}>
          Padding
        </Typography>
        <span className={classes.scaleBadge}>
          {formatScaleValue(sizeConfig?.shanks[0]?.padding || 0, !size.checked || !shapeEnabled)}
        </span>
        <div className={classes.sliderWrapper}>
          <Slider
            value={sizeConfig?.shanks[0]?.padding || 0}
            onChange={(_, value) => onPaddingChange(shape?.id, size.id, value)}
            min={0}
            max={50}
            step={1}
            disabled={!size.checked || !shapeEnabled}
            valueLabelDisplay="auto"
            valueLabelFormat={getValueLabelFormat}
          />
        </div>
      </div>

      <div className={classes.shadowContainer}>
        <Typography variant="body2" className={classes.shadowLabel}>
          Shadow
        </Typography>
        <RadioGroup
          value={sizeConfig?.shanks[0]?.shadow || 'standard'}
          onChange={(e) => onShadowChange(shape?.id, size.id, e.target.value)}
          className={classes.radioGroup}
          disabled={!size.checked || !shapeEnabled}
        >
          <FormControlLabel
            value="shallow"
            className={classes.radioOption}
            control={<Radio size="small" />}
            label="Shallow"
            disabled={!size.checked || !shapeEnabled}
          />
          <FormControlLabel
            value="standard"
            className={classes.radioOption}
            control={<Radio size="small" />}
            label="Standard"
            disabled={!size.checked || !shapeEnabled}
          />
          <FormControlLabel
            value="deep"
            className={classes.radioOption}
            control={<Radio size="small" />}
            label="Deep"
            disabled={!size.checked || !shapeEnabled}
          />
        </RadioGroup>
      </div>

      <div className={classes.rotationContainer}>
        <Typography variant="body2" className={classes.rotationLabel}>
          Rotation
        </Typography>
        <CircularRange
          value={sizeConfig?.shanks[0]?.shape_rotation || 0}
          onChange={(value) => onRotationChange(shape?.id, size.id, value)}
          disabled={!size.checked || !shapeEnabled}
          size={72}
          strokeWidth={4}
        />
      </div>

      <div className={classes.previewContainer}>
        {state.svgFile ? (
          <SizePreview
            svgFile={state.svgFile}
            shapeConfig={state.shapeConfigs.find((config) => config.shape_sku === shape?.sku)}
            sizeConfig={sizeConfig}
            disabled={!size.checked || !shapeEnabled}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ShapeSizeCard;
