import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ThemeProvider } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import customTheme from '../../../customTheme';
import ApprovalMapping from './ApprovalMapping';
import { useEngravingAPI } from './hooks/useEngravingAPI';
import { ACTIONS } from './reducers/studioReducer';
import { useSignetStudio } from './SignetStudioContext';
import SvgUpload from './SvgUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
    margin: '0 auto',
  },
  title: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  optionText: {
    '& .school': {
      fontSize: '0.875rem',
      color: theme.palette.text.secondary,
      marginLeft: 5,
    },
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(4),
  },
  selectionColumn: {
    flex: 1,
    minWidth: '45%',
    maxWidth: '50%',
  },
  uploadColumn: {
    flex: 1,
    minWidth: '45%',
    maxWidth: '50%',
  },
  selectionGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  dropdown: {
    width: '100%',
  },
  submitButton: {
    minWidth: 120,
  },
  multiSelect: {
    width: '100%',
  },
  menuItem: {
    padding: theme.spacing(0.5, 2),
    width: '100%',
  },
  colorDot: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  whiteColorDot: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  noColorIcon: {
    fontSize: 16,
    marginRight: theme.spacing(1),
    opacity: 0.7,
  },
  filterRow: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
  },
  filterColumn: {
    flex: 1,
    minWidth: 0,
  },
  sectionLabel: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

const StudioMain = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useSignetStudio();
  const { loading, fetchEngravingsList, fetchEngravingDetails, handleMappingChange } = useEngravingAPI();

  const handleOpen = () => {
    if (state.engravingsList.length === 0) {
      fetchEngravingsList();
    }
  };

  const handleChange = async (event, newValue) => {
    dispatch({ type: ACTIONS.SET_SELECTED_ENGRAVING, payload: newValue });

    if (newValue?.id) {
      await fetchEngravingDetails(newValue.id);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    dispatch({ type: ACTIONS.SET_INPUT_VALUE, payload: newInputValue });
  };

  const handleSvgChange = (file) => {
    dispatch({ type: ACTIONS.SET_SVG_FILE, payload: file });
  };

  const handleSubmit = () => {
    if (!state.svgFile || !state.selectedEngraving || !state.mappingData) {
      return;
    }
    history.push('/signet-studio/engraving-review');
  };

  const handleMaterialChange = (event, selectedMaterials) => {
    const selectedMaterialIds = new Set(selectedMaterials.map((material) => material.id));
    const updatedData = {
      ...state.mappingData,
      materials: state.mappingData.materials.map((material) => ({
        ...material,
        checked: selectedMaterialIds.has(material.id),
      })),
    };

    dispatch({ type: ACTIONS.SET_MAPPING_DATA, payload: updatedData });
  };

  const handleColorChange = (event, selectedColors) => {
    const selectedColorIds = new Set(selectedColors.map((color) => color.id));

    const updatedData = {
      ...state.mappingData,
      colors: state.mappingData.colors.map((color) => ({
        ...color,
        checked: selectedColorIds.has(color.id),
      })),
    };

    dispatch({ type: ACTIONS.SET_MAPPING_DATA, payload: updatedData });
  };

  const selectedMaterials = useMemo(() => {
    return state.mappingData?.materials?.filter((m) => m.checked) || [];
  }, [state.mappingData?.materials]);

  const selectedColors = useMemo(() => {
    return state.mappingData?.colors?.filter((c) => c.checked) || [];
  }, [state.mappingData?.colors]);

  const renderSelectionSummary = (selected, total, label) => {
    if (selected.length === total) return `All ${label}s selected`;
    if (selected.length === 0) return `No ${label}s selected`;
    return `${selected.length} of ${total} ${label}s selected`;
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          Signet Studio
        </Typography>
        <Paper className={classes.paper}>
          <div className={classes.gridContainer}>
            <div className={classes.selectionColumn}>
              <div className={classes.selectionGroup}>
                <div>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Select Engraving
                  </Typography>
                  <Autocomplete
                    className={classes.dropdown}
                    value={state.selectedEngraving}
                    onChange={handleChange}
                    inputValue={state.inputValue}
                    onInputChange={handleInputChange}
                    onOpen={handleOpen}
                    options={state.engravingsList}
                    getOptionLabel={(option) => option.name}
                    filterOptions={(options, { inputValue }) => {
                      const searchTerms = inputValue.toLowerCase().split(' ');
                      return options.filter((option) => {
                        const searchString = `${option.name} ${option.school || ''}`.toLowerCase();
                        return searchTerms.every((term) => searchString.includes(term));
                      });
                    }}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search by engraving name or school"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    renderOption={(option) => (
                      <div className={classes.optionText}>
                        <span>{option.name}</span>
                        {option.school && <span className="school">- {option.school}</span>}
                      </div>
                    )}
                  />
                </div>

                <div>
                  <div className={classes.filterRow}>
                    <div className={classes.filterColumn}>
                      <Typography variant="h6" className={classes.sectionLabel} gutterBottom>
                        Colors
                      </Typography>
                      <Autocomplete
                        multiple
                        className={classes.multiSelect}
                        options={state?.mappingData?.colors || []}
                        value={selectedColors}
                        onChange={handleColorChange}
                        getOptionLabel={(option) => option.school_color_name || option.name}
                        disableCloseOnSelect
                        renderTags={() => null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Colors"
                            helperText={renderSelectionSummary(
                              selectedColors,
                              state?.mappingData?.colors?.length,
                              'color',
                            )}
                          />
                        )}
                        renderOption={(option, { selected }) => (
                          <MenuItem className={classes.menuItem}>
                            <Checkbox checked={selected} color="primary" />
                            <ListItemText
                              primary={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {option.hexcode?.toLowerCase() === 'none' ? (
                                    <BlockIcon className={classes.noColorIcon} />
                                  ) : (
                                    <span
                                      className={`${classes.colorDot} ${
                                        option.hexcode?.toLowerCase() === '#ffffff' ? classes.whiteColorDot : ''
                                      }`}
                                      style={{
                                        backgroundColor: option.hexcode,
                                      }}
                                    />
                                  )}
                                  {option.school_color_name || option.name}
                                </div>
                              }
                            />
                          </MenuItem>
                        )}
                        disabled={!state.selectedEngraving}
                      />
                    </div>

                    <div className={classes.filterColumn}>
                      <Typography variant="h6" className={classes.sectionLabel} gutterBottom>
                        Materials
                      </Typography>
                      <Autocomplete
                        multiple
                        className={classes.multiSelect}
                        options={state?.mappingData?.materials || []}
                        value={selectedMaterials}
                        onChange={handleMaterialChange}
                        getOptionLabel={(option) => option.name}
                        disableCloseOnSelect
                        renderTags={() => null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Materials"
                            helperText={renderSelectionSummary(
                              selectedMaterials,
                              state?.mappingData?.materials?.length || 0,
                              'material',
                            )}
                          />
                        )}
                        renderOption={(option, { selected }) => (
                          <MenuItem className={classes.menuItem}>
                            <Checkbox checked={selected} color="primary" />
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        )}
                        disabled={!state.selectedEngraving}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.uploadColumn}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Upload SVG
              </Typography>
              <SvgUpload file={state.svgFile} onFileChange={handleSvgChange} />
            </div>
          </div>

          {state.selectedEngraving && state.mappingData && (
            <>
              <Box mt={4} width="100%">
                <ApprovalMapping data={state.mappingData} onChange={handleMappingChange} />
              </Box>
              <Box mt={4} display="flex" justifyContent="flex-end" width="100%">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleSubmit}
                  disabled={!state.svgFile}
                >
                  Proceed
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default StudioMain;
