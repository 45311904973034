import queryString from "query-string";
import baseURL from "./settings";
import axios from "axios";
import { SignetSignetInlayResources, SignetSignetPricingDataResources } from "./components/Signet/util";

export const apiUrl = baseURL.apiBaseUrl;

export const rawFileExtraction = async data => {
  let form_data = new FormData();

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      let value = (!data[key] && typeof data[key] !== 'number' && typeof data[key] !== 'boolean') ? "" : data[key];
      if (value && value.hasOwnProperty('rawFile') && key === "icon_image_url") {
        const converted = await convertFileToBase64(value);
        form_data.append(key, converted);
      } else if (value && value.hasOwnProperty('rawFile')) {
        form_data.append(key, value.rawFile)
      } else if (Array.isArray(data[key])) {
        for (let i = 0; i < value.length; i++) {
          form_data.append(`${key}[]`, value[i]);
        }
      } else {
        form_data.append(key, value);
      }
    }
  }

  return form_data;
};

export const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    //pagination
    const query = {
      "page[number]": page,
      "page[size]": perPage
    };
    //filter
    Object.keys(params.filter || {}).forEach(key => {
      if (resource === 'position' && key === 'category_id') {
        query[`category_id`] = params.filter[key];
      } else if (key === 'deleted_at') {
        query[`filter[trashed]`] = params.filter[key];
      } else {
        query[`filter[${key}]`] = params.filter[key];
      }
    });

    //sort
    if (params.sort && params.sort.field && (resource !== 'orders' || (resource === 'orders' && params.sort.field !== 'id'))) {
      const prefix = params.sort.order === "ASC" ? "" : "-";
      query.sort = `${prefix}${params.sort.field}`;
    }

    const url =
      resource === "products/categories"
        ? `${apiUrl}/${resource}`
        : `${apiUrl}/${resource}?${queryString.stringify(query)}`;
    return await axios.get(url).then(({ data }) => {
      // this changes the keys to include the product type when displaying a list of both static and dynamic products
      const updatedData = resource === 'position' ? data.data.map(resource => ({ ...resource, id: `${resource.id}_${resource.type}` })) : data.data;
      return {
        data: updatedData,
        total: data.meta ? data.meta.pagination.total : '',
      };
    });
  },

  getOne: async (resource, params) => {
    const url =
      resource === "me" || resource === "ring/options" || resource === "website/metrics"
        ? `${apiUrl}/${resource}`
        : `${apiUrl}/${resource}/${params.id}`;

    return await axios.get(url).then(({ data: { data: response } }) => {
      const updatedResponse = response.id ? response : { ...response, id: 1 }
      return {
        data: updatedResponse
      };
    });
  },

  getMany: async (resource, params) => {
    const url = `${apiUrl}/${resource}?filter[id]=${params.ids}`;
    return await axios.get(url).then(({ data }) => {
      return {
        data: data.data,
        total: data.meta ? data.meta.pagination.total : '',
      };
    });
  },

  getManyReference: async (resource, params) => {
    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    // const query = {
    //   sort: JSON.stringify([field, order]),
    //   range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //   filter: JSON.stringify({
    //     ...params.filter,
    //     [params.target]: params.id
    //   })
    // };

    const url = `${apiUrl}/${resource}`;
    return await axios.get(url).then(({ data: response, headers }) => {
      const data = response[resource];
      return {
        data,
        total: headers["content-range"]
      };
    });
  },

  update: async (resource, params) => {
    if (resource === "shipping" || resource === "account/shipping") {
      const cost = Number(params.data.cost.replace(/[^0-9\.]+/g, ""));
      return await axios
        .patch(`${apiUrl}/${resource}/${params.id}`, { cost })
        .then(({ data }) => ({
          data
        }));
    }

    if (resource === "position") {
      return await axios
        .patch(`${apiUrl}/${resource}`, params.data)
        .then(({ data }) => ({
          data
        }));
    }

    if (resource === "accounts/restore") {
      return await axios
        .patch(`${apiUrl}/accounts/${params.id}/restore`)
        .then(({ data }) => ({
          data
        }));
    }

    if (resource === "stores/restore") {
      return await axios
        .patch(`${apiUrl}/stores/${params.id}/restore`)
        .then(({ data }) => ({
          data
        }));
    }



    if (resource === "stores") {
      const { parent_store_id, is_parent_store } = params.data;
      const parentStoreId = is_parent_store ? null : parent_store_id;
      const updatedData = { ...params.data, parent_store_id: parentStoreId };
      return await axios
        .put(`${apiUrl}/${resource}/${params.id}`, updatedData)
        .then(({ data }) => ({
          data
        }));
    }

    if (resource === SignetSignetPricingDataResources) { // modify the data before sending to the server
      if (![0, 2].includes(params.data.attribute_id)) params.data.attribute_id = params.data.inlay_type_id;
      delete params.data.attribute_type_name;
      delete params.data.size;
      delete params.data.size_name;
      delete params.data.inlay;
      delete params.data.ring_material_name;
      delete params.data.inlay_type_id;
      delete params.data.attribute_name;
      if (params.data.attribute_id === 0 ) params.data.attribute_id = null;
    }

    if (resource === SignetSignetInlayResources) { // modify the data before sending to the server
      delete params.data.inlay_type_name;
      delete params.data.option_category_name;
    }

    return await axios
      .put(`${apiUrl}/${resource}/${params.id}`, params.data)
      .then(({ data }) => ({ data: { id: '', ...data } }));
  },

  updateMany: async (resource, params) => {
    if (resource === "accounts/restore") {
      return await Promise.all(
        params.ids.map(id => axios.patch(`${apiUrl}/accounts/${id}/restore`))
      ).then(({ data }) => ({
        data
      }));
    }

    if (resource === "stores/restore") {
      return await Promise.all(
        params.ids.map(id => axios.patch(`${apiUrl}/stores/${id}/restore`))
      ).then(({ data }) => ({
        data
      }));
    }

    if (resource === "admin/orders/sync") {
      return await axios
        .post(`${apiUrl}/${resource}`, { orders: params.orders })
        .then(() => ({
          data: params.orders
        }));
    }

    return Promise.all(
      params.ids.map(id => axios.patch(`${apiUrl}/${resource}/${id}`))
    ).then(({ data }) => ({
      data
    }));
  },

  create: async (resource, data) => {
    if (resource === "stores") {
      const { parent_store_id, is_parent_store } = data.data;
      const parentStoreId = is_parent_store ? null : parent_store_id;
      const updatedData = { ...data.data, parent_store_id: parentStoreId };
      return await axios
        .post(`${apiUrl}/${resource}`, updatedData)
        .then(({ data }) => ({ data: { id: '', ...data } }));
    }
    if (resource === SignetSignetPricingDataResources) { // modify the data before sending to the server
      delete data.data.attribute_type_name;
      delete data.data.ring_material_name;
      delete data.data.Inlay;
      delete data.data.size_name;
      if (data.data.attribute_id === 0 ) data.data.attribute_id = null;
    }
    if (resource === SignetSignetInlayResources) { // modify the data before sending to the server
      delete data.data.inlay_type_name;
      delete data.data.option_category_name;
    }

    return await axios
      .post(`${apiUrl}/${resource}`, data.data)
      .then(({ data }) => ({ data: { id: '', ...data } }));
  },

  delete: async (resource, params) =>
    await axios
      .delete(`${apiUrl}/${resource}/${params.id}`)
      .then(({ data }) => ({ data: { id: '', message: 'Deleted Successfully' } })),

  deleteMany: async (resource, params) => {
    return Promise.all(
      params.ids.map(id => axios.delete(`${apiUrl}/${resource}/${id}`))
    ).then(({ data }) => ({ data: [{ id: '', message: 'Deleted Successfully' }] }))
  }
};

export default dataProvider;