import React from "react";
import { Show, SimpleShowLayout, TextField, DateField } from "react-admin";
import ItemsTable from "./order-items";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {},
  marginTop: {
    marginTop: "20px"
  },
  capitalize: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
}));

const AddressList = ({record: {addresses}}) => {
  const classes = useStyles();

  return addresses ?  <Grid container>
    {addresses.map(address => (
        <Grid
            item
            container
            xs={6}
            direction="column"
            key={`${address.first_name} ${address.address_type}`}
        >
          <Grid item className={classes.capitalize}>{address.address_type === 'both' ? 'Billing and Shipping Address': `${address.address_type} Address`}</Grid>
          <Grid item>{`${address.first_name} ${address.last_name}`}</Grid>
          <Grid item>{address.company}</Grid>
          <Grid item>{address.street_address1}</Grid>
          <Grid item>{address.street_address2}</Grid>
          <Grid item>{address.city}</Grid>
          <Grid item>{address.state}</Grid>
          <Grid item>{address.zip}</Grid>
          <Grid item>{address.country_code}</Grid>
          <Grid item>{address.phone_number}</Grid>
        </Grid>
    ))}
  </Grid> : null;
};
const OrderShow = props => {
  const classes = useStyles();
  const { resource } = props;
  return (
    <Show {...props} className="view-order">
      <SimpleShowLayout>
        <TextField source="order_id" label="Order ID" />
        <TextField source="status" />
        <TextField source="customer_firstname" />
        <TextField source="customer_lastname" />
        <TextField source="customer_email" />
        <DateField source="created_at" />
        <TextField source="checkout_method" />
        <TextField source="total_amount" />
        <TextField source="shipping_cost" />
        <TextField source="processing_fee" label="Processing fee" />
        <TextField source="tax_amount" label="Sales Tax" />
        <TextField source="shipping_method_name" label="Shipping Method" />
        <TextField source="grand_total" />
        <DateField source="shipping_date" />
        {resource === 'store/orders' && <TextField source="po_number"/>}
        {resource === 'store/orders' && <TextField source="notes"/>}
        <ItemsTable isStoreOrder={resource === 'store/orders'}/>
        <AddressList className={classes.marginTop} />
      </SimpleShowLayout>
    </Show>
  );
};

export default OrderShow;
