import React, {Fragment} from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
    ReferenceField,
  DateField,
  EditButton,
  Loading,
  useRedirect,
  useMutation,
  useNotify,
  useRefresh,
  useUpdate, BulkDeleteButton, useListContext, useUpdateMany,
} from "react-admin";
import Button from '@material-ui/core/Button';
import RestoreIcon from '@material-ui/icons/Restore';
import ReactivateStore from "./ReactivateStore";
import {StoresFilter} from "./StoresFilter";
import LinkToAccounts from "./LinkToAccounts";

const CustomBulkActionButtons = props => {
  const { filterValues, selectedIds, onUnselectItems } = useListContext();
  const showMultiReactivate = filterValues && filterValues.deleted_at && filterValues.deleted_at === 'only';

  const restoreHandler = () => {
      if (selectedIds.length > 0) {
          props.reactivateManyStores(selectedIds)
              .then(() => onUnselectItems())
      }
  };

 return <Fragment>
     {!showMultiReactivate && props.delete && <BulkDeleteButton {...props} undoable={false}/>}
   {showMultiReactivate &&
   <Button
       size="small"
       color="secondary"
       startIcon={<RestoreIcon />}
       onClick={restoreHandler}
   >
     Reactivate
   </Button>}
  </Fragment>
};

const CustomEdit = ({ record: { deleted_at, id, is_parent_store } }) => {
    const redirect = useRedirect();
    const [getDetails] = useMutation({
        type: "getOne",
        resource: "stores",
        payload: { id: id }
    });

    const handleEdit = async e => {
        e.preventDefault();
        getDetails();
        redirect(`/stores/${id}?p=${is_parent_store ? 1 : 0}`);
    };

    return deleted_at ? null : <EditButton onClick={handleEdit} />;
};

const StoresList = props => {
  const {permissions} = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate("stores/restore");
  const [updateMany] = useUpdateMany("stores/restore");

  if (!permissions) return <Loading />;
  const permissionsList = permissions.split(",");

  const reactivateStore = async data => {
    await update(
        {
          payload: { id: data }
        },
        {
          onSuccess: () => {
            refresh();
            notify("Store Restored", 2000);
          },
            onFailure: ({response: { data }}) => {
                const {message} = data;
                notify(message || "Something went wrong", 2000);
            }
        }
    );
  };

  const reactivateManyStores = async data => {
      await updateMany(
          {
              payload: { ids: [...data] }
          },
          {
              onSuccess: () => {
                  refresh();
                  notify("Stores Restored", 2000);
              },
              onFailure: ({response: { data }}) => {
                  const {message} = data;
                  notify(message || "Something went wrong", 2000);
              }
          }
      );
  };

  return (
    <List
      {...props}
      exporter={false}
      filters={<StoresFilter />}
      bulkActionButtons={<CustomBulkActionButtons reactivateManyStores={reactivateManyStores} delete={permissionsList.includes("delete_stores")} />}
    >
            <Datagrid rowClick={null}>
                <TextField source="store_name" />
                <TextField source="store_number" />
                <ReferenceField source="parent_store_id" reference="stores" sortable={false} linkType={false}><TextField source="store_name" /></ReferenceField>
                <BooleanField source="is_parent_store" sortable={false} />
                <TextField source="retail_multiplier" sortable={false} />
                <TextField source="netsuite_id" />
                <DateField source="created_at" />
                <DateField source="updated_at" sortable={false} />
                <LinkToAccounts />
                {permissionsList.includes("edit_stores") && <CustomEdit />}
                <ReactivateStore reactivateStore={reactivateStore} />
            </Datagrid>
        </List>
  );
};

export default StoresList;
