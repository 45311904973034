import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    Loading,
    NumberInput,
    useNotify,
    Notification,
} from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import WithProps from "../../Custom/WithProps";
import { makeStyles } from '@material-ui/core/styles';
import {
    validateRequired,
} from "../../../utils/Validations";
import {  EditToolBar, PERMISSION_LIST_SIGNET, SignetInlayFinishes, SignetSignetInlayTypes, SignetSignetOptionCategories } from "../util";
import MultiSelect from "./MultiSelect";
import CustomSchoolSelect from "../CustomSchoolSelect";

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});



const SignetInlayEdit = props => {
    const classes = useStyles();
    const { permissions } = props;
    const notify = useNotify();

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");


    const onFailure = (error) => {
        notify(error.response.data.message ? error.response.data.message : 'Something went wrong', { type: 'warning' });
    };

    return (
        <ThemeProvider >
            <Edit mutationMode="pessimistic" {...props} onFailure={onFailure} className='overflow-visible'>
                <WithProps>
                    {({ record, ...props }) =>
                    (<SimpleForm record={record} {...props} undoable={false} toolbar={<EditToolBar delete={permissionsList.includes(PERMISSION_LIST_SIGNET.delete_signet_data)} />}>
                        <Grid container className={classes.container} justify="space-between">

                        <Grid item xs={5}>
                            <p className="label">Inlay Type Id</p>

                            <CustomSchoolSelect isEdit={true} idsource='inlay_type_id' resource={SignetSignetInlayTypes} source="inlay_type_name" />
                        </Grid>

                        <Grid item xs={5}>
                            <p className="label">Option Category Name</p>

                            <CustomSchoolSelect isEdit={true}  idsource='option_category_id' resource={SignetSignetOptionCategories}  source="option_category_name" />
                        </Grid>

                        <Grid item xs={5}>
                            <TextInput
                                source="name" label="Name" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput
                                source="netsuite_id" label="Netsuite Id" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput
                                source="netsuite_inlay_group" label="Netsuite Inlay Group" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput
                                source="netsuite_inlay_type" label="Netsuite Inlay Type" validate={[validateRequired]} fullWidth />
                        </Grid>


                        <Grid item xs={5}>
                            <TextInput
                                source="sku_code" label="Sku Code" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberInput label="Sort Order" source="sort_order" defaultValue="0" fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <MultiSelect reference={SignetInlayFinishes} label="Supported Finishes" source="supported_finishes" />
                        </Grid>
                        </Grid>
                    </SimpleForm>)
                    }
                </WithProps>
            </Edit>
            <Notification />
        </ThemeProvider>
    )
};

export default SignetInlayEdit;