import React from "react";
import { Datagrid, ImageField, EditButton, List, Loading, TextField, useMutation, useRedirect } from "react-admin";
import { StoreFilter } from "./filter";
import { makeStyles } from "@material-ui/core/styles";

import { MaterialResources } from "./util";
const useStyles = makeStyles(() => ({
  image: {
    width: "100px",
    height: "100%",
    textAlign: "center"
  },
  table: {
    background: '#fafafa',
    flexGrow: 1,
    width: 'auto',
    overflow: 'auto',
  }
}));

const CustomEdit = ({ record: { id } }) => {
  const redirect = useRedirect();
  const [getDetails] = useMutation({
    type: "getOne",
    resource: MaterialResources,
    payload: { id: id }
  });

  const handleEdit = async e => {
    e.preventDefault();
    getDetails();
    redirect(`/${MaterialResources}/${id}`);
  };

  return <EditButton onClick={handleEdit} />;
};

const MaterialList = props => {
  const { permissions } = props;

  const classes = useStyles();
  if (!permissions) return <Loading />;
  const permissionsList = permissions.split(",");

  return (
    <List
      {...props}
      exporter={false}
      filters={<StoreFilter />}
    >
      <Datagrid rowClick={null}>
        <TextField source="material" label="Material name" />
        <ImageField
          source="image_url"
          className={classes.image}
          label="Ring Preview"
          sortable={false}
        />
        <ImageField
          source="emblem_image_url"
          className={classes.image}
          label="Emblem Ring Preview"
          sortable={false}
        />
        <TextField source="short_description" sortable={false} label="Description" />
        <TextField source="weight" sortable={false} label="Weight" />
        <TextField source="scratch_resistant" sortable={false} label="Scratch Resistant" />



        {permissionsList.includes("edit_material_data") && <CustomEdit />}
      </Datagrid>
    </List>
  );
};

export default MaterialList;