import React from "react";
import { PieChart, Pie, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {GRAPH_COLORS, labelFormatter} from "../Constants";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    noData: {
        textAlign: 'center',
        marginTop: '5%',
    }
});

const Revenues = ({ b2b, b2c, trends, expanded = false }) => {
    const classes = useStyles();

    const revenuePie = [{ name: "B2B", value: b2b,}, { name: "B2C", value: b2c,}];
    return (
        expanded ?
            <ResponsiveContainer width="100%" height={250}>
                <BarChart
                    width={500}
                    data={trends}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis yAxisId="left" orientation="left" stroke={GRAPH_COLORS.solid1} unit="$" />
                    <YAxis yAxisId="right" orientation="right" stroke={GRAPH_COLORS.solid2} unit="$" />
                    <Tooltip labelFormatter={labelFormatter} />
                    <Legend />
                    <Bar name="B2B" yAxisId="left" dataKey="revenue_b2b" fill={GRAPH_COLORS.solid1} />
                    <Bar name="B2C" yAxisId="right" dataKey="revenue_b2c" fill={GRAPH_COLORS.solid2} />
                </BarChart>
            </ResponsiveContainer>:
            <div style={{ width: '100%', height: 300 }}>
                {b2b === 0 && b2c === 0 ? <div className={classes.noData}>No data</div> :
                    <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            dataKey="value"
                            data={revenuePie}
                            fill={GRAPH_COLORS.pie1}
                            isAnimationActive={false}
                            label={({
                                        cx,
                                        cy,
                                        midAngle,
                                        innerRadius,
                                        outerRadius,
                                        value,
                                        index
                                    }) => {
                                const RADIAN = Math.PI / 180;
                                // eslint-disable-next-line
                                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                // eslint-disable-next-line
                                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                // eslint-disable-next-line
                                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                return (
                                    <text
                                        x={x}
                                        y={y}
                                        textAnchor={x > cx ? "start" : "end"}
                                        dominantBaseline="central"
                                    >
                                        {`$${value}`} ({revenuePie[index].name})
                                    </text>
                                );
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>}
            </div>
    )
};

export default Revenues;