import React from "react";
import {
    BooleanInput,
    Create,
    FormDataConsumer,
    SimpleForm,
    TextInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import CreateToolbar from "../Custom/create-save-button";
import {
    validateDomain,
    validateRequired
} from "../../utils/Validations";
import {makeStyles} from "@material-ui/core/styles";
import './index.css';
import CustomStoreSelect from "./CustomStoreSelect";
import { PlaformSelect } from "./ShopifyStoreEdit";

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

export const ShopifyStoreCreate = props => {
    const classes = useStyles();
    return (
        <Create {...props} className="overflow-visible">
            <SimpleForm toolbar={<CreateToolbar/>}>
                <Grid container className={classes.container} justify="space-between">
                    <Grid item xs={5}>
                        <p className="label">Platform Name</p>
                        <PlaformSelect />
                    </Grid>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            console.log('formData', formData)
                            if(formData.platform_name === 'magento') {
                                return (
                                    <>
                                        <Grid item xs={5}>
                                            <TextInput type="number" source="attribute_set_id" label="Attribute Id" validate={validateRequired} fullWidth/>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextInput type="number" source="category_id" label="Category Id" fullWidth/>
                                        </Grid>
                                    </>
                                )
                            }
                        }}
                    </FormDataConsumer>
                    <Grid item xs={5}>
                        <TextInput source="store_name" validate={validateRequired} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="keys.api_key" label="Api key" validate={validateRequired} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="keys.password" label="Password" validate={validateRequired} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput placeholder="weareemblem.myshopify.com/" source="base_url" label="Store url" validate={[validateRequired, validateDomain]} fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput placeholder="customize" source="subpath" label="Subpath" validate={validateRequired} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput placeholder="USD / CAD / GBP" source="currency" label="Currency" validate={validateRequired} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <BooleanInput source="is_approved" label="Subscribed"/>
                        <BooleanInput source="show_women_builder" defaultValue={false}/>
                        <BooleanInput source="show_signet_builder" defaultValue={false}/>
                        <BooleanInput source="show_collegiate_builder" defaultValue={false}/>
                    </Grid>
                    <Grid item xs={5}>
                        <p className="label">Store</p>
                        <CustomStoreSelect />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
)};