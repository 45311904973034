import React, { useCallback, useEffect, useState } from "react";
import { useInput } from "react-admin";
import AsyncSelect from "react-select/async";
import axios from 'axios';
import { apiUrl } from "../../../dataProvider";
import { FormHelperText } from '@material-ui/core';

import './../style.css';

const CustomSelect = ({ isEdit, validate = [], pageSize = 1000, idsource = '', isDisabled = false, source = 'school', resource = 'schools', emptyCarakote = false, ...props }) => {
    const [list, setList] = useState([]);
    const [searchValue, setSearchValue] = useState();

    const { input,
        meta: { touched, error, }
    } = useInput({ source, validate })

    const { input: inputSourceId } = useInput({ source: idsource })

    const getSchoolOptions = (params) => axios.get(`${apiUrl}/${resource}`, { params }).then(({ data }) => data.data)
    const getSearchOptions = value => getSchoolOptions({ 'filter[name]': value });

    const setStoreList = useCallback(async (params) => {
        const response = await getSchoolOptions(params);
        if (isEdit) {
            const selected = response.find(item => {
                return item.sku_code === input.id;
            });
            if (selected) input.onChange(selected?.id);
        }
        setList(response);
    }, [isEdit, input])

    /** This effect calls initial load with  and when you clear value  */
    useEffect(() => {
        if (list.length === 0) {
            setStoreList(
                {
                    'page[number]': 1,
                    'page[size]': pageSize
                }
            );
        }
    }, [list.length, setStoreList])


    const handleChange = callback => selectedopt => {
        if (searchValue) {
            setStoreList({ 'filter[name]': searchValue });
        }



        if (idsource) {
            inputSourceId.onChange(selectedopt?.id); // sets the id properties
            callback(selectedopt?.name)
        } else {
            callback(selectedopt?.id);
        }

    }

    return (
        <div>
            <AsyncSelect
                {...input}
                isClearable
                cacheOptions
                styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                }}
                defaultOptions={list}
                isDisabled={isDisabled}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                value={list.find(item => item.id === input.value)} // value must be object, so get selected opt from input.value
                loadOptions={getSearchOptions}
                onChange={handleChange(input.onChange)}
                onInputChange={setSearchValue}
            />
            {touched && error && <FormHelperText error>{error === 'ra.validation.required' ? "Required" : error}</FormHelperText>}
        </div>
    )
}

export default CustomSelect;