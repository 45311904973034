import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button, Box, IconButton } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2.3),
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.01)',
    },
  },
  dragActive: {
    borderColor: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  uploadIcon: {
    fontSize: 48,
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    display: 'none',
  },
  uploadText: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  browseButton: {
    marginTop: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  previewContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  preview: {
    width: '100%',
    maxWidth: 200,
    maxHeight: 200,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '& object': {
      maxWidth: '100%',
      maxHeight: 200,
      width: 'auto',
      height: 'auto',
    },
  },
  previewWrapper: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: 200,
    position: 'relative',
  },
  previewTitle: {
    textAlign: 'left',
    width: '100%',
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  previewFileName: {
    textAlign: 'left',
    width: '100%',
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  closeButton: {
    position: 'absolute',
    top: -12,
    right: -12,
    backgroundColor: theme.palette.background.paper,
    padding: 4,
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  uploadNewButton: {
    marginTop: theme.spacing(2),
    fontSize: '0.875rem',
    textTransform: 'none',
  },
}));

const SvgUpload = ({ file, onFileChange }) => {
  const classes = useStyles();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }

    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [file]);

  const validateFile = (file) => {
    if (!file) return false;

    if (!file.type.includes('svg')) {
      setError('Please upload an SVG file');
      return false;
    }

    setError('');
    return true;
  };

  const handleFile = (file) => {
    if (validateFile(file)) {
      onFileChange?.(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragActive(false);

    const droppedFile = e.dataTransfer.files[0];
    handleFile(droppedFile);
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    handleFile(selectedFile);
  };

  if (file && previewUrl) {
    return (
      <Box className={classes.previewContainer}>
        <div className={classes.previewWrapper}>
          <IconButton size="small" className={classes.closeButton} onClick={() => onFileChange(null)}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Typography variant="subtitle2" className={classes.previewTitle}>
            Preview
          </Typography>
          <div
            className={classes.preview}
            dangerouslySetInnerHTML={{
              __html: `<object data="${previewUrl}" type="image/svg+xml"></object>`,
            }}
          />
          <Typography variant="body2" className={classes.previewFileName}>
            {file.name}
          </Typography>
        </div>
        <Button
          variant="text"
          color="primary"
          startIcon={<CloudUploadIcon />}
          onClick={() => document.getElementById('svg-file-upload').click()}
          className={classes.uploadNewButton}
        >
          Upload New
        </Button>
        <input accept=".svg" className={classes.input} id="svg-file-upload" type="file" onChange={handleChange} />
      </Box>
    );
  }

  return (
    <Box width="100%">
      <input accept=".svg" className={classes.input} id="svg-file-upload" type="file" onChange={handleChange} />
      <label htmlFor="svg-file-upload">
        <Paper
          className={`${classes.dropzone} ${dragActive ? classes.dragActive : ''}`}
          onDragOver={(e) => {
            e.preventDefault();
            setDragActive(true);
          }}
          onDragLeave={() => setDragActive(false)}
          onDrop={handleDrop}
          component="div"
        >
          <CloudUploadIcon className={classes.uploadIcon} />
          <Typography variant="body1" className={classes.uploadText}>
            Drag and drop your SVG file here
          </Typography>
          <Button variant="contained" color="primary" component="span" className={classes.browseButton}>
            Browse Files
          </Button>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Paper>
      </label>
    </Box>
  );
};

export default SvgUpload;
