import React, { useCallback, useEffect, useState } from "react";
import { useInput } from "react-admin";
import AsyncSelect from "react-select/async";
import axios from 'axios';
import { apiUrl } from "../../dataProvider";
import './index.css';

const CustomStoreSelect = ({isMulti=false}) => {
    const [list, setList] = useState([]);
    const [searchValue, setSearchValue] = useState();

    const { input } = useInput({ source: 'material' })
    const { input: inputSku } = useInput({ source: 'sku_code' })
    const getMaterialOptions = (params) => axios.get(`${apiUrl}/material-list`, { params }).then(({ data }) => data.map((item) => ({ value: item.name, label: item.name, ...item })))


    useEffect(() => {
        if (input?.value && Array.isArray(input?.value)) {
            inputSku.onChange(input?.value.map(item => item.sku_code).join(', '));
        }
    }, [input.value, list])



    const getSearchOptions = value => {

        const filteredItems = list.filter((item) =>
            item.label.toLowerCase().includes(value.toLowerCase())
        );

        // Partition filtered items into matching and non-matching arrays
        const [matchingItems, nonMatchingItems] = filteredItems.reduce(
            (acc, item) => {
                const index = item.label
                    .toLowerCase()
                    .startsWith(value.toLowerCase())
                    ? 0
                    : 1;
                acc[index].push(item);
                return acc;
            },
            [[], []]
        );

        // Concatenate matching and non-matching items
        const arrangedItems = [...matchingItems, ...nonMatchingItems];
        return Promise.resolve(arrangedItems).catch([]);

    };

    const setStoreList = useCallback(async (params) => {
        const response = await getMaterialOptions(params);
        setList(response);
    }, [])

    useEffect(() => {
        if (input.value) {
            setStoreList({ 'filter[material]': input.value })
        }
    }, []);

    /** This effect calls initial load with  and when you clear value  */
    useEffect(() => {
        if (!input.value) {
            setStoreList();
        }
    }, [input.value, setStoreList])


    const handleChange = callback => selectedopt => {
        if (searchValue) {
            setStoreList({ 'filter[material]': searchValue });
        }
        callback(selectedopt);
    }

    return (<AsyncSelect
        {...input}
        isClearable
        cacheOptions
        styles={{
            menu: provided => ({ ...provided, zIndex: 9999 }),
        }}
        defaultOptions={list}
        getOptionLabel={e => e.label}
        getOptionValue={e => e.sku_code}
        isMulti={isMulti}
        value={list.find(item => item.sku_code === input.sku_code)} // value must be object, so get selected opt from input.value
        loadOptions={getSearchOptions}
        onChange={handleChange(input.onChange)}
        onInputChange={setSearchValue}
    />)
}

export default CustomStoreSelect;