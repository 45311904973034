import { Slider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { getValueLabelFormat } from '../utils/formatter';

const useStyles = makeStyles((theme) => ({
  relativeScaleSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: 'auto',
    marginLeft: 'auto',
  },
  relativeScaleLabel: {
    whiteSpace: 'nowrap',
  },
  sliderWrapper: {
    width: 200,
  },
  scaleBadge: {
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.75rem',
    fontWeight: 500,
    minWidth: 60,
    textAlign: 'center',
  },
}));

const RelativeScaleSlider = ({ shape, shapeConfig, onScaleChange, disabled }) => {
  const classes = useStyles();
  const [scale, setScale] = useState(undefined);

  useEffect(() => {
    const enabledSizes = shape.children.filter((size) => size.checked);
    if (!enabledSizes.length) {
      setScale(0);
    } else if (scale === undefined) {
      setScale(
        Math.max(
          ...enabledSizes.map((size) => {
            const sizeConfig = shapeConfig?.sizes.find((s) => s.size_sku === size?.sku);
            return sizeConfig?.shanks[0]?.scale || 0;
          }),
        ),
      );
    }
  }, [shape, shapeConfig]);

  const handleChange = (_, newValue, event) => {
    event?.stopPropagation();

    // Calculate the absolute difference
    const difference = newValue - scale;

    // Apply the absolute difference to all enabled sizes
    onScaleChange(shape.id, difference, event);
    setScale(newValue);
  };

  return (
    <div className={classes.relativeScaleSection} onClick={(e) => e?.stopPropagation()}>
      <Typography variant="body2" className={classes.relativeScaleLabel}>
        Relative Scale
      </Typography>
      <div className={classes.sliderWrapper}>
        <Slider
          value={scale || 0}
          onChange={handleChange}
          min={-50}
          max={50}
          step={1}
          disabled={disabled}
          valueLabelDisplay="auto"
          valueLabelFormat={getValueLabelFormat}
        />
      </div>
      <Typography variant="body2" className={classes.scaleBadge}>
        {getValueLabelFormat(scale)}
      </Typography>
    </div>
  );
};

export default RelativeScaleSlider;
