import React from "react";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer } from "recharts";
import {GRAPH_COLORS, labelFormatter} from "../Constants";

const AvgSessionDuration = ({ trends }) => {
    return (
        <ResponsiveContainer width="100%" height={250}>
            <LineChart width={500} data={trends}
                       margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip labelFormatter={labelFormatter} />
            <Legend verticalAlign="top" />
            <Line name="Session duration (min)" type="monotone" dataKey="avgSessionDurationTrend" stroke={GRAPH_COLORS.line1} />
            </LineChart>
        </ResponsiveContainer>
    )
};

export default AvgSessionDuration;