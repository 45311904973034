import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#003053",
      light: "#385980",
      dark: "#00062a",
      contrastText: "#fff"
    },
    secondary: {
      main: "#003053",
      light: "#fff9ad",
      dark: "#a9964f",
      contrastText: "#fff"
    }
    //type: "dark"
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif"
    ].join(",")
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        color: "inherit"
      },
      contained: {
        extend: "root"
      },
      containedPrimary: {
        extend: "contained",
        backgroundColor: `#dcc67d`,
        border: "1px solid #dcc67d",
        "&:hover": {
          backgroundColor: `#d1b455`,
          border: "1px solid #d1b455"
        }
      }
    },
    RaMenuItemLink: {
      root: {
        borderLeft: "4px solid #fff"
      },
      active: {
        borderLeft: "4px solid #385980",
        backgroundColor: "#c9e8ff"
      },
      hover: {
        backgroundColor: "#c9e8ff"
      }
    },
    RaSidebar: {
      drawerPaper: {
        MuiSvgIcon: {
          root: {
            color: "#385980"
          }
        }
      }
    },
    MuiTableRow: {
      head: {
        backgroundColor: "lightgray",
        "& > th ": {
          color: "#fff",
          backgroundColor: "#385980",
          fontWeight: "bold",
          padding: "15px"
        }
      }
    },
    MuiTableCell: {
      sizeSmall: {
        padding: "12px 24px 12px 16px"
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#ffffff",
        "&.Mui-focused": {
          backgroundColor: "#ffffff"
        },
        "&:hover": {
          backgroundColor: `#ffffff`
        },
        "&.Mui-disabled": {
          borderRadius: "3px",
          "&:hover": {
            borderBottom: "none"
          },
          "&:before": {
            borderBottomStyle: "none"
          }
        }
      },
      underline: {
        "&:hover": {
          borderBottom: `1px solid #003053`
        },
        "&.Mui-disabled": {
          "&:before": {
            borderBottomStyle: "none"
          }
        }
      }
    },
    RaImageInput: {
      dropZone: {
        backgroundColor: "#fff9ad",
        borderRadius: "10px",
        width: "25%",
        padding: "1px",
        "&:hover": {
          border: `1px solid #dcc67d`,
          borderRadius: "10px"
        },
        "&:focus": {
          outline: "none"
        }
      },
      previews: {
        display: "flex"
      },
      preview: {
        maxHeight: "10rem"
      }
    },

    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#dcc67d",
          "&:hover": {
            backgroundColor: "#dcc67d"
          }
        }
      }
    },

    RaImageField: {
      image: {
        height: "inherit",
        width: "inherit",
        margin: "inherit",
        maxHeight: "inherit"
      }
    },
    MuiSwitch: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#a9964f"
        }
      },
      switchBase: {
        color: "#003053"
      }
    },
    MuiInputAdornment: {
      root: {
        position: "absolute",
        right: "-10px"
      }
    }
  },
  sidebar: {
    width: 250, // The default value is 240
    closedWidth: 55 // The default value is 55
  }
});

export default theme;
