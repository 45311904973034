export const GRAPH_COLORS = {
    solid2: '#a9964f',
    solid1: '#002453',
    line1: '#002453',
    pie1: '#002453',
}

export const LIST_COLORS = ["#03459c", "#0458c7", "#007a35", "#009440", "#5b9400", "#bda209", "#e0c426"];

export const HEALTH_COLORS = {
    degraded: '#531300',
    severe: '#530000',
    warning: '#baae02',
    ok: '#005324',
    nodata: '#353836'
}

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
export const labelFormatter = (name) => new Date(name).toLocaleDateString("en-US", options);