import React, {useCallback , useState } from "react";
import axios from "axios";
import { Toolbar, useRedirect, useNotify, DeleteButton, fetchStart, fetchEnd } from "react-admin";
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from 'react-final-form';
import { rawFileExtraction } from "../../dataProvider";
import { useDispatch } from 'react-redux';
import { apiUrl } from "../../dataProvider";

const useStyles = makeStyles(() => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const CustomSave = ({ handleSubmitWithRedirect, resource, action, ...props }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath } = props;
    const form = useForm();
    const tags = props.chipData.map(chip => chip.label);
    form.change("newTags", tags);
    let axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*"
        }
    };

    const handleClick = useCallback(async () => {
        setLoading(true);
        dispatch(fetchStart());

        const values = form.getState().values;

        if(action === 'edit') {
            const {
                employee_price,
                wholesale_price,
                retail_price,
                category_id,
                newTags,
                ...remainder
            } = values;
            const convertedPrices = {
                employee_price: Number(employee_price.replace(/[^0-9\.]+/g, "")),
                wholesale_price: Number(wholesale_price.replace(/[^0-9\.]+/g, "")),
                retail_price: Number(retail_price.replace(/[^0-9\.]+/g, ""))
            };
            const editData = {
                ...remainder,
                ...convertedPrices,
                categories: category_id,
                tags: newTags,
                _method: "PUT"
            };

            const formData = await rawFileExtraction(editData);
            axios
                .post(`${apiUrl}/${resource}/${props.record.id}`, formData, axiosConfig)
                .then(({ data }) => {
                    notify('Product Updated');
                    redirectTo(basePath);
                    return ({
                    data
                })
                })
                .catch(({response: {data: {errors}}}) => {
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message, 'warning')
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(fetchEnd());
                });
        }

        if(action === 'create') {
            const { data: {category_id, ...remaining}, newTags } = values;
            const cleanData = {
                ...remaining,
                categories: category_id,
                tags: newTags,
            };

            const formData = await rawFileExtraction(cleanData);
            axios
                .post(`${apiUrl}/${resource}`, formData, axiosConfig)
                .then(response => {
                    notify('Product Added');
                    redirectTo(basePath);
                    return {
                        data: {
                            ...response.data
                        }
                    };
                })
                .catch(({response: {data: {errors}}}) => {
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message, 'warning')
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(fetchEnd());
                });
        }
    },[form]);

    return <Button
        {...props}
        disabled={form.getState().hasValidationErrors || loading}
        onClick={handleClick}
        startIcon={<SaveIcon/>}
    >
        Save
    </Button>;
};

const CreateToolbar = props => {
    const classes = useStyles();
       return <Toolbar {...props} className={classes.toolbar}>
            <CustomSave
                redirect="list"
                submitOnEnter={false}
                variant="text"
                {...props}
            />
            <DeleteButton undoable={false} />
        </Toolbar>
};

export default CreateToolbar;