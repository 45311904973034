import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Switch, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RelativeScaleSlider from './RelativeScaleSlider';
import ShapeSizeCard from './ShapeSizeCard';
import RelativePaddingSlider from './RelativePaddingSlider';

const useStyles = makeStyles((theme) => ({
  shapeAccordion: {
    marginBottom: theme.spacing(2),
    '&:before': {
      display: 'none',
    },
    backgroundColor: theme.palette.background.default,
  },
  accordionSummary: {
    padding: theme.spacing(0, 2),
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  shapeHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  shapeControls: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    flex: 1,
  },
  shapeName: {
    textTransform: 'capitalize',
    fontWeight: 500,
    textAlign: 'center',
  },
  sizesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    width: '100%',
  },
}));

const ShapeAccordion = ({
  shape,
  shapeConfig,
  expanded,
  onAccordionChange,
  onShapeToggle,
  onSizeToggle,
  onShapeScaleChange,
  onSizeScaleChange,
  onShapePaddingChange,
  onSizePaddingChange,
  onSizeShadowChange,
  onRotationChange
}) => {
  const classes = useStyles();

  return (
    <Accordion
      expanded={expanded === shape.id && shape.checked}
      onChange={onAccordionChange(shape.id)}
      className={classes.shapeAccordion}
    >
      <AccordionSummary expandIcon={shape.checked && <ExpandMoreIcon />} className={classes.accordionSummary}>
        <div className={classes.shapeHeader}>
          <div className={classes.shapeControls}>
            <Switch
              checked={shape.checked}
              onChange={(e) => onShapeToggle(shape.id, e.target.checked)}
              color="primary"
            />
            <Typography className={classes.shapeName}>{shape.name}</Typography>
          </div>

          <div className={classes.shapeControls}>
            <RelativeScaleSlider
              shape={shape}
              shapeConfig={shapeConfig}
              onScaleChange={onShapeScaleChange}
              disabled={!shape.checked}
            />
            <RelativePaddingSlider
              shape={shape}
              shapeConfig={shapeConfig}
              onPaddingChange={onShapePaddingChange}
              disabled={!shape.checked}
            />
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <div className={classes.sizesGrid}>
          {shape.children.map((size) => {
            const sizeConfig = shapeConfig?.sizes.find((s) => s.size_sku === size?.sku);
            return (
              <ShapeSizeCard
                key={size.id}
                size={size}
                sizeConfig={sizeConfig}
                shape={shape}
                shapeEnabled={shape.checked}
                onSizeToggle={onSizeToggle}
                onScaleChange={onSizeScaleChange}
                onPaddingChange={onSizePaddingChange}
                onShadowChange={onSizeShadowChange}
                onRotationChange={onRotationChange}
              />
            );
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ShapeAccordion;
