import React, { useCallback, useState } from 'react';
import { BooleanInput, DeleteButton, SaveButton, Toolbar, useNotify, useRefresh } from 'react-admin';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Typography } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { useHistory } from 'react-router';
import axios from 'axios';
import { apiUrl } from "../../dataProvider";
import { CloudUpload, ImportExport } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';

export const CustomBooleanInput = (props) => {
    return (
        <BooleanInput
            initialValue={0}
            {...props}
            format={(value) => value ? true : false}
            parse={(value) => value ? 1 : 0}
        />
    );
};


export const SignetShapeResources = 'signet-shape'
export const SignetSizeResources = 'signet-size'
export const SignetShankResources = 'shank'
export const SignetCerakoteResources = 'cerakote-color'
export const SignetEngravingResources = 'signet-engravings'
export const SignetEngravingRingMaterialsResources = 'signet-engraving-ring-materials'
export const SignetSchoolCerekoteResources = 'school-cerakote-colors'
export const SignetNonSchoolCerekoteResources = 'non-collegiate-signet-colors'
export const SignetSchoolResources = 'schools'
export const SignetRingDataResources = 'ring-data'
export const SignetFinishesDataResources = 'finishes'
export const SignetSignetPricingDataResources = 'signet-pricing'
export const SignetRingMaterialResources = 'ring-materials'
export const SignetRingAttributeTypeResources = 'attribute-types'
export const SignetSignetInlayResources = 'signet-inlay'
export const SignetSignetOptionCategories = 'option-categories'
export const SignetSignetInlayTypes = 'inlay-types'
export const SignetInlayFinishes = 'inlay-finishes'


export const PERMISSION_LIST_SIGNET = {
    delete_signet_data: 'delete_signet_data',
    edit_signet_data: 'edit_signet_data',
    view_signet_data: 'view_signet_data',
    add_signet_data: 'add_signet_data',
}

export const ResetButton = () => {
    const form = useForm();

    const handleReset = () => {
        form.reset();
    };

    return (
        <Button style={{
            marginLeft: 10,
            marginRight: 10,
        }} variant='text' disabled={!form.getState().dirty} type='submit' onClick={handleReset}>
            Reset
        </Button>
    );
};

export const GoBack = () => {
    const history = useHistory();
    const handleBack = () => {
        history.goBack();
    };

    return (
        <Button style={{
            marginLeft: 10,
            marginRight: 10,
        }} variant='text' type="button" onClick={handleBack}>
            Cancel
        </Button>
    );
};

export const EditToolBar = props => {
    return (
        <Toolbar {...props}>
            <SaveButton />
            <GoBack />
            <DeleteButton undoable={false} />
        </Toolbar>
    )
}

export const downloadCSV = (data, filename = 'export.csv') => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


export const CustomExportButton = ({ resource, exporter, ids, ...rest }) => {

    const [loading, setLoading] = useState(false);
    const notify = useNotify();


    const handleClick = useCallback(() => {
        setLoading(true);
        axios.get(`${apiUrl}/${resource}-export`).then(({ data }) => {
            setLoading(false);
            downloadCSV(data, `${resource}.csv`);
        }).catch(error => {
            console.error(error);
            notify('something went wrong', { type: 'warning' })
            setLoading(false);
        });
    }, [resource, loading])

    return (
        <Button size='small' disabled={ids?.length === 0} onClick={handleClick} startIcon={loading ? <CircularProgress size={20} /> : <GetAppIcon />}>
            {loading ? 'Exporting...' : 'Export'}
        </Button>
    );
};



export const CustomImportButton = ({ resource, ...rest }) => {

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const notify = useNotify();
    const refresh = useRefresh();


    const onFileChange = useCallback((e) => {
        setFile(e.target.files[0]);
    }, [])

    const handleClick = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('resource', resource);
        axios
            .post(`${apiUrl}/${resource}-import`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                notify(data.message, { type: 'success' });
                setFile(null);
                setLoading(false);
                handleClose();
                refresh();
            })
            .catch(
                ({
                    response: {
                        status: { errors },
                    },
                }) => {
                    const message = errors[Object.keys(errors)[0]][0];
                    setLoading(false);
                    notify(message, { type: 'warning' });
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOpen = () => {
        if (file && file.type !== 'text/csv') {
            notify('The selected file is not a CSV file. Please upload a valid CSV file.', { type: 'warning' });
            return;
        }
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setFile(null)
    }

    return (
        <>
            {!file ?
                <>
                    <input id="file-upload" style={{ display: 'none' }}
                        accept=".csv"
                        onChange={onFileChange} type="file" />
                    <label htmlFor="file-upload">
                        <Button
                            size="small"
                            variant="text"
                            component="span"
                            startIcon={<ImportExport />}
                        >
                            Import CSV
                        </Button>
                    </label>
                </>
                :
                <Button size='small' onClick={handleOpen} startIcon={<CloudUpload />}> {file.name}</Button>
            }
            {open && file && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography color='error'>
                                Please ensure that the CSV file contains correct data. Uploading incorrect data can corrupt the database.
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleClose()
                        }}>Cancel</Button>
                        <Button onClick={handleClick} startIcon={loading ? <CircularProgress size={20} /> : <CloudUpload />} autoFocus>
                            {loading ? 'In Progress...' : 'Upload'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};


