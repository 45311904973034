import React from "react";
import { Datagrid, DeleteButton, EditButton, FunctionField, List, Loading, TextField, TextInput, useMutation, useRedirect } from "react-admin";

import { PERMISSION_LIST_SIGNET, SignetSignetPricingDataResources } from "../util";
import ListActions from "../ListActions";


const CustomEdit = ({ record: { id } }) => {
    const redirect = useRedirect();
    const [getDetails] = useMutation({
        type: "getOne",
        resource: SignetSignetPricingDataResources,
        payload: { id: id }
    });

    const handleEdit = async e => {
        e.preventDefault();
        getDetails();
        redirect(`/${SignetSignetPricingDataResources}/${id}`);
    };

    return <EditButton onClick={handleEdit} />;
};
const SignetPricingList = props => {
    const { permissions } = props;

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");
    return (
        <List
            {...props}
            exporter={false}
            actions={<ListActions filters={
                [<TextInput source="attribute_type_name" label="Name" defaultValue="" />,
                <TextInput source="size_name" label="Size" defaultValue="" />,
                <TextInput source="ring_material_name" label="Ring Material" defaultValue="" />,
                <TextInput source="attribute_name" label="Attribute" defaultValue="" />,]
            } />}

        >
            <Datagrid rowClick={null}>
                <TextField source="attribute_type_name" label="Name" />
                <TextField source="ring_material_name" sortable={false} label="Ring Material" />
                <FunctionField
                    label="Attribute"
                    source="attribute_name"
                    render={record => {
                        return record?.attribute_name ? `${record.attribute_name} ` : 'N/A'
                    }}
                />
                <TextField source="size_name" sortable={false} label="Size" />
                <TextField source="amount" sortable={false} label="Amount" />
                {permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) && <CustomEdit />}
                {permissionsList.includes(PERMISSION_LIST_SIGNET.delete_signet_data) && (
                    <DeleteButton label="" undoable={false} />
                )}
            </Datagrid>
        </List>
    );
};

export default SignetPricingList;