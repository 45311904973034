import React from "react";
import {
    Create,
    Notification,
    NumberInput,
    SimpleForm,
    TextInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import CreateToolbar from "../../Custom/create-save-button";
import {
    validateNumberGreaterThanZero,
    validateRequired
} from "../../../utils/Validations";
import { ThemeProvider } from "@material-ui/styles";

import { makeStyles } from "@material-ui/core/styles";
import { CustomBooleanInput, SignetEngravingRingMaterialsResources, SignetShapeResources, SignetSignetInlayResources, SignetSizeResources } from "../util";
import '../style.css';
import CustomMultiSelect from "../CustomMultiSelect";
import { FormEngraving } from "./FormEngraving";
const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

const SignetEngravingCreate = props => {
    const classes = useStyles();

    return (
        <ThemeProvider>
            <Create {...props} className="overflow-visible" >
                <SimpleForm toolbar={<CreateToolbar />} >
                    <Grid container className={classes.container} justify="space-between">
                        <FormEngraving />
                        <Grid item xs={5}>
                            <CustomMultiSelect reference={SignetSizeResources} label="Sizes Allowed" source="sizes_allowed" />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomMultiSelect reference={SignetShapeResources} label="Shapes Allowed" source="shapes_allowed" />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomMultiSelect reference={SignetEngravingRingMaterialsResources} label="Materials Allowed" source="ring_materials_allowed" optionValue="id"/>
                        </Grid>
                        <Grid item xs={5}>
                            <CustomMultiSelect reference={SignetSignetInlayResources} label="Inlays Allowed" source="inlay_materials_allowed" optionValue="id"/>
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput
                                source="name" label="Name" validate={[validateRequired]} fullWidth />
                        </Grid>

                        <Grid item xs={5}>
                            <TextInput
                                source="sku_code" label="Sku Code" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <NumberInput label="Price Markup" source="price_markup" defaultValue="1" validate={[validateNumberGreaterThanZero]} />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomBooleanInput source="active" label="Active" />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomBooleanInput source="allow_cerakote" label="Allow Cerakote" />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomBooleanInput source="has_outside_reversed_option" label="Has Outside Reversed Option" />
                        </Grid>

                    </Grid>
                </SimpleForm>
            </Create>
            <Notification />
        </ThemeProvider >
    )
};

export default SignetEngravingCreate;