import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import CreateToolbar from "../../Custom/create-save-button";
import {
    validateRequired
} from "../../../utils/Validations";
import { makeStyles } from "@material-ui/core/styles";
import { CustomBooleanInput } from "../util";

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

const SignetSizeCreate = props => {
    const classes = useStyles();

    return (
        <Create {...props} className="overflow-visible" >
            <SimpleForm toolbar={<CreateToolbar />} >
                <Grid container className={classes.container} justify="space-between">
                    <Grid item xs={5}>
                        <TextInput
                            source="name" label="Name" validate={[validateRequired]} fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput
                            source="sku_code" label="Sku Code" validate={[validateRequired]} fullWidth />
                    </Grid>

                    <Grid item xs={5}>
                        <CustomBooleanInput source="active" label="Active" />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};

export default SignetSizeCreate;