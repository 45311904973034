import React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    EditButton,
    Loading
} from "react-admin";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import CategoryPanel from "./CategoryPanel";

const ProductsLink = ({record}) => (
    <Link
        to={{
            pathname: '/position',
            search: queryString.stringify({
                page: 1,
                perPage: 25,
                sort: "position",
                order: "ASC",
                filter: JSON.stringify({ category_id: record.id }),
            }),
        }}
    >
        View Products
    </Link>
);
const CategoryList = props => {
    const { permissions } = props;

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    return (
        <List {...props} exporter={false} bulkActionButtons={false}>
            <Datagrid rowClick={null} expand={<CategoryPanel hasEdit={false} />}>
                <TextField source="name" />
                <BooleanField source="active" sortable={false} />
                <TextField source="category_sku" sortable={false} />
                <ProductsLink sortable={false} />
                {permissionsList.includes("edit_categories") && <EditButton />}
            </Datagrid>
        </List>
)};

export default CategoryList;