import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import {makeStyles} from "@material-ui/core/styles";
import {GRAPH_COLORS} from "../Constants";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles({
    root: {
        background: '#fff',
        border: '1px solid grey',
        padding: '0 10px',
        borderRadius: '1%',
        wordWrap: 'break-word',
        fontSize: '.75em',
    },
    pointer: {
        cursor: 'pointer',
    }
});

const CustomTooltip = ({ active, payload }) => {
    const classes = useStyles();

    if (active && payload && payload.length) {
        return (
            <div className={classes.root}>
                <p>{payload[0].name}</p>
            </div>
        );
    }

    return null;
};

const MostVisitedPages = ({ data }) => {
    const classes = useStyles();

    const openPage = info => {
        console.log(info);
        window.open(`https://www.lashbrookdesigns.com${info.url}`, 'Preview');
    }
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>URL</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.sort((a,b) => b.pageViews - a.pageViews).map((row, i) => (
                        <TableRow key={row.url}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell><a href={`https://www.lashbrookdesigns.com${row.url}`}>{row.url}</a></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default MostVisitedPages;
