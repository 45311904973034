import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    Loading,
    NumberInput,
    useNotify,
    Notification,
} from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import WithProps from "../../Custom/WithProps";
import { makeStyles } from '@material-ui/core/styles';
import {
    validateNumberGreaterThanZero,
    validateRequired,
} from "../../../utils/Validations";
import { CustomBooleanInput, EditToolBar, PERMISSION_LIST_SIGNET, SignetEngravingRingMaterialsResources, SignetSchoolCerekoteResources, SignetShapeResources, SignetSignetInlayResources, SignetSizeResources } from "../util";
import CustomSchoolSelect from "../CustomSchoolSelect";
import CustomMultiSelect from "../CustomMultiSelect";
import { FormEngraving } from "./FormEngraving";

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});


const SignetEngravingEdit = props => {
    const classes = useStyles();
    const { permissions } = props;
    const notify = useNotify();

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");


    const onFailure = (error) => {
        notify(error.response.data.message ? error.response.data.message : 'Something went wrong', { type: 'warning' });
    };

    return (
        <ThemeProvider >
            <Edit mutationMode="pessimistic" {...props} onFailure={onFailure} className='overflow-visible'>
                <WithProps>
                    {({ record, ...props }) =>
                    (<SimpleForm record={record} {...props} undoable={false} toolbar={<EditToolBar delete={permissionsList.includes(PERMISSION_LIST_SIGNET.delete_signet_data)} />}>
                        <Grid container className={classes.container} justify="space-between">
                            <FormEngraving/>
                            <Grid item xs={5}>
                                <CustomMultiSelect reference={SignetSizeResources} label="Sizes Allowed" source="sizes_allowed" />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomMultiSelect reference={SignetShapeResources} label="Shapes Allowed" source="shapes_allowed" />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomMultiSelect reference={SignetEngravingRingMaterialsResources} label="Materials Allowed" source="ring_materials_allowed" optionValue="id" />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomMultiSelect reference={SignetSignetInlayResources} label="Inlays Allowed" source="inlay_materials_allowed" optionValue="id"/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput
                                    source="name" label="Name" validate={[validateRequired]} fullWidth />
                            </Grid>

                            <Grid item xs={5}>
                                <TextInput
                                    source="sku_code" label="Sku Code" validate={[validateRequired]} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <NumberInput label="Price Markup" source="price_markup"  validate={[validateNumberGreaterThanZero]} />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomBooleanInput source="active" label="Active" />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomBooleanInput source="allow_cerakote" label="Allow Cerakote" />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomBooleanInput source="has_outside_reversed_option" label="Has Outside Reversed Option" />
                            </Grid>
                        </Grid>
                    </SimpleForm>)
                    }
                </WithProps>
            </Edit>
            <Notification />
        </ThemeProvider>
    )
};

export default SignetEngravingEdit;