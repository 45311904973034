import React from "react";
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import {makeStyles} from "@material-ui/core/styles";
import {GRAPH_COLORS} from "../Constants";

const useStyles = makeStyles({
    root: {
        background: '#fff',
        border: '1px solid grey',
        padding: '0 10px',
        borderRadius: '1%'
    },
});

const CustomTooltip = ({ active, payload, label }) => {
    const classes = useStyles();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    if (active && payload && payload.length) {
        return (
            <div className={classes.root}>
                <p>{new Date(label).toLocaleDateString("en-US", options)}</p>
                <p>{`${payload[0].name}: ${payload[0].value}`}</p>
                <p>{`${payload[1].name}: ${payload[1].value}`}</p>
                <p>{`Total: ${payload[0].value + payload[1].value}`}</p>
            </div>
        );
    }

    return null;
};

const GaUsers = ({ trends }) => {
    return (
        <ResponsiveContainer width="100%" height={250}>
            <AreaChart
                width={500} data={trends}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip content={<CustomTooltip/>}/>
                <Area name="Returning Users" type="monotone" dataKey="returning" stackId="1" stroke={GRAPH_COLORS.solid1} fill={GRAPH_COLORS.solid1} />
                <Area name="New Users" type="monotone" dataKey="newUsers" stackId="1" stroke={GRAPH_COLORS.solid2} fill={GRAPH_COLORS.solid2} />
            </AreaChart>
        </ResponsiveContainer>
    )
};

export default GaUsers;