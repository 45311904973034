import React from "react";
import {
    BooleanInput,
    Show,
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect,
} from "react-admin";
import { ArrowBack } from "@material-ui/icons";

import Grid from "@material-ui/core/Grid";

import { Box, Typography, Button as MUIButton, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { validateRequired } from "../../utils/Validations";
import { CustomSelect, InlayTypeShow, EngravingTypeShow } from "./util";
import { CUSTOM_RESOURCES, FIRST_FEATURE } from "./type";
import WithProps from "../Custom/WithProps";
import { get } from 'lodash';

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

export const CustomPricingShow = (props) => {
    const classes = useStyles();
    const redirect = useRedirect();


    return (
        <Show {...props}>
            <WithProps>
                {({ record, ...props }) => {
                    return (<SimpleForm toolbar={<Toolbar {...props}>
                        <MUIButton
                            startIcon={<ArrowBack />}
                            onClick={() => {
                                redirect(`/${props.resource}`);
                            }} >
                            Back
                        </MUIButton>
                    </Toolbar>
                    } >
                        <Grid container className={classes.container} justify="space-between">
                            <Grid item xs={5}>
                                <CustomSelect label="Ring Material" isDisabled source="ring_material" validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/ring-materials"]} />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomSelect label="Width" isDisabled source="width" validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/widths"]} />
                            </Grid>

                            <Grid item xs={5}>
                                <CustomSelect label="Size" isDisabled source="size" validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/sizes"]} />
                            </Grid>

                            <Grid item xs={5}>
                                <CustomSelect label="Weight" isDisabled source="weight" resource={CUSTOM_RESOURCES["custom-pricing/weights"]} />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomSelect label="Sleeve" isDisabled source="sleeve" resource={CUSTOM_RESOURCES["custom-pricing/sleeves"]} />
                            </Grid>

                            <Grid item xs={5}>
                                <CustomSelect label="Profile" isDisabled source="profile" resource={CUSTOM_RESOURCES["custom-pricing/profiles"]} />
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput disabled source="thickness" label="Thickness" fullWidth />
                            </Grid>

                            <Grid item xs={5}>
                                <TextInput disabled source="volume" label="Volume" fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <BooleanInput
                                    label={"Is Cast"}
                                    disabled
                                    source="is_cast" defaultValue={false} />
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    gap: 10,
                                    alignItems: 'center',
                                    paddingY: '1rem',
                                    justifyContent: 'space-between',
                                }}>


                                    <Typography>Outside Feature</Typography>
                                    <Box sx={{ display: 'flex', gap: 10, alignItems: 'center' }}>

                                        <MUIButton
                                            disabled
                                            variant="contained"
                                        >-</MUIButton>
                                        <Typography>{record.outside_feature.length}</Typography>

                                        <MUIButton
                                            disabled
                                            variant="contained"
                                        >+</MUIButton>  </Box>
                                </Box>


                            </Grid>

                            {record.outside_feature.map((v, i) => (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    border: '1px solid #ddd',
                                    borderRadius: 8,
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    padding: '1rem',
                                    flexDirection: 'column',
                                    marginTop: '1rem',
                                }} key={i}>
                                    <InlayTypeShow disabled key={i} val={v} count={i}
                                        type={FIRST_FEATURE}
                                    />
                                </Box>))}

                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    gap: 10,
                                    alignItems: 'center',
                                    paddingY: '1rem',
                                    justifyContent: 'space-between',
                                }}>


                                    <Typography>Additional Price</Typography>
                                    <Box sx={{ display: 'flex', gap: 10, alignItems: 'center' }}>

                                        <MUIButton
                                            variant="contained"
                                            disabled

                                        >-</MUIButton>
                                        <Typography>{record.additional_price.length}</Typography>
                                        <MUIButton
                                            disabled
                                            variant="contained"

                                        >+</MUIButton>  </Box>
                                </Box>


                            </Grid>
                            {record.additional_price.map((val, i) => (
                                <Box
                                    sx={
                                        {
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            border: '1px solid #ddd',
                                            borderRadius: 8,
                                            padding: '1rem',
                                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                            marginY: '0.5rem',
                                        }
                                    }
                                    key={i}>
                                    <Grid item xs={5}>
                                        <TextField disabled label="Label" value={val.label} fullWidth />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField disabled label="Price" fullWidth value={val.price} type="number" />
                                    </Grid>
                                </Box >

                            ))}



                            {record.engraving
                                && <EngravingTypeShow val={record.engraving} disabled type={'engraving-options-type'} />}


                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    '& > *': { m: 1 },
                                    gap: 2,
                                    padding: '4rem 2rem',
                                    border: '2px solid',
                                    marginTop: '1rem',
                                }}>
                                    <Box sx={{ display: 'flex', gap: 10, alignItems: 'center', flexDirection: 'column' }}>

                                        {record.price_breakdown && record.price_breakdown.length > 0 && (
                                            <Box sx={{ width: '100%', display: 'flex', gap: 10, flexDirection: 'column' }}>
                                                <Typography align="left" variant="h3">Price Breakdown </Typography>

                                                <TableContainer component={Paper}>
                                                    <Table size="small" aria-label="Price Breakdown">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell />
                                                                <TableCell>Label</TableCell>
                                                                <TableCell>Value</TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {record.price_breakdown.map((row, i) => (
                                                                <TableRow key={row.label}>
                                                                    <TableCell>{i + 1}</TableCell>
                                                                    <TableCell>{row.label}</TableCell>
                                                                    <TableCell>{row.value}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        )}

                                       {record?.weight_breakdown && record.weight_breakdown.length > 0 && (
                                            <Box sx={{ width: '100%', display: 'flex', gap: 10, flexDirection: 'column' }}>
                                                <Typography align="left" variant="h3">Weight Breakdown </Typography>

                                                <TableContainer component={Paper}>
                                                    <Table size="small" aria-label="weight_breakdown">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell />
                                                                <TableCell>Label</TableCell>
                                                                <TableCell>Value</TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {record.weight_breakdown.map((row, i) => (
                                                                <TableRow key={row.label}>
                                                                    <TableCell>{i + 1}</TableCell>
                                                                    <TableCell>{row.label}</TableCell>
                                                                    <TableCell>{row.value}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        )}
                                        <Box sx={{ width: '100%', display: 'flex', marginTop: 10 }}>
                                            <Typography align="right" variant="h3">Price :   {get(record, 'price', 0)} </Typography>
                                        </Box>

                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </SimpleForm>
                    )
                }
                }
            </WithProps>
        </Show >
    )
}
