/**
 * Initial state for the Signet Studio application
 */
export const initialState = {
  selectedEngraving: null,
  svgFile: null,
  mappingData: null,
  inputValue: '',
  fetchedEngravingId: null,
  engravingsList: [],
  materialList: [],
  shapeConfigs: [],
  textureImages: null,
};

/**
 * Action types for state updates
 */
export const ACTIONS = {
  SET_SELECTED_ENGRAVING: 'SET_SELECTED_ENGRAVING',
  SET_SVG_FILE: 'SET_SVG_FILE',
  SET_MAPPING_DATA: 'SET_MAPPING_DATA',
  SET_INPUT_VALUE: 'SET_INPUT_VALUE',
  SET_FETCHED_ENGRAVING_ID: 'SET_FETCHED_ENGRAVING_ID',
  SET_ENGRAVINGS_LIST: 'SET_ENGRAVINGS_LIST',
  RESET_STATE: 'RESET_STATE',
  UPDATE_SHAPE_CONFIGS: 'UPDATE_SHAPE_CONFIGS',
  SET_MATERIALS_LIST: 'SET_MATERIALS_LIST',
  SET_SHAPE_CONFIGS: 'SET_SHAPE_CONFIGS',
  SET_TEXTURE_IMAGES: 'SET_TEXTURE_IMAGES',
};

export const studioReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_SELECTED_ENGRAVING:
      return {
        ...state,
        selectedEngraving: action.payload,
        svgFile: null,
      };
    case ACTIONS.SET_SVG_FILE:
      return {
        ...state,
        svgFile: action.payload,
      };
    case ACTIONS.SET_MAPPING_DATA: {
      return {
        ...state,
        mappingData: {
          ...(state.mappingData ? state.mappingData : {}),
          ...action.payload,
        },
      };
    }
    case ACTIONS.SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.payload,
      };
    case ACTIONS.SET_FETCHED_ENGRAVING_ID:
      return {
        ...state,
        fetchedEngravingId: action.payload,
      };
    case ACTIONS.SET_ENGRAVINGS_LIST:
      return {
        ...state,
        engravingsList: action.payload,
      };
    case ACTIONS.RESET_STATE:
      return initialState;
    case ACTIONS.UPDATE_SHAPE_CONFIGS:
      return {
        ...state,
        shapeConfigs: action.payload,
      };
    case ACTIONS.SET_MATERIALS_LIST:
      return {
        ...state,
        materialList: action.payload,
      };
    case ACTIONS.SET_SHAPE_CONFIGS:
      return {
        ...state,
        shapeConfigs: action.payload,
      };
    case ACTIONS.SET_TEXTURE_IMAGES:
      return {
        ...state,
        textureImages: action.payload,
      };
    default:
      return state;
  }
};
