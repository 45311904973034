import React, { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import UserList from "./components/Users/users";
import { ThemeProvider } from "@material-ui/core/styles";
import UserIcon from "@material-ui/icons/Group";
import RolesIcon from "@material-ui/icons/AssignmentInd";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import StorefrontIcon from '@material-ui/icons/Storefront';
import dashboard from "./components/dashboard";
import authProvider from "./components/authProvider";
import dataProvider from "./dataProvider";
import customRoutes from "./customRoutes";
import axios from "axios";
import UserCreate from "./components/Users/create-user";
import LogoutButton from "./components/logoutButton";
import LoginPage from "./components/login-page";
import ForgotPassword from "./components/forgotPassword";
import customTheme from "../src/customTheme";
import RoleCreate from "./components/Roles/RoleCreate";
import RoleList from "./components/Roles/roles";
import RoleEdit from "./components/Roles/RoleEdit";
import UserEdit from "./components/Users/edit-user";
import CustomLayout from "./Layout/customLayout";
import NotFound from "./components/notFound";
import CreateProduct from "./components/StaticProducts/create-product";
import ProductList from "./components/StaticProducts/product-list";
import EditProduct from "./components/StaticProducts/edit-product";
import AccountList from "./components/Accounts/AccountsList";
import { AccountEdit } from "./components/Accounts/AccountEdit";
import { AccountCreate } from "./components/Accounts/AccountCreate";
import StoreLocationList from "./components/Store-Location/StoreLocationList";
import { StoreLocationEdit } from "./components/Store-Location/StoreLocationEdit";
import { StoreLocationCreate } from "./components/Store-Location/StoreLocationCreate";
import ShippingList from "./components/Shipping/shipping-list";
import ShippingEdit from "./components/Shipping/edit-shipping";
import OrderList from "./components/Orders/orders";
import OrderShow from "./components/Orders/view-order";
import CategoryList from "./components/Categories/CategoriesList";
import CategoryEdit from "./components/Categories/CategoryEdit";
import CategoryCreate from "./components/Categories/CategoryCreate";
import DynamicProducts from "./components/DynamicProducts/product-list";
import CreateDynamicProduct from "./components/DynamicProducts/create-product";
import EditDynamicProduct from "./components/DynamicProducts/edit-product";
import StoresList from "./components/Stores/StoresList";
import { StoreEdit } from "./components/Stores/StoreEdit";
import { StoreCreate } from "./components/Stores/StoreCreate";
import ProductsList from "./components/Categories/ProductsList";
import BulkUpload from "./components/BulkUpload/bulk-upload"
import ShopifyStoreList from "./components/Shopify-Store/ShopifyStoreList";
import { ShopifyStoreEdit } from "./components/Shopify-Store/ShopifyStoreEdit";
import { ShopifyStoreCreate } from "./components/Shopify-Store/ShopifyStoreCreate";
import MaterialList from "./components/Material-Data/MaterialDataList";
import { MonetizationOn } from '@material-ui/icons';

// signet ui list
// shape
import SignetShapeList from "./components/Signet/Shape/SignetShapeList";
import SignetShapeCreate from "./components/Signet/Shape/SignetShapeCreate";
import SignetShapeEdit from "./components/Signet/Shape/SignetShapeEdit";

// size
import SignetSizeList from "./components/Signet/Size/SignetSizeList";
import SignetSizeCreate from "./components/Signet/Size/SignetSizeCreate";
import SignetSizeEdit from "./components/Signet/Size/SignetSizeEdit";


// shank
import SignetShankList from "./components/Signet/shank/SignetShankList";
import SignetShankCreate from "./components/Signet/shank/SignetShankCreate";
import SignetShankEdit from "./components/Signet/shank/SignetShankEdit";


// cerakote
import SignetCerakoteList from "./components/Signet/Cerokote/SignetCerakoteList";
import SignetCerakoteCreate from "./components/Signet/Cerokote/SignetCerakoteCreate";
import SignetCerakoteEdit from "./components/Signet/Cerokote/SignetCerakoteEdit";

// engraving
import SignetEngravingList from "./components/Signet/Engraving/SignetEngravingList";
import SignetEngravingCreate from "./components/Signet/Engraving/SignetEngravingCreate";
import SignetEngravingEdit from "./components/Signet/Engraving/SignetEngravingEdit";

// school cerakote
import SignetSchoolCerakoteList from "./components/Signet/SchoolCerakote/SignetSchoolCerakoteList";
import SignetSchoolCerakoteCreate from "./components/Signet/SchoolCerakote/SignetSchoolCerakoteCreate";
import SignetSchoolCerakoteEdit from "./components/Signet/SchoolCerakote/SignetSchoolCerakoteEdit";

// school
import SignetSchoolList from "./components/Signet/schools/SignetSchoolList";
import SignetSchoolCreate from "./components/Signet/schools/SignetSchoolCreate";
import SignetSchoolEdit from "./components/Signet/schools/SignetSchoolEdit";

// ring data
import SignetRingList from "./components/Signet/RingData/SignetRingList";
import SignetRingCreate from "./components/Signet/RingData/SignetRingCreate";
import SignetRingEdit from "./components/Signet/RingData/SignetRingEdit";

// signet pricing
import SignetPricingList from "./components/Signet/Pricing/SignetPricingList";
import SignetPricingCreate from "./components/Signet/Pricing/SignetPricingCreate";
import SignetPricingEdit from "./components/Signet/Pricing/SignetPricingEdit";

// signet inlay
import SignetInlayList from "./components/Signet/Inlay/SignetInlayList";
import SignetInlayCreate from "./components/Signet/Inlay/SignetInlayCreate";
import SignetInlayEdit from "./components/Signet/Inlay/SignetInlayEdit";

// custom pricing
import {CustomPricing} from "./components/CustomPricing";
import {CustomPricingShow} from "./components/CustomPricing/show";
import CustomPricingList from "./components/CustomPricing/List";
import { CustomPricingEdit } from "./components/CustomPricing/Edit";

import { MaterialDataCreate } from "./components/Material-Data/MaterialDataCreate";
import { MaterialDataEdit } from "./components/Material-Data/MaterialDataEdit";
import { PERMISSION_LIST_SIGNET, SignetEngravingRingMaterialsResources, SignetFinishesDataResources, SignetInlayFinishes, SignetRingDataResources, SignetRingMaterialResources, SignetSchoolResources, SignetSignetInlayResources, SignetSignetInlayTypes, SignetSignetOptionCategories, SignetSignetPricingDataResources, SignetNonSchoolCerekoteResources } from "./components/Signet/util";

import { CUSTOM_RESOURCES } from "./components/CustomPricing/util";

// if (sessionStorage.getItem("access_token")) {
//   axios.defaults.headers.common = {
//     Authorization: "Bearer " + sessionStorage.getItem("access_token"),
//     Accept: "application/json"
//   };
// }

const App = () => {

  const access_token = sessionStorage.getItem("access_token")

  useEffect(() => {
    if (access_token) {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + access_token,
        Accept: "application/json"
      };
    }
  }, [access_token])


  return (
    <ThemeProvider theme={customTheme}>
      <Admin
        catchAll={NotFound}
        theme={customTheme}
        forgotPassword={ForgotPassword}
        dashboard={dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
        customRoutes={customRoutes}
        loginPage={LoginPage}
        logoutButton={LogoutButton}
        layout={CustomLayout}
      >
        {permissions => {
          const permissionsList = permissions.split(",");
          return [
            <Resource
              name="users"
              list={permissionsList.includes("view_users") ? UserList : null}
              create={permissionsList.includes("add_users") ? UserCreate : null}
              icon={UserIcon}
              edit={UserEdit}
            />,
            <Resource
              name="roles"
              list={permissionsList.includes("view_roles") ? RoleList : null}
              create={permissionsList.includes("add_roles") ? RoleCreate : null}
              edit={RoleEdit}
              icon={RolesIcon}
            />,
            <Resource
              name="stores"
              list={permissionsList.includes("view_stores") ? StoresList : null}
              edit={permissionsList.includes("edit_stores") ? StoreEdit : null}
              create={permissionsList.includes("add_stores") ? StoreCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name="builder-platform"
              list={permissionsList.includes("view_shopify_stores") ? ShopifyStoreList : null}
              edit={permissionsList.includes("edit_shopify_stores") ? ShopifyStoreEdit : null}
              create={permissionsList.includes("add_shopify_stores") ? ShopifyStoreCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name="material-data"
              list={permissionsList.includes("view_material_data") ? MaterialList : null}
              edit={permissionsList.includes("edit_material_data") ? MaterialDataEdit : null}
              create={permissionsList.includes("add_material_data") ? MaterialDataCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name="signet-shape"
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetShapeList : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetShapeEdit : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetShapeCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name="signet-size"
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetSizeList : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetSizeEdit : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetSizeCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name="shank"
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetShankList : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetShankEdit : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetShankCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name="cerakote-color"
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetCerakoteList : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetCerakoteEdit : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetCerakoteCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name="signet-engravings"
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetEngravingList : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetEngravingEdit : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetEngravingCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name="school-cerakote-colors"
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetSchoolCerakoteList : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetSchoolCerakoteEdit : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetSchoolCerakoteCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name={SignetSchoolResources}
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetSchoolList : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetSchoolEdit : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetSchoolCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name={SignetRingDataResources}
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetRingList : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetRingEdit : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetRingCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource
              name={SignetSignetPricingDataResources}
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetPricingList : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetPricingEdit : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetPricingCreate : null}
              icon={StorefrontIcon}
            />,
            <Resource name={SignetSignetInlayResources}
              list={permissionsList.includes(PERMISSION_LIST_SIGNET.view_signet_data) ? SignetInlayList : null}
              create={permissionsList.includes(PERMISSION_LIST_SIGNET.add_signet_data) ? SignetInlayCreate : null}
              edit={permissionsList.includes(PERMISSION_LIST_SIGNET.edit_signet_data) ? SignetInlayEdit : null}
            />,
            <Resource
              name="accounts"
              list={permissionsList.includes("view_accounts") ? AccountList : null}
              edit={permissionsList.includes("edit_accounts") ? AccountEdit : null}
              create={permissionsList.includes("add_accounts") ? AccountCreate : null}
              icon={RecentActorsIcon}
            />,
            <Resource
              name="accounts"
              list={permissionsList.includes("view_accounts") ? AccountList : null}
              edit={permissionsList.includes("edit_accounts") ? AccountEdit : null}
              create={permissionsList.includes("add_accounts") ? AccountCreate : null}
              icon={RecentActorsIcon}
            />,
            <Resource
              name="store-locations"
              list={permissionsList.includes("view_accounts") ? StoreLocationList : null}
              edit={permissionsList.includes("edit_accounts") ? StoreLocationEdit : null}
              create={permissionsList.includes("add_accounts") ? StoreLocationCreate : null}
              icon={RecentActorsIcon}
            />,
            <Resource name="accounts/types" />,
            <Resource name={SignetRingMaterialResources} />,
            <Resource name={SignetFinishesDataResources} />,
            <Resource name={SignetSignetOptionCategories} />,
            <Resource name={SignetSignetInlayTypes} />,
            <Resource name={SignetInlayFinishes} />,
            <Resource name={SignetEngravingRingMaterialsResources} />,
            <Resource name={SignetNonSchoolCerekoteResources} />,
            <Resource name="attribute-types" />,
            <Resource name="accounts/parents" />,
            <Resource name="me" />,
            <Resource name="permissions" />,
            <Resource
              name="direct/orders"
              list={permissionsList.includes("view_orders") ? OrderList : null}
              show={permissionsList.includes("view_orders") ? OrderShow : null}
            />,
            <Resource
              name="store/orders"
              list={permissionsList.includes("view_orders") ? OrderList : null}
              show={permissionsList.includes("view_orders") ? OrderShow : null}
            />,
            <Resource
              name="products"
              list={permissionsList.includes("view_products") ? ProductList : null}
              create={
                permissionsList.includes("add_products") ? CreateProduct : null
              }
              edit={permissionsList.includes("edit_products") ? EditProduct : null}
            />,
            <Resource
              name="dynamic/products"
              options={{ label: "Customizable Products" }}
              list={permissionsList.includes("view_dynamic-products") ? DynamicProducts : null}
              create={permissionsList.includes("add_dynamic-products") ? CreateDynamicProduct : null}
              edit={permissionsList.includes("edit_dynamic-products") ? EditDynamicProduct : null}
            />,
            <Resource
              name="categories"
              list={permissionsList.includes("view_categories") ? CategoryList : null}
              edit={permissionsList.includes("edit_categories") ? CategoryEdit : null}
              create={permissionsList.includes("add_categories") ? CategoryCreate : null}
            />,
            <Resource name="position" list={ProductsList} />,
            <Resource name="products/categories" />,
            <Resource
              name="account/shipping"
              list={permissionsList.includes("view_shipping") ? ShippingList : null}
              edit={permissionsList.includes("edit_shipping") ? ShippingEdit : null}
            />,
            <Resource
              name="shipping"
              list={permissionsList.includes("view_shipping") ? ShippingList : null}
              edit={permissionsList.includes("edit_shipping") ? ShippingEdit : null}
            />,
            <Resource name="export/sample" />,
            <Resource name="import/status" />,
            <Resource name="website/metrics" />,
            <Resource
              name="bulk-upload"
              list={permissionsList.includes("view_users") ? BulkUpload : null}
              create={permissionsList.includes("add_users") ? BulkUpload : null}
              icon={UserIcon}
            />,
            <Resource
              name="nc-rings"
              list={permissionsList.includes("view_users") ? CustomPricingList : null}
              create={permissionsList.includes("view_users") ? CustomPricing : null}
              edit={permissionsList.includes("view_users") ? CustomPricingEdit : null}
              // show={permissionsList.includes("view_users") ? CustomPricingShow : null}
              icon={MonetizationOn}
            />,
          ];
        }}
      </Admin>
    </ThemeProvider>
  )
};

export default App;
