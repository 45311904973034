import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router";
import { fetchEnd, fetchStart, useNotify } from "react-admin";
import { apiUrl } from "../../dataProvider";
import {LIST_COLORS} from "./Constants";

const COLORS = ["#8884d8", "#83a6ed", "#8dd1e1", "#82ca9d", "#a4de6c", "#d0ed57", "#ffc658"];

function GetSiteMetrics() {
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const notify = useNotify();
    const { search } = useLocation();

    useEffect(() => {
        fetchGoogleMetrics(search || '').then(() => setLoading(false));
    }, [search]);

    useEffect(() => {
        getTotalUsers();
        getBounceRate();
        getPagesPerSession();
        getSessionDuration();
        getNewVisits();
        getTotalSessions();
        getCombinedUsers();
        getAvgPageLoad();
        getTopBrowsers();
    }, [state.analytics]);

    const fetchGoogleMetrics = async (dates) => {
        setLoading(true);
        dispatch(fetchStart());

        await axios.get(`${apiUrl}/google/metrics${dates}`)
            .then(({data}) => {
                setState(state => ({ ...state, analytics: data }));
                return {
                    data: {
                        ...data
                    }
                };
            })
            .catch(({response}) => {
                const message = response?.statusText;
                const status = response?.status;
                if (status === 401 || status === 403) {
                    window.location.replace('/#/login');
                }
                notify(message, 'warning');
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd());
            });
    }

    const getTotalUsers = () => {
        let usersCount = 0;
        const data = state?.analytics?.totalUsers && Object.keys(state?.analytics?.totalUsers).map(date => {
            usersCount = usersCount + state.analytics.totalUsers[date];
            return ({
                date,
                users: state.analytics.totalUsers[date]
            })
        });
        setState(state => ({ ...state, totalUsersTrend: data, usersCount }));
    };

    const getNewVisits = () => {
        let usersCount = 0;
        const data = state?.analytics?.newUsers && Object.keys(state?.analytics?.newUsers).map(date => {
            usersCount = usersCount + state.analytics.newUsers[date];
            return ({
                date,
                visits: state.analytics.newUsers[date]
            })
        });
        setState(state => ({ ...state, newVisitsTrend: data, totalNewUsers: usersCount }));
    };

    const getCombinedUsers = () => {
        const data = state?.analytics?.newUsers && state?.analytics?.totalUsers && Object.keys(state?.analytics?.totalUsers).map(date => {
            return ({
                date,
                returning: state.analytics.totalUsers[date] - state.analytics.newUsers[date],
                newUsers: state.analytics.newUsers[date]
            })
        });
        setState(state => ({ ...state, combinedUsersTrend: data }));
    }

    const getBounceRate = () => {
        let totalBounceRate = 0;
        let days =state?.analytics?.bounceRate && Object.keys(state?.analytics?.bounceRate).length;
        const data = state?.analytics?.bounceRate && Object.keys(state?.analytics?.bounceRate).map(date => {
            totalBounceRate = totalBounceRate + state.analytics.bounceRate[date];
            return ({
                date,
                bounceRateTrend: state.analytics.bounceRate[date]
            })
        });
        setState(state => ({ ...state, bounceRateTrend: data, avgBounceRate: Number((totalBounceRate/days).toFixed(2)) }));
    };

    const getPagesPerSession = () => {
        let totalCount = 0;
        let days = state?.analytics?.pagesPerSession && Object.keys(state?.analytics?.pagesPerSession).length;
        const data = state?.analytics?.pagesPerSession && Object.keys(state?.analytics?.pagesPerSession).map(date => {
            totalCount = totalCount + state.analytics.pagesPerSession[date];
            return ({
                date,
                pagesPerSessionTrend: state.analytics.pagesPerSession[date]
            })
        });
        setState(state => ({ ...state, pagesPerSessionTrend: data, avgPagesPerSession: Number((totalCount/days).toFixed(2)) }));
    };

    const getSessionDuration = () => {
        let totalCount = 0;
        let days = state?.analytics?.avgSessionDuration && Object.keys(state?.analytics?.avgSessionDuration).length;
        const data = state?.analytics?.avgSessionDuration && Object.keys(state?.analytics?.avgSessionDuration).map(date => {
            totalCount = totalCount + state.analytics.avgSessionDuration[date];
            const time = state.analytics.avgSessionDuration[date];
            const seconds = (time % 60)/60;
            const minutes = Math.floor(time / 60);
            return ({
                date,
                avgSessionDurationTrend: Number(minutes+seconds).toFixed((2)),
            })
        });
        const consolidatedCount = totalCount/days;
        const seconds = Math.floor((((consolidatedCount % 60)/60) * 100));
        const minutes = Math.floor(consolidatedCount / 60);
        const hours = Math.floor(consolidatedCount / 3600 );
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        setState(state => ({ ...state, avgSessionDurationTrend: data, avgSessionDuration: `${formattedHours}:${formattedMinutes}:${seconds}` }));
    };

    const getTotalSessions = () => {
        const data = state?.analytics?.sessions && Object.keys(state?.analytics?.sessions).map(date => {
            return ({
                date,
                sessions: state.analytics.sessions[date],
            })
        });
        setState(state => ({ ...state, sessionsTrend: data }));
    };

    const getAvgPageLoad = () => {
        const data = state?.analytics?.avgPageLoadTime && Object.keys(state?.analytics?.avgPageLoadTime).map(date => {
            return ({
                date,
                time: state.analytics.avgPageLoadTime[date]
            })
        });
        setState(state => ({ ...state, avgPageLoadTime: data }));
    };

    const getTopBrowsers = () => {
        const data = state?.analytics?.topBrowsers.map((key, index) => {
            return ({
                value: key.sessions,
                name: key.browser,
                fill: LIST_COLORS[index] || LIST_COLORS[COLORS.length - 1],
            })
        });
        setState(state => ({ ...state, topBrowsers: data }));
    };

    return ({
        totalUsersTrend: state.totalUsersTrend,
        newUsers: state.newVisitsTrend,
        totalNewUsers: state.totalNewUsers,
        combinedUsers: state.combinedUsersTrend,
        usersCount: state.usersCount,
        bounceRate: state.avgBounceRate,
        bounceRateTrend: state.bounceRateTrend,
        pagesPerSessionTrend: state.pagesPerSessionTrend,
        avgPagesPerSession: state.avgPagesPerSession,
        avgSessionDurationTrend: state.avgSessionDurationTrend,
        avgSessionDuration: state.avgSessionDuration,
        totalSessions: state.sessionsTrend,
        avgPageLoadTime: state.avgPageLoadTime,
        topReferrers: state.analytics?.topReferrers,
        mostVisitedPages: state.analytics?.mostVisitedPages,
        topBrowsers: state.topBrowsers,
        loading
    });
}

export default GetSiteMetrics;