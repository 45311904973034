import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, usePermissions, getResources } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import DefaultIcon from '@material-ui/icons/ViewList';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import LocalMallIcon from "@material-ui/icons/LocalMall";
import CategoryIcon from '@material-ui/icons/Category';
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import CatalogIcon from '@material-ui/icons/ImportContacts';
import CommuteIcon from '@material-ui/icons/Commute';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import WebIcon from '@material-ui/icons/Web';
import AppsIcon from '@material-ui/icons/Apps';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';
import SubMenu from "./SubMenu";

import { FormatSize, FormatShapes, ColorLens,WidgetsSharp, AllInclusive,Layers, School, WifiTethering,MonetizationOn } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '1.5em',
    },
    properCase: {
        textTransform: 'capitalize',
    }
}));

// add the menu items to the list
const MenuList = ["users", "roles", "stores", "builder-platform", "material-data","nc-rings", "accounts", "store-locations", "bulk-upload"]

const Menu = ({ onMenuClick, dense, logout }) => {
    const [state, setSubmenuMenuState] = useState({
        catalog: false,
        shipping: false,
        orders: false,
        signet: false,
    });
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const { permissions } = usePermissions();
    if (!permissions) return null;
    const permissionsList = permissions.split(",");

    const renderMenuItem = resource => {
        if (MenuList.includes(resource.name)) {
            return <MenuItemLink
                className={classes.properCase}
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={
                    (resource.options && resource.options.label) ||
                    resource.name
                }
                leftIcon={
                    resource.icon ? <resource.icon /> : <DefaultIcon />
                }
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
        }
    };

    const renderCatalogMenu = () => (
        <SubMenu
            handleToggle={() => handleToggle('catalog')}
            isOpen={state.catalog}
            sidebarIsOpen={open}
            name="Catalog"
            dense={dense}
            icon={<CatalogIcon />}
        >
            {permissionsList.includes('view_categories') &&
                <MenuItemLink
                    className={classes.properCase}
                    to="/categories"
                    primaryText="Categories"
                    leftIcon={<CategoryIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />}
            {permissionsList.includes('view_products') &&
                <MenuItemLink
                    className={classes.properCase}
                    to="/products"
                    primaryText="Static Products"
                    leftIcon={<LocalMallIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />}
            {permissionsList.includes('view_dynamic-products') &&
                <MenuItemLink
                    className={classes.properCase}
                    to="/dynamic/products"
                    primaryText="Dynamic Products"
                    leftIcon={<LocalMallIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />}
            {permissionsList.includes('view_import_export') &&
                <MenuItemLink
                    className={classes.properCase}
                    to="/bulk-products"
                    primaryText="Import Export"
                    leftIcon={<ImportExportIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />}
            {permissionsList.includes('view_import_export') &&
                <MenuItemLink
                    className={classes.properCase}
                    to="/export-product-price"
                    primaryText="Import Export Price Data"
                    leftIcon={<ImportExportIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />}
        </SubMenu>
    );


    const renderSignetMenu = () => (
        <SubMenu
            handleToggle={() => handleToggle('signet')}
            isOpen={state.signet}
            sidebarIsOpen={open}
            name="Signet"
            dense={dense}
            icon={<CatalogIcon />}
        >

            {permissionsList.includes('view_signet_data') &&
                <>
                    <MenuItemLink
                        className={classes.properCase}
                        to="/signet-shape"
                        primaryText="Shape"
                        leftIcon={<FormatShapes />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        className={classes.properCase}
                        to="/signet-size"
                        primaryText="Size"
                        leftIcon={<FormatSize />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        className={classes.properCase}
                        to="/shank"
                        primaryText="Shank"
                        leftIcon={<WidgetsSharp />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        className={classes.properCase}
                        to="/cerakote-color"
                        primaryText="Cerakote Color"
                        leftIcon={<ColorLens />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    <MenuItemLink
                        className={classes.properCase}
                        to="/schools"
                        primaryText="School"
                        leftIcon={<School />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        className={classes.properCase}
                        to="/school-cerakote-colors"
                        primaryText="School Cerakote Color"
                        leftIcon={<ColorLens />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                     <MenuItemLink
                        className={classes.properCase}
                        to="/ring-data"
                        primaryText="Signet Rings"
                        leftIcon={<WifiTethering />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        className={classes.properCase}
                        to="/signet-engravings"
                        primaryText="Signet Engraving"
                        leftIcon={<AllInclusive />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        className={classes.properCase}
                        to="/signet-pricing"
                        primaryText="Signet Pricing"
                        leftIcon={<MonetizationOn />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                     <MenuItemLink
                        className={classes.properCase}
                        to="/signet-inlay"
                        primaryText="Signet Inlay"
                        leftIcon={<Layers />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                   <MenuItemLink
                        className={classes.properCase}
                        to="/signet-studio"
                        primaryText="Signet Studio"
                        leftIcon={<PhotoLibrary />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </>
            }
        </SubMenu>
    )

    const renderShippingMenu = () => (
        <SubMenu
            handleToggle={() => handleToggle('shipping')}
            isOpen={state.shipping}
            sidebarIsOpen={open}
            name="Shipping"
            dense={dense}
            icon={<CommuteIcon />}
        >
            <MenuItemLink
                className={classes.properCase}
                to="/account/shipping"
                primaryText="Store Shipping"
                leftIcon={<LocalShippingIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                className={classes.properCase}
                to="/shipping"
                primaryText="Guest Shipping"
                leftIcon={<AirportShuttleIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        </SubMenu>
    );

    const renderOrdersMenu = () => (
        <SubMenu
            handleToggle={() => handleToggle('orders')}
            isOpen={state.orders}
            sidebarIsOpen={open}
            name="Orders"
            dense={dense}
            icon={<ShoppingBasketIcon />}
        >
            <MenuItemLink
                className={classes.properCase}
                to="/direct/orders"
                primaryText="Direct Orders"
                leftIcon={<ShoppingCartIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                className={classes.properCase}
                to="/store/orders"
                primaryText="Store Orders"
                leftIcon={<ShoppingCartIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        </SubMenu>
    );

    const renderDashBoardMenu = () => (
        <SubMenu
            handleToggle={() => handleToggle('dashboard')}
            isOpen={state.dashboard}
            sidebarIsOpen={open}
            name="Dashboard"
            dense={dense}
            icon={<InsertChartIcon />}
        >
            <MenuItemLink
                className={classes.properCase}
                to="/dashboard/site"
                primaryText="Website"
                leftIcon={<WebIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                className={classes.properCase}
                to="/dashboard/application"
                primaryText="Application"
                leftIcon={<AppsIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        </SubMenu>
    );

    const handleToggle = menu => {
        setSubmenuMenuState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div className={classes.root}>
            <MenuItemLink
                exact
                to="/"
                primaryText="Home"
                leftIcon={<DashboardIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={false}
            />
            {renderDashBoardMenu()}
            {resources.filter(resource => resource.hasList).map(resource => renderMenuItem(resource))}
            {(permissionsList.includes('view_categories') || permissionsList.includes('view_products') || permissionsList.includes('view_dynamic-products')) && renderCatalogMenu()}
            {(permissionsList.includes('view_categories') || permissionsList.includes('view_products') || permissionsList.includes('view_dynamic-products')) && renderSignetMenu()}
            {permissionsList.includes('view_orders') && renderOrdersMenu()}
            {permissionsList.includes('view_shipping') && renderShippingMenu()}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;