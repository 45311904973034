import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    minValue,
    maxValue
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import {
    validateProductDescription,
    validateRequired
} from "../../utils/Validations";
import { makeStyles } from "@material-ui/core/styles";
import './index.css';
import CustomStoreSelect from "./CustomStoreSelect";
import { CreateMaterialToolbar } from "./util";

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

export const MaterialDataCreate = props => {
    const classes = useStyles();

    return (
        <Create {...props} className="overflow-visible" >
            <SimpleForm toolbar={<CreateMaterialToolbar />} >
                <Grid container className={classes.container} justify="space-between">
                    <Grid item xs={5}>
                        <p className="label">Material Name</p>
                        <CustomStoreSelect isMulti={true} />
                    </Grid>
                    
                    <Grid item xs={5}>
                        <div className="sku-code">
                            <TextInput
                                disabled source="sku_code" label="Sku Code" validate={[validateRequired]} fullWidth />
                        </div>
                    </Grid>

                    <Grid item xs={5}>
                        <TextInput placeholder="/pages/titanium" source="link" label="Link" fullWidth />
                    </Grid>
                    <Grid item xs={5}>

                        <TextInput source="image_url" label="Image url" validate={[validateRequired]} fullWidth />
                    </Grid>
                    <Grid item xs={5}>

                        <TextInput source="emblem_image_url" label="Emblem Image url" validate={[validateRequired]} fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="scratch_resistant" type="number" label="Scratch Resistant" validate={[validateRequired, minValue(0), maxValue(5)]} fullWidth />
                    </Grid>

                    <Grid item xs={5}>
                        <TextInput source="weight" label="Weight" type="number" validate={[validateRequired, minValue(0), maxValue(5)]} fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput multiline source="short_description" label="Description" validate={[validateRequired, validateProductDescription]} fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};