import React from "react";
import { Show, SimpleShowLayout, ReferenceField, TextField } from "react-admin";

const AccountPanel = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="store.store_name" label="Store Name"/>
                <ReferenceField
                    source="account_type_id"
                    reference="accounts/types"
                    sortable={false}
                    link={false}
                >
                    <TextField source="name" />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    );
};

export default AccountPanel;
