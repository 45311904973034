import React from "react";
import { Datagrid, DeleteButton, EditButton, List, Loading, TextField, TextInput, useMutation, useRedirect } from "react-admin";

import { PERMISSION_LIST_SIGNET, SignetSchoolCerekoteResources } from "../util";
import ListActions from "../ListActions";


const CustomEdit = ({ record: { id } }) => {
    const redirect = useRedirect();
    const [getDetails] = useMutation({
        type: "getOne",
        resource: SignetSchoolCerekoteResources,
        payload: { id: id }
    });

    const handleEdit = async e => {
        e.preventDefault();
        getDetails();
        redirect(`/${SignetSchoolCerekoteResources}/${id}`);
    };

    return <EditButton onClick={handleEdit} />;
};

const SignetSchoolCerakoteList = props => {
    const { permissions } = props;

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");
    return (
        <List
            {...props}
            exporter={false}
            actions={<ListActions filters={
                [<TextInput source="name" label="Name" defaultValue="" />,
                <TextInput source="school" label="School" defaultValue="" />,
                <TextInput source="cerakote_color" label="Cerakote Color" defaultValue="" />]
            } />}
        >
            <Datagrid rowClick={null}>
                <TextField source="name" label="Name" />
                <TextField source="school" sortable={false} label="School" />
                <TextField source="cerakote_color" sortable={false} label="Cerakote Color" />
                {permissionsList.includes("delete_signet_data") && <CustomEdit />}
                {permissionsList.includes(PERMISSION_LIST_SIGNET.delete_signet_data) && (
                    <DeleteButton label="" undoable={false} />
                )}
            </Datagrid>
        </List>
    );
};

export default SignetSchoolCerakoteList;