import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";

const CategoryPanel = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="description" />
                <TextField source="tagline" />
            </SimpleShowLayout>
        </Show>
    );
};

export default CategoryPanel;
