import React, { useCallback } from "react";
import { Edit, SimpleForm, TextInput, SaveButton, Toolbar, ReferenceArrayInput, SelectArrayInput, ChipField, useUpdate, useRedirect, useNotify } from "react-admin";
import { useForm } from 'react-final-form';

const CustomSave = ({ handleSubmitWithRedirect, ...props }) => {
    const [update] = useUpdate('users', props.record.id);
    const redirectTo = useRedirect();
    const notify = useNotify();
    const form = useForm();
    const { basePath } = props;

    const handleClick = useCallback(() => {
        const {created_at, id, ...rest} = form.getState().values;

        update(
            {
                payload: { data: { ...rest } },
            },
            {
                onSuccess: ({ data }) => {
                    notify(data.message, 2000);
                    redirectTo(basePath);
                },
            }
        );

    }, [form]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const EditToolbar = props => (
    <Toolbar {...props}>
        <CustomSave
            redirect="list"
            submitOnEnter={false}
            variant="text"
        />
    </Toolbar>
);

const UserEdit = props => {
    return <Edit {...props}>
            <SimpleForm toolbar={<EditToolbar/>}>
                <TextInput source="name" />
                <TextInput source="email" />
                <ReferenceArrayInput label="Roles" reference="roles" source="roles">
                    <SelectArrayInput>
                        <ChipField source="name" />
                    </SelectArrayInput>
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
};

export default UserEdit;