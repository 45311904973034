import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    Loading,
    Toolbar, 
    SaveButton, 
    DeleteButton,
    BooleanInput,
    useInput,
    FormDataConsumer,
} from "react-admin";
import Grid from '@material-ui/core/Grid';
import WithProps from "../Custom/WithProps";
import { makeStyles } from '@material-ui/core/styles';
import {
  validateDomain,
  validateRequired,
} from "../../utils/Validations";
import './index.css';
import CustomStoreSelect from "./CustomStoreSelect";
import Select from "react-select";

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});

const StoreEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine} />
        {props.delete && <DeleteButton undoable={false}/>}
    </Toolbar>
);

export const PlaformSelect = props => {
    const { input } = useInput({ source: 'platform_name' })
    const options = [
        { value: 'shopify', label: 'Shopify' },
        { value: 'php', label: 'PHP' },
        { value: 'magento', label: 'Magneto' },
        { value: 'iframe', label: 'Iframe' },
        { value: 'wordpress', label: 'Wordpress' },
    ];

    const handleChange = callback => selectedopt => {
        callback(selectedopt?.value);
    }

    return (
        <Select 
            {...input}
            options={options}
            onChange={handleChange(input.onChange)}
            value={options.find(item => item.value === input.value)}
            styles={{ menu: provided => ({ ...provided, zIndex: 9999 })}}
        />
    )
}

export const ShopifyStoreEdit = props => {
    const classes = useStyles();
    const {permissions} = props;

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    return (
        <Edit {...props} className='overflow-visible'>
            <WithProps>
                {({record, ...props}) =>
                  (<SimpleForm record={record} {...props} undoable={false} toolbar={<StoreEditToolbar delete={permissionsList.includes("delete_shopify_stores")}/>}>
                    <Grid container className={classes.container} justify="space-between">
                        <Grid item xs={5}>
                            <p className="label">Platform Name</p>
                            <PlaformSelect/>
                        </Grid>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => {
                                console.log('formData', formData)
                                if(formData.platform_name === 'magento') {
                                    return (
                                        <>
                                            <Grid item xs={5}>
                                                <TextInput type="number" source="attribute_set_id" label="Attribute Id" validate={validateRequired} fullWidth/>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextInput type="number" source="category_id" label="Category Id" fullWidth/>
                                            </Grid>
                                        </>
                                    )
                                }
                            }}
                        </FormDataConsumer>
                        <Grid item xs={5}>
                            <TextInput source="store_name" validate={validateRequired} fullWidth/>
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput source="keys.api_key" label="Api key" validate={validateRequired} fullWidth/>
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput source="keys.password" label="Password" validate={validateRequired} fullWidth/>
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput placeholder="weareemblem.myshopify.com/" source="base_url" label="Store url" validate={[validateRequired, validateDomain]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput placeholder="customize" source="subpath" label="Subpath" validate={validateRequired} fullWidth/>
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput placeholder="USD / CAD / GBP" source="currency" label="Currency" validate={validateRequired} fullWidth/>
                        </Grid>
                        <Grid item xs={5}>
                            <BooleanInput source="is_approved" label="Subscribed"/>
                            <BooleanInput source="show_women_builder"/>
                            <BooleanInput source="show_signet_builder"/>
                            <BooleanInput source="show_collegiate_builder"/>
                        </Grid>
                        <Grid item xs={5}>
                            <p className="label">Store</p>
                            <CustomStoreSelect />
                        </Grid>
                    </Grid>
                  </SimpleForm>)
                }
            </WithProps>
        </Edit>
)};