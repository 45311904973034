import React, {useState} from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    BooleanInput,
    AutocompleteInput, ArrayInput, SimpleFormIterator, NumberInput, SelectInput
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import CreateToolbar from "../Custom/create-save-button";
import {
    validateRequired as validation, validateNumericRequired
} from "../../utils/Validations";
import {makeStyles} from "@material-ui/core/styles";
import { arrayInputStyles } from "./StoreEdit";

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

export const StoreCreate = props => {
    const classes = useStyles();
    const [isParent, toggleParent] = useState(false);
    const arrayClasses = arrayInputStyles();
    const handleToggle = isParent => toggleParent(!isParent);

    return (
        <Create {...props}>
            <SimpleForm toolbar={<CreateToolbar/>} initialValues={{
                retail_price_model: [
                    {start: 0, end: 1000, multiplier: 3},
                ],
                precious_retail_price_model: [
                    {start: 0, end: 1000, multiplier: 3},
                ],
                signet_retail_price_model: [
                    {start: 0, end: 1000, multiplier: 2.5},
                ],
                signet_precious_retail_price_model: [
                    {start: 0, end: 1000, multiplier: 2.5},
                ],
            }}>
                <Grid container className={classes.container} justify="space-between">
                    <Grid item xs={5}>
                        <TextInput source="store_name" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="store_number" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="netsuite_id" validate={validateNumericRequired} fullWidth label="Netsuite ID"/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source="retail_multiplier" validate={validation} fullWidth/>
                    </Grid>
                    <Grid item container xs={5} alignItems="center">
                        {/*double grid to limit toggle area*/}
                        <Grid item>
                            <BooleanInput source="is_parent_store" defaultValue={!isParent} onChange={handleToggle} />
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <ReferenceInput
                            source="parent_store_id"
                            reference="stores"
                            filterToQuery={searchText => ({store_name: searchText, is_parent_store: true})}
                            fullWidth
                            format={v => !isParent ? "" : v}
                            disabled={!isParent}
                        >
                            <AutocompleteInput optionText="store_name" optionValue="id" />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={5}>
                        <SelectInput
                            source="currency"
                            choices={[
                                { id: 'USD', name: 'USD' },
                                { id: 'CAD', name: 'CAD' },
                            ]}
                            validate={validation}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <ArrayInput source="retail_price_model">
                            <SimpleFormIterator inline classes={{
                                root: arrayClasses.root,
                                form: arrayClasses.form,
                                indexContainer: arrayClasses.indexContainer,
                                action: arrayClasses.action
                            }}>
                                <NumberInput source="start" label="Start" validate={validation} fullWidth/>
                                <NumberInput source="end" label="End" validate={validation} fullWidth/>
                                <NumberInput source="multiplier" label="Multiplier" validate={validation} fullWidth/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid item xs={9}>
                        <ArrayInput source="precious_retail_price_model">
                            <SimpleFormIterator inline classes={{
                                root: arrayClasses.root,
                                form: arrayClasses.form,
                                indexContainer: arrayClasses.indexContainer,
                                action: arrayClasses.action
                            }}>
                                <NumberInput source="start" label="Start" validate={validation} fullWidth/>
                                <NumberInput source="end" label="End" validate={validation} fullWidth/>
                                <NumberInput source="multiplier" label="Multiplier" validate={validation} fullWidth/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid item xs={9}>
                        <ArrayInput source="signet_retail_price_model">
                            <SimpleFormIterator inline classes={{
                                root: arrayClasses.root,
                                form: arrayClasses.form,
                                indexContainer: arrayClasses.indexContainer,
                                action: arrayClasses.action
                            }}>
                                <NumberInput source="start" label="Start" validate={validation} fullWidth/>
                                <NumberInput source="end" label="End" validate={validation} fullWidth/>
                                <NumberInput source="multiplier" label="Multiplier" validate={validation} fullWidth/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid item xs={9}>
                        <ArrayInput source="signet_precious_retail_price_model">
                            <SimpleFormIterator inline classes={{
                                root: arrayClasses.root,
                                form: arrayClasses.form,
                                indexContainer: arrayClasses.indexContainer,
                                action: arrayClasses.action
                            }}>
                                <NumberInput source="start" label="Start" validate={validation} fullWidth/>
                                <NumberInput source="end" label="End" validate={validation} fullWidth/>
                                <NumberInput source="multiplier" label="Multiplier" validate={validation} fullWidth/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
)};