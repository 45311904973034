import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSignetStudio } from './SignetStudioContext';
import { ACTIONS } from './reducers/studioReducer';
import ShapeAccordion from './components/ShapeAccordion';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const ApprovalMapping = () => {
  const classes = useStyles();
  const { state, dispatch } = useSignetStudio();
  const [expanded, setExpanded] = React.useState(null);

  const handleAccordionChange = (shapeId) => (event, isExpanded) => {
    setExpanded(isExpanded ? shapeId : null);
  };

  const handleShapeToggle = (shapeId, checked) => {
    const updatedData = {
      ...state.mappingData,
      shapes: state.mappingData.shapes.map((shape) => {
        if (shape.id === shapeId) {
          return {
            ...shape,
            checked,
            children: shape.children.map((size) => ({
              ...size,
              checked: checked ? size.checked : false,
            })),
          };
        }
        return shape;
      }),
    };
    dispatch({ type: ACTIONS.SET_MAPPING_DATA, payload: updatedData });

    if (!checked && expanded === shapeId) {
      setExpanded(null);
    }
  };

  const handleSizeToggle = (shapeId, sizeId, checked) => {
    const updatedData = {
      ...state.mappingData,
      shapes: state.mappingData.shapes.map((shape) => {
        if (shape.id === shapeId) {
          return {
            ...shape,
            children: shape.children.map((size) => {
              if (size.id === sizeId) {
                return { ...size, checked };
              }
              return size;
            }),
          };
        }
        return shape;
      }),
    };
    dispatch({ type: ACTIONS.SET_MAPPING_DATA, payload: updatedData });
  };

  const handleShapeScaleChange = (shapeId, difference, event) => {
    event?.stopPropagation();

    const currentShape = state.mappingData.shapes.find((s) => s.id === shapeId);

    const updatedConfigs = state.shapeConfigs.map((config) => {
      if (config.shape_sku === currentShape?.sku) {
        const updatedSizes = config.sizes.map((size) => {
          const isEnabled = currentShape.children.find((s) => s?.sku === size.size_sku)?.checked;

          if (isEnabled) {
            return {
              ...size,
              shanks: size.shanks.map((shank) =>
                shank.shank_sku === 'T'
                  ? {
                      ...shank,
                      scale: Math.max(-50, Math.min(50, shank.scale + difference)),
                    }
                  : shank,
              ),
            };
          }
          return size;
        });

        return {
          ...config,
          sizes: updatedSizes,
        };
      }
      return config;
    });

    dispatch({ type: ACTIONS.UPDATE_SHAPE_CONFIGS, payload: updatedConfigs });
  };

  const handleSizeScaleChange = (shapeId, sizeId, newValue) => {
    const updatedConfigs = state.shapeConfigs.map((config) => {
      const currentShape = state.mappingData.shapes.find((s) => s.id === shapeId);
      if (config.shape_sku === currentShape?.sku) {
        const currentSize = currentShape?.children?.find((s) => s.id === sizeId);
        const updatedSizes = config.sizes.map((size) => {
          if (size.size_sku === currentSize?.sku) {
            return {
              ...size,
              shanks: size.shanks.map((shank) => (shank.shank_sku === 'T' ? { ...shank, scale: newValue } : shank)),
            };
          }
          return size;
        });

        return {
          ...config,
          sizes: updatedSizes,
        };
      }
      return config;
    });

    dispatch({ type: ACTIONS.UPDATE_SHAPE_CONFIGS, payload: updatedConfigs });
  };

  const handleShapePaddingChange = (shapeId, difference, event) => {
    event?.stopPropagation();

    const currentShape = state.mappingData.shapes.find((s) => s.id === shapeId);

    const updatedConfigs = state.shapeConfigs.map((config) => {
      if (config.shape_sku === currentShape?.sku) {
        const updatedSizes = config.sizes.map((size) => {
          const isEnabled = currentShape.children.find((s) => s?.sku === size.size_sku)?.checked;

          if (isEnabled) {
            return {
              ...size,
              shanks: size.shanks.map((shank) =>
                shank.shank_sku === 'T'
                  ? {
                      ...shank,
                      padding: Math.max(0, Math.min(50, shank.padding + difference)),
                    }
                  : shank,
              ),
            };
          }
          return size;
        });

        return {
          ...config,
          sizes: updatedSizes,
        };
      }
      return config;
    });

    dispatch({ type: ACTIONS.UPDATE_SHAPE_CONFIGS, payload: updatedConfigs });
  };

  const handleSizePaddingChange = (shapeId, sizeId, newValue) => {
    const updatedConfigs = state.shapeConfigs.map((config) => {
      const currentShape = state.mappingData.shapes.find((s) => s.id === shapeId);
      if (config.shape_sku === currentShape?.sku) {
        const currentSize = currentShape?.children?.find((s) => s.id === sizeId);
        const updatedSizes = config.sizes.map((size) => {
          if (size.size_sku === currentSize?.sku) {
            return {
              ...size,
              shanks: size.shanks.map((shank) =>
                shank.shank_sku === 'T'
                  ? {
                      ...shank,
                      padding: Math.max(0, Math.min(50, newValue)),
                    }
                  : shank,
              ),
            };
          }
          return size;
        });

        return {
          ...config,
          sizes: updatedSizes,
        };
      }
      return config;
    });

    dispatch({ type: ACTIONS.UPDATE_SHAPE_CONFIGS, payload: updatedConfigs });
  };

  const handleSizeShadowChange = (shapeId, sizeId, newValue) => {
    const updatedConfigs = state.shapeConfigs.map((config) => {
      const currentShape = state.mappingData.shapes.find((s) => s.id === shapeId);
      if (config.shape_sku === currentShape?.sku) {
        const currentSize = currentShape?.children?.find((s) => s.id === sizeId);
        const updatedSizes = config.sizes.map((size) => {
          if (size.size_sku === currentSize?.sku) {
            return {
              ...size,
              shanks: size.shanks.map((shank) =>
                shank.shank_sku === 'T'
                  ? {
                      ...shank,
                      shadow: newValue,
                    }
                  : shank,
              ),
            };
          }
          return size;
        });

        return {
          ...config,
          sizes: updatedSizes,
        };
      }
      return config;
    });

    dispatch({ type: ACTIONS.UPDATE_SHAPE_CONFIGS, payload: updatedConfigs });
  };

  const handleRotationChange = (shapeId, sizeId, newValue) => {
    const updatedConfigs = state.shapeConfigs.map((config) => {
      const currentShape = state.mappingData.shapes.find((s) => s.id === shapeId);
      if (config.shape_sku === currentShape?.sku) {
        const currentSize = currentShape?.children?.find((s) => s.id === sizeId);
        const updatedSizes = config.sizes.map((size) => {
          if (size.size_sku === currentSize?.sku) {
            return {
              ...size,
              shanks: size.shanks.map((shank) =>
                shank.shank_sku === 'T'
                  ? {
                      ...shank,
                      shape_rotation: newValue,
                    }
                  : shank,
              ),
            };
          }
          return size;
        });

        return {
          ...config,
          sizes: updatedSizes,
        };
      }
      return config;
    });

    dispatch({ type: ACTIONS.UPDATE_SHAPE_CONFIGS, payload: updatedConfigs });
  };

  return (
    <div className={classes.root}>
      {state.mappingData?.shapes?.map((shape) => {
        const shapeConfig = state.shapeConfigs.find((config) => config.shape_sku === shape?.sku);

        return (
          <ShapeAccordion
            key={shape.id}
            shape={shape}
            shapeConfig={shapeConfig}
            expanded={expanded}
            onAccordionChange={handleAccordionChange}
            onShapeToggle={handleShapeToggle}
            onSizeToggle={handleSizeToggle}
            onShapeScaleChange={handleShapeScaleChange}
            onSizeScaleChange={handleSizeScaleChange}
            onShapePaddingChange={handleShapePaddingChange}
            onSizePaddingChange={handleSizePaddingChange}
            onSizeShadowChange={handleSizeShadowChange}
            onRotationChange={handleRotationChange}
          />
        );
      })}
    </div>
  );
};

export default ApprovalMapping;
