import React from "react";
import { Route, Switch } from "react-router-dom";
import { SignetStudioProvider } from './SignetStudioContext';
import StudioMain from './StudioMain';
import EngravingMappingView from './EngravingMappingView';

const SignetStudio = ({ match }) => {
  return (
    <SignetStudioProvider>
      <Switch>
        <Route exact path={match.path} component={StudioMain} />
        <Route 
          path={`${match.path}/engraving-review`} 
          component={EngravingMappingView} 
        />
      </Switch>
    </SignetStudioProvider>
  );
};

export default SignetStudio;
