import axios from "axios";
import URLs from "../utils/urls";
import baseURL from "../settings";
import SessionStorage from "../utils/SessionStorage";

let token = SessionStorage.get("access_token") || "";
let permissions = SessionStorage.get("permissions") || "Guest";
let loggedIn = false;

export default {
  login: async ({ email, password }) => {
    await axios
      .post(`${baseURL.apiBaseUrl}/${URLs.login}`, {
        email,
        password
      })
      .then(response => {
        const access_token = response.data.access_token;
        SessionStorage.add("access_token", access_token);
        token = SessionStorage.get("access_token");
        loggedIn = true;
        axios.defaults.headers.common = {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        };
      });
    return Promise.resolve();
  },

  logout: async () => {
    if (token) {
      await axios
        .post(`${baseURL.apiBaseUrl}/${URLs.logout}`, token)
        .then(response => {
          SessionStorage.remove("permissions");
          SessionStorage.remove("access_token");
          loggedIn = false;
        });
    }
    return Promise.resolve();
  },

  checkError: ({ response }) => {
    const status = response?.status;
    if (status === 401 || status === 403) {
      window.location.replace('/#/login');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: async () => {
    return token ? Promise.resolve() : Promise.reject({ redirectTo: "/login" });
  },

  getPermissions: async () => {
    permissions = SessionStorage.get("permissions") || "Guest";

    if ((loggedIn && permissions === 'Guest')) {
      await axios
        .get(`${baseURL.apiBaseUrl}/${URLs.currentUser}`)
        .then(({ data: { data: {roles} } }) => {
          const userPermissions = roles
            .map(role => role.permissions)
            .reduce(
              (permissions, permission) => [...permissions, ...permission],
              []
            )
            .map(permission => permission.name);
          const userPermissionsFiltered = [...new Set(userPermissions)];

          SessionStorage.add("permissions", userPermissionsFiltered);
          permissions = SessionStorage.get("permissions");
        });
    }

    return permissions ? Promise.resolve(permissions) : Promise.reject();
  }
};
