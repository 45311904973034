import React from "react";
import {
    Edit,
    SimpleForm,
    Loading,
    NumberInput,
    useNotify,
    Notification,
} from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import WithProps from "../../Custom/WithProps";
import { makeStyles } from '@material-ui/core/styles';
import {
    validatePositiveNumber,
    validateNumberGreaterThanZero,
    validateRequired,
} from "../../../utils/Validations";
import { CustomBooleanInput, EditToolBar, PERMISSION_LIST_SIGNET, SignetFinishesDataResources, SignetShankResources, SignetShapeResources, SignetSizeResources } from "../util";
import CustomSchoolSelect from "../CustomSchoolSelect";

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});


const SignetEngravingEdit = props => {
    const classes = useStyles();
    const { permissions } = props;
    const notify = useNotify();

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");


    const onFailure = (error) => {
        notify(error.response.data.message ? error.response.data.message : 'Something went wrong', { type: 'warning' });
    };

    return (
        <ThemeProvider >
            <Edit mutationMode="pessimistic" {...props} onFailure={onFailure} className='overflow-visible'>
                <WithProps>
                    {({ record, ...props }) =>
                    (<SimpleForm record={record} {...props} undoable={false} toolbar={<EditToolBar delete={permissionsList.includes(PERMISSION_LIST_SIGNET.delete_signet_data)} />}>
                        <Grid container className={classes.container} justify="space-between">

                            <Grid item xs={5}>
                                <p className="label">Shank</p>
                                <CustomSchoolSelect isDisabled={true} idsource='shank_id' isEdit={true} source="shank" resource={SignetShankResources} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <p className="label">First Finish Group</p>
                                <CustomSchoolSelect idsource='first_finish_group_id' isEdit={true} source="first_finish_group" resource={SignetFinishesDataResources} validate={[validateRequired]} fullWidth />
                            </Grid>


                            <Grid item xs={5}>
                                <p className="label">Size</p>
                                <CustomSchoolSelect isDisabled={true} idsource='size_id' source="size" isEdit={true} resource={SignetSizeResources} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <p className="label">Second Finish Group</p>
                                <CustomSchoolSelect idsource='second_finish_group_id' isEdit={true} source="second_finish_group" resource={SignetFinishesDataResources} validate={[validateRequired]} fullWidth />
                            </Grid>

                            <Grid item xs={5}>
                                <p className="label">Shape</p>
                                <CustomSchoolSelect isDisabled={true} idsource='shape_id' source="shape" isEdit={true} resource={SignetShapeResources} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <p className="label">Inlay Finish Group</p>
                                <CustomSchoolSelect idsource='inlay_finish_group_id' isEdit={true} source="inlay_finish_group" resource={SignetFinishesDataResources} />
                            </Grid>

                            <Grid item xs={5}>
                                <NumberInput label="Inlay Volume" source="inlay_volume" defaultValue="0" validate={[validateRequired, validatePositiveNumber]} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <NumberInput label="Thickness" source="thickness" defaultValue="0" validate={[validateRequired, validatePositiveNumber]} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <NumberInput label="Inlay Thickness" source="inlay_thickness" defaultValue="0" validate={[validateRequired, validatePositiveNumber]} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <NumberInput source="volume_size_3" label="Volume Size 3" defaultValue="0" validate={[validateRequired, validatePositiveNumber]} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <NumberInput source="volume_size_10" label="Volume Size 10" defaultValue="0" validate={[validateRequired, validatePositiveNumber]} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <NumberInput source="volume_size_17" label="Volume Size 17" defaultValue="0" validate={[validateRequired, validatePositiveNumber]} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <NumberInput label="Min Finger Size" source="min_finger_size" defaultValue="0" validate={[validateRequired, validateNumberGreaterThanZero]} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <NumberInput label="Max Finger Size" source="max_finger_size" defaultValue="0" validate={[validateRequired, validateNumberGreaterThanZero]} fullWidth />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomBooleanInput source="allow_machine_pattern" label="LCV" />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomBooleanInput source="allow_inlay" label="Allow Inlay" />
                            </Grid>

                        </Grid>
                    </SimpleForm>)
                    }
                </WithProps>
            </Edit>
            <Notification />
        </ThemeProvider>
    )
};

export default SignetEngravingEdit;