import React from "react";
import { Route } from "react-router-dom";
import ForgotPassword from "./components/forgotPassword";
import ConfirmForgotPassword from "./components/confirmForgotPassword";
import CurrentUser from "./components/Users/current-user";
import importExport from "./components/Import-Export";
import SiteMetrics from "./components/Dashboard/Site Metrics";
import AWSMetrics from "./components/Dashboard/AWS";
import importExportPriceData from "./components/Import-Export-Price-Data";
import SignetStudio from "./components/Signet/Studio";

export default [
  <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
  <Route
    exact
    path="/confirm-password"
    component={ConfirmForgotPassword}
    noLayout
  />,
  <Route path="/profile" component={CurrentUser} exact />,
  <Route path="/bulk-products" component={importExport} exact />,
  <Route path="/export-product-price" component={importExportPriceData} exact />,
  <Route path="/dashboard/site" component={SiteMetrics} exact />,
  <Route path="/dashboard/application" component={AWSMetrics} exact />,
  <Route path="/signet-studio" component={SignetStudio} />,
];
