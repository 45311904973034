import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, Error } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Loader from "../Loader";
import customTheme from "../../customTheme";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
    margin: 'auto',
  },
}));

const CurrentUser = () => {
  const classes = useStyles();

  const { data: user, loading, error } = useQuery({
    type: "getOne",
    resource: "me",
    payload: { id: "" }
  });

  if (loading) return <Loader timedOut={loading} />;
  if (error) return <Error />;
  if (!user) return null;

  return (
    <ThemeProvider theme={customTheme}>
      <div className={classes.root}>
        <Card>
          <List component="ul">
            <ListItem>
              <Typography variant="strong" component="strong">
                Name:
              </Typography>
              <ListItemText primary={user.name} />
            </ListItem>
            <Divider />
            <ListItem>
              <Typography variant="strong" component="strong">
                Email:
              </Typography>
              <ListItemText primary={user.email} />
            </ListItem>
            <Divider />
            <ListItem>
              <Typography variant="strong" component="strong">
                Created At: 
              </Typography>
              <ListItemText primary={user.created_at} />
            </ListItem>
          </List>
        </Card>
      </div>
    </ThemeProvider>
  );
};

export default CurrentUser;
