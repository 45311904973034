import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  ReferenceArrayField,
  TextInput,
  Filter,
  Loading,
  ImageField,
  SingleFieldList,
  ChipField,
  DeleteButton,
  EditButton,
  SelectInput
} from "react-admin";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ProductPanel from "./product-panel";
import CustomBulkActionButtons from "../Custom/CustomBulkActionsButtons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    width: "100px",
    height: "100%",
    textAlign: "center"
  },
  checkIcon: {
    color: "green"
  },
  clearIcon: {
    color: "#f44336"
  },
  deleteIcon: {
    MuiSvgIcon: {
      root: {
        color: "#f44336"
      }
    }
  },
  chips: {
    backgroundColor: theme.palette.secondary.light
  }
}));

const ProductFilter = props => (
  <Filter {...props}>
    <SelectInput
      label="Active"
      source="active"
      choices={[
        { name: "Active", value: 1 },
        { name: "Inactive", value: 0 }
      ]}
      optionValue="value"
    />
    <TextInput label="Material" source="ring_material" defaultValue="" />
    <TextInput label="Inside Sleeve" source="sleeve" defaultValue="" />
    <TextInput label="Inlay" source="inlay" defaultValue="" />
  </Filter>
);

const ProductList = ({ permissions, ...props }) => {
  const classes = useStyles();
  if (!permissions) return <Loading />;
  const permissionsList = permissions.split(",");

  return (
    <List
      {...props}
      exporter={false}
      filters={<ProductFilter />}
      bulkActionButtons={
        permissionsList.includes("delete_products") ? (
          <CustomBulkActionButtons />
        ) : false
      }
    >
      <Datagrid rowClick="expand" expand={<ProductPanel />}>
        <ImageField
          source="pictures"
          className={classes.image}
          label="Ring Preview"
          sortable={false}
        />
        <TextField source="sku" sortable={false} />
        <ReferenceArrayField
          reference="products/categories"
          source="category_id"
          resource="products/categories"
          link={false}
          sortable={false}
        >
          <SingleFieldList>
            <ChipField source="name" className={classes.chips} />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField label="Wholesale Price" source="wholesale_price" />
        <TextField
          label="Retail Price"
          source="retail_price"
          sortable={false}
        />
        <FunctionField
          label="Active"
          source="active"
          render={record =>
            record.active === 1 ? (
              <CheckIcon className={classes.checkIcon} />
            ) : (
              <ClearIcon className={classes.clearIcon} />
            )
          }
          sortable={false}
        />
        <DateField source="created_at" locales="en-US" sortable={false} />
        {permissionsList.includes("edit_products") && <EditButton />}
        {permissionsList.includes("delete_products") && (
          <DeleteButton className={classes.deleteIcon} undoable={false} />
        )}
      </Datagrid>
    </List>
  );
};

export default ProductList;
