export default class SessionStorage {
  static get(attribute) {
    return sessionStorage.getItem(attribute);
  }

  static add(attribute, data) {
    sessionStorage.setItem(attribute, data);
  }

  static remove(attribute) {
    sessionStorage.removeItem(attribute);
  }
}
