import React, {useCallback} from "react";
import { SaveButton, Toolbar, useCreate, useRedirect, useNotify } from "react-admin";
import { useForm } from 'react-final-form';

const CustomSave = ({ handleSubmitWithRedirect, resource, ...props }) => {
    const [create] = useCreate(resource);
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath } = props;
    const form = useForm();

    const handleClick = useCallback(() => {
        const values = form.getState().values;
        const { isParentStore } = props;
        const { requires_parent_approval } = values;
        const updatedValues = {...values, requires_parent_approval: isParentStore ? false : requires_parent_approval}
        create(
            {
                payload: { data: { ...updatedValues } },
            },
            {
                onSuccess: ({ data }) => {
                    notify(data.message, 2000);
                    redirectTo(basePath);
                },
                onFailure: ({response: {data: {errors}}}) => {
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message, 2000);
                },
            }
        );

    },[form, props.isParentStore]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} disabled={form.getState().hasValidationErrors} />;
};

const CreateToolbar = props => (
    <Toolbar {...props}>
        <CustomSave
            redirect="list"
            submitOnEnter={false}
            variant="text"
            isParentStore={props.isParentStore}
        />
    </Toolbar>
);

export default CreateToolbar;