import React, {useCallback , useState } from "react";
import axios from "axios";
import { Toolbar, useRedirect, useNotify, fetchStart, fetchEnd } from "react-admin";
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-final-form';
import { rawFileExtraction } from "../../dataProvider";
import { useDispatch } from 'react-redux';
import { apiUrl } from "../../dataProvider";

const CustomSave = ({ handleSubmitWithRedirect, resource, action, ...props }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath } = props;
    const form = useForm();

    let axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*"
        }
    };

    const handleClick = useCallback(async () => {
        setLoading(true);
        dispatch(fetchStart());

        const values = form.getState().values;

        if(action === 'edit') {
            const { active, display_filters, hidden, ring_image, background_image, header_background_image, footer_background_image, footer_banner_image,...rest } = values;
            const images = {ring_image, background_image, header_background_image, footer_background_image, footer_banner_image};
            const imageFiles =  Object.keys(images).reduce((image, key) => ({...image, ...images[key] && images[key].hasOwnProperty('rawFile') ? {[key]: images[key]} : null}), {});
            const editData = {
                ...rest,
                ...imageFiles,
                active: active ? 1 : 0,
                display_filters: display_filters ? 1 : 0,
                hidden: hidden ? 1 : 0,
                _method: "PUT"
            };

            const formData = await rawFileExtraction(editData);
            axios
                .post(`${apiUrl}/${resource}/${props.record.id}`, formData, axiosConfig)
                .then(({ data }) => {
                    notify('Category Updated');
                    redirectTo(basePath);
                    return ({
                    data
                })
                })
                .catch(({response: {data: {errors}}}) => {
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message, 'warning')
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(fetchEnd());
                });
        }

        if(action === 'create') {
            const formData = await rawFileExtraction(values);
            axios
                .post(`${apiUrl}/${resource}`, formData, axiosConfig)
                .then(response => {
                    notify('Category Added');
                    redirectTo(basePath);
                    return {
                        data: {
                            ...response.data
                        }
                    };
                })
                .catch(({response: {data: {errors}}}) => {
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message, 'warning')
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(fetchEnd());
                });
        }
    },[form]);

    return <Button
        {...props}
        disabled={form.getState().hasValidationErrors || loading}
        onClick={handleClick}
        startIcon={<SaveIcon/>}
    >
        Save
    </Button>;
};

const CategoryToolbar = props => (
    <Toolbar {...props}>
        <CustomSave
            redirect="list"
            submitOnEnter={false}
            variant="text"
            {...props}
        />
    </Toolbar>
);

export default CategoryToolbar;