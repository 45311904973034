import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import { useNotify, Notification, useDataProvider } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import customTheme from "../customTheme";
import Loader from "./Loader";
import URLs from "../utils/urls";

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    margin: "10% auto",
    padding: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  logo: {
    maxWidth: 200,
    margin: "auto"
  }
});
const handleKeyDown = function(event, submitCallback) {
  if (event.key === "Enter" && event.shiftKey === false) {
    event.preventDefault();
    submitCallback();
  }
};

const ForgotPassword = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const submit = async auth => {
    setLoading(true);
    try {
      await dataProvider
        .create(`${URLs.emailPassword}`, {
          data: { email: email }
        })
        .then(response => {
          setLoading(false);
          notify(response.data.message, 5000);
        });
    } catch (error) {
      setLoading(false);
      notify(error.response.data.email);
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.logo}
          component="img"
          image="LashbrookLogo.png"
          title="Lashbrook Logo"
          style={{ marginBottom: 10 }}
        />
        <CardContent>
          <form
            onSubmit={submit}
            onKeyDown={event => {
              handleKeyDown(event, submit);
            }}
          >
            <TextField
              label="Email"
              name="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              fullWidth
            />
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: 10 }}
                onClick={submit}
              >
                Submit
              </Button>
              <Loader timedOut={loading} />
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <Notification />
    </ThemeProvider>
  );
};

export default ForgotPassword;
