export const CUSTOM_RESOURCES = {
    'custom-pricing/ring-materials': 'custom-pricing/ring-materials',
    'custom-pricing/profiles': 'custom-pricing/profiles',
    'custom-pricing/widths': 'custom-pricing/widths',
    'custom-pricing/weights': 'custom-pricing/weights',
    'custom-pricing/finishes': 'custom-pricing/finishes',
    'custom-pricing/feature-groups': 'custom-pricing/feature-groups',
    'custom-pricing/groove-types': 'custom-pricing/groove-types',
    'custom-pricing/inlays': 'custom-pricing/inlays',
    'custom-pricing/mga-color-options': 'custom-pricing/mga-color-options',
    'custom-pricing/machine-patterns': 'custom-pricing/machine-patterns',
    'custom-pricing/machine-pattern-color-options': 'custom-pricing/machine-pattern-color-options',
    'custom-pricing/gemstone-arrangements': 'custom-pricing/gemstone-arrangements',
    'custom-pricing/gemstone-settings': 'custom-pricing/gemstone-settings',
    'custom-pricing/gemstone-cuts': 'custom-pricing/gemstone-cuts',
    'custom-pricing/gemstone-dimensions': 'custom-pricing/gemstone-dimensions',
    'custom-pricing/gemstones': 'custom-pricing/gemstones',
    'custom-pricing/all-cerakote-options': 'custom-pricing/all-cerakote-options',
    'custom-pricing/sleeves': 'custom-pricing/sleeves',
    'custom-pricing/engraving-options': 'custom-pricing/engraving-options',
    'custom-pricing/engraving-fonts': 'custom-pricing/engraving-fonts',
    'custom-pricing/groove-groups': 'custom-pricing/groove-groups',
    'custom-pricing/sizes': 'custom-pricing/sizes',
}


export const FIRST_FEATURE = 'first-feature'
export const SECOND_FEATURE = 'second-feature'
export const DESIGN_TYPE = {
    inlays: "Inlays",
    designPattern: "Design Pattern",
    allCerakote: "All Cerakote",
    gemstones: "Gemstones",
    text: "Text",
    none: "None",
}