import React from "react";
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    ImageField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    SelectInput,
    Filter,
    DeleteButton,
    EditButton,
    Loading, TextInput
} from "react-admin";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CustomBulkActionButtons from "../Custom/CustomBulkActionsButtons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {},
    image: {
        width: "100px",
        height: "100%",
        textAlign: "center"
    },
    checkIcon: {
        color: "green"
    },
    clearIcon: {
        color: "#f44336"
    },
    deleteIcon: {
        MuiSvgIcon: {
            root: {
                color: "#f44336"
            }
        }
    },
    chips: {
        backgroundColor: theme.palette.secondary.light
    }
}));

const ProductFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Active"
            source="active"
            choices={[
                { name: "Active", value: 1 },
                { name: "Inactive", value: 0 }
            ]}
            optionValue="value"
        />
        <TextInput label="Material" source="ring_material" defaultValue="" />
        <TextInput label="Inside Sleeve" source="sleeve" defaultValue="" />
        <TextInput label="Inlay" source="inlay" defaultValue="" />
    </Filter>
);

const DynamicProducts = props => {
    const classes = useStyles();
    const { permissions } = props;

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    return <List
        {...props}
        exporter={false}
        filters={<ProductFilter />}
        bulkActionButtons={<CustomBulkActionButtons />}
    >
                <Datagrid rowClick={null}>
                    <ImageField
                        source="ring_image_url"
                        className={classes.image}
                        label="Ring Preview"
                        sortable={false}
                    />
                    <ReferenceArrayField
                        reference="categories"
                        source="categories"
                        resource="categories"
                        perPage={1000}
                    >
                        <SingleFieldList>
                            <ChipField source="name" className={classes.chips} />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <TextField source="wholesale_price" />
                    <TextField source="retail_price" sortable={false} />
                    <FunctionField
                        label="Active"
                        source="active"
                        render={record =>
                            record.active === 1 ? (
                                <CheckIcon className={classes.checkIcon} />
                            ) : (
                                <ClearIcon className={classes.clearIcon} />
                            )
                        }
                        sortable={false}
                    />
                    {permissionsList.includes("edit_dynamic-products") && <EditButton />}
                    {permissionsList.includes("delete_dynamic-products") &&  <DeleteButton className={classes.deleteIcon} undoable={false} />}
                </Datagrid>
            </List>
};

export default DynamicProducts;