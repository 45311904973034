import * as React from "react";
import { Link } from 'react-router-dom';
import queryString from "query-string";


const LinkToAccounts = ({ record }) => {
    return record ? (
        <Link
            to={{
                pathname: '/accounts',
                search: queryString.stringify({
                    page: 1,
                    perPage: 25,
                    filter: JSON.stringify({ store_id: record.id }),
                }),
            }}
        >
            View Accounts
        </Link>
    ) : null;
};

export default LinkToAccounts