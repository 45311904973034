import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import {useLocation, useHistory} from "react-router";
import queryString from "query-string";

export default function DateFilters() {
    const { search, pathname } = useLocation();
    const history = useHistory();
    const query = queryString.parse(search.slice(1));
    const today = new Date();
    const lastSixty = new Date(new Date().setDate(today.getDate() - 60)).toISOString().split('T')[0];
    const lastMonth = new Date(new Date().setDate(today.getDate() - 28)).toISOString().split('T')[0];
    const [toDate, setToDate] = React.useState(query?.to_date ? new Date(query.to_date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]);
    const [fromDate, setFromDate] = React.useState(query?.from_date ? new Date(query.from_date).toISOString().split('T')[0] : lastMonth);


    const handleToDateChange = (date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];
        setToDate(formattedDate);
        const url = `${pathname}?from_date=${fromDate}&to_date=${formattedDate}`;
        history.push(url);
    };
    const handleFromDateChange = (date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];
        setFromDate(formattedDate);
        const url = `${pathname}?from_date=${formattedDate}&to_date=${!!toDate && toDate}`;
        history.push(url);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <DatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-from"
                        label="From"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        minDate={lastSixty}
                        maxDate={toDate}
                        disableFuture
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-to"
                        label="To"
                        value={toDate}
                        onChange={handleToDateChange}
                        minDate={fromDate}
                        disableFuture
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
