import React, {useCallback, useState } from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    BooleanInput,
    SelectInput,
    SaveButton,
    DeleteButton,
    Toolbar,
    useUpdate,
    useRedirect,
    useNotify,
    PasswordInput,
    ArrayInput,
    SimpleFormIterator, AutocompleteInput, Loading, useDataProvider,
} from "react-admin";
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import {
    validateRequired as validation,
    validateEmail,
    validateUsername,
    validateNumeric
} from "../../utils/Validations";
import theme from "../../customTheme";
import WithProps from "../Custom/WithProps";

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});

const CustomSave = ({ handleSubmitWithRedirect, resource, ...props }) => {
    const [update] = useUpdate(resource, props.record.id);
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath } = props;
    const form = useForm();

    const handleClick = useCallback(() => {
        const values = form.getState().values;
        const { isParentStore } = props;
        const { requires_parent_approval } = values;
        const updatedValues = {...values, requires_parent_approval: isParentStore ? false : requires_parent_approval}
        update(
            {
                payload: { data: { ...updatedValues } },
            },
            {
                onSuccess: ({ data }) => {
                    notify(data.message, 2000);
                    redirectTo(basePath);
                },
                onFailure: ({response: {data: {errors}}}) => {
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message || "Something went wrong, please try again", 2000);
                },
            }
        );

    },[form, props.isParentStore]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} disabled={form.getState().hasValidationErrors}/>;
};

const EditToolbar = props => {
    return <Toolbar {...props}>
        <CustomSave
            redirect="list"
            submitOnEnter={false}
            variant="text"
            isParentStore={props.isParentStore}
        />
        {props.delete && <DeleteButton undoable={false} />}
    </Toolbar>
};

export const AddressList = ({record: {addresses}}) => {
    const classes = useStyles();

    return addresses ?  <Grid container>
        {addresses.map(address => (
            <Grid
                item
                container
                xs={6}
                direction="column"
                key={`${address.first_name} ${address.address_type}`}
            >
                <Grid item className={classes.capitalize}>{address.address_type === 'both' ? 'Billing and Shipping Address': `${address.address_type} Address`}</Grid>
                <Grid item>{`${address.first_name} ${address.last_name}`}</Grid>
                <Grid item>{address.company}</Grid>
                <Grid item>{address.street_address1}</Grid>
                <Grid item>{address.street_address2}</Grid>
                <Grid item>{address.city}</Grid>
                <Grid item>{address.state}</Grid>
                <Grid item>{address.zip}</Grid>
                <Grid item>{address.country_code}</Grid>
                <Grid item>{address.phone_number}</Grid>
            </Grid>
        ))}
    </Grid> : null;
};

export const AccountEdit = props => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [checked, setChecked] = useState(false);
    const [isParentStore, setParent] = useState();
    const {permissions} = props;

    const selection = store_id => {
        dataProvider.getOne('stores', { id: store_id })
            .then(({ data }) => {
                setParent(data.is_parent_store);
            })
            .catch(error => {
                notify("Something went wrong, please refresh", 2000);
            })
    };

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <Edit {...props}>
            <WithProps>
                {({record, ...props}) =>
                    (<SimpleForm record={record}{...props} toolbar={<EditToolbar delete={permissionsList.includes("delete_accounts")} isParentStore={isParentStore} />}>
                        <Grid container className={classes.container} justify="space-between">
                            <Grid item xs={5}>
                                <TextInput source="first_name" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="last_name" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="email" validate={validateEmail} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="username" validate={validateUsername} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <ReferenceInput
                                    reference="stores"
                                    source="store_id"
                                    label="Store Name"
                                    filterToQuery={searchText => ({store_name: searchText})}
                                    shouldRenderSuggestions={(val) => {
                                        return val.trim().length >= 2
                                    }}
                                    fullWidth
                                    validate={validation}
                                    onChange={selection}
                                >
                                    <AutocompleteInput optionText="store_name" optionValue="id"/>
                                </ReferenceInput>
                            </Grid>
                            <Grid item container xs={8} className={classes.password} alignItems="center">
                                <Grid item xs={3}>
                                    <label>
                                        Change Password
                                        <input type="checkbox" checked={checked} onChange={handleChange}/>
                                    </label>
                                </Grid>
                                {checked &&
                                <Grid item xs={8} alignItems='center'>
                                    <PasswordInput source='password'/>
                                    <Tooltip
                                        title="Password must have at least 8 characters with one uppercase letter and one number"
                                        arrow
                                        leaveDelay={200}
                                    >
                                        <InfoIcon
                                            htmlColor={theme.palette.secondary.dark}
                                            className={classes.marginBounds}
                                        />
                                    </Tooltip>
                                </Grid>}
                            </Grid>
                            <Grid item xs={5}>
                                <ReferenceInput source="account_type_id" reference="accounts/types"
                                                validate={validation} fullWidth><SelectInput
                                    optionText="name"/></ReferenceInput>
                            </Grid>
                            <Grid item container xs={5}>
                                <Grid item>
                                    <BooleanInput source="requires_parent_approval" disabled={(typeof isParentStore !== 'undefined' && isParentStore) || (typeof isParentStore === 'undefined' && record.store.is_parent_store)}/>
                                    <BooleanInput source="show_price_toggle"/>
                                    <BooleanInput source="customer_portal_access"/>
                                    <BooleanInput source="show_data_tab"/>
                                    <BooleanInput source="show_store_toggle" label="Show store toggle & notes"/>
                                    <BooleanInput source="show_store_success_page"/>
                                    <BooleanInput source="show_sales_dashboard"/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <AddressList />
                    </SimpleForm>)
                }
            </WithProps>
        </Edit>
)};