import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router";
import { fetchEnd, fetchStart, useNotify } from "react-admin";
import { apiUrl } from "../../dataProvider";

function GetSiteMetrics() {
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const notify = useNotify();
    const { search } = useLocation();

    useEffect(() => {
        fetchSiteMetrics(search || '').then(() => setLoading(false));
    }, [search]);

    useEffect(() => {
        storeLoginTrend();
        revenuesTrend();
        valuesTrend();
        usersTrend();
        ordersTrend();
    }, [state.metrics]);

    const fetchSiteMetrics = async (dates) => {
        setLoading(true);
        dispatch(fetchStart());

        await axios.get(`${apiUrl}/website/metrics${dates}`)
            .then(({data}) => {
                setState(state => ({ ...state, metrics: data }));
                return {
                    data: {
                        ...data
                    }
                };
            })
            .catch(({response}) => {
                const message = response?.statusText;
                const status = response?.status;
                if (status === 401 || status === 403) {
                    window.location.replace('/#/login');
                }
                notify(message, 'warning');
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd());
            });
    }

    const storeLoginTrend = () => {
        const trend = state?.metrics && Object.keys(state?.metrics?.datewise).map(date => ({
            date,
            logins: state.metrics.datewise[date]["users"]
        }));
        setState(state => ({ ...state, storeLoginTrend: trend }));
    };

    const revenuesTrend = () => {
        const trend = state?.metrics && Object.keys(state?.metrics?.datewise).map(date => ({
            date,
            revenue_b2b: Number(state.metrics.datewise[date]["revenue_b2b"]),
            revenue_b2c: Number(state.metrics.datewise[date]["revenue_b2c"]),
        }));
        setState(state => ({ ...state, revenuesTrend: trend }));
    };

    const valuesTrend = () => {
        const trend = state?.metrics && Object.keys(state?.metrics?.datewise).map(date => ({
            date,
            avg_value_b2b: Number(state.metrics.datewise[date]["avg_value_b2b"]),
            avg_value_b2c: Number(state.metrics.datewise[date]["avg_value_b2c"]),
        }));
        setState(state => ({ ...state, valuesTrend: trend }));
    };

    const usersTrend = () => {
        const trend = state?.metrics && Object.keys(state?.metrics?.datewise).map(date => ({
            date,
            users: state.metrics.datewise[date]["new_store_users"]
        }));
        setState(state => ({ ...state, usersTrend: trend }));
    };

    const ordersTrend = () => {
        const trend = state?.metrics && Object.keys(state?.metrics?.datewise).map(date => ({
            date,
            direct_orders: Number(state.metrics.datewise[date]["direct_orders"]),
            store_orders: Number(state.metrics.datewise[date]["store_orders"]),
        }));
        setState(state => ({ ...state, ordersTrend: trend }));
    };

    return ({
        metrics: state.metrics,
        storeLoginTrend: state.storeLoginTrend,
        valuesTrend: state.valuesTrend,
        revenuesTrend: state.revenuesTrend,
        usersTrend: state.usersTrend,
        ordersTrend: state.ordersTrend,
        loading
    });
}

export default GetSiteMetrics;