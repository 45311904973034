import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useLogin, useNotify, Notification } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import Loader from "./Loader";

const useStyles = makeStyles({
  login: {
    backgroundImage: `url(
      https://wpassets.lashbrookdesigns.com/wp-content/uploads/2019/09/20004127/PageHeaderImage-min.jpg
    )`,
    backgroundSize: "cover",
    backgroundPosition: "50%",
    minHeight: "100vh",
    position: "relative",
    width: "100%",
    backgroundRepeat: "no-repeat"
  },
  root: {
    maxWidth: 300,
    margin: "0 auto",
    padding: "5%",
    maxHeight: 350,
    opacity: 0.9,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  logo: {
    maxWidth: 200,
    margin: "auto"
  },
  resetPassword: {
    paddingLeft: "2%",
    textDecoration: "none",
    color: "#668398",
    "&:hover": {
      color: "#dcc67d"
    }
  }
});

const LoginPage = ({ theme }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();

  const handleKeyDown = function(event, submitCallback) {
    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      submitCallback();
    }
  };

  const submit = auth => {
    setLoading(true);
    login({ email, password }).catch(() => {
      notify("Invalid email or password");
      setLoading(false);
    });
  };

  return (
    <div className={classes.login}>
      <ThemeProvider theme={theme}>
        <Card className={`${classes.root} login-page`}>
          <CardMedia
            className={classes.logo}
            component="img"
            image="LashbrookLogo.png"
            title="Lashbrook Logo"
            style={{ marginBottom: 10 }}
          />
          <CardContent>
            <form
              onSubmit={submit}
              onKeyDown={event => {
                handleKeyDown(event, submit);
              }}
            >
              <TextField
                label="Email"
                name="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                fullWidth
              />
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 10 }}
                  onClick={submit}
                >
                  Login
                </Button>

                <Loader timedOut={loading} />
              </CardActions>
              <Typography variant="body2" component="p" align="center">
                Trouble logging in ?
                <Link
                  to="/forgot-password"
                  color="primary"
                  className={classes.resetPassword}
                >
                  Reset Password
                </Link>
              </Typography>
            </form>
          </CardContent>
        </Card>
        <Notification />
      </ThemeProvider>
    </div>
  );
};

export default LoginPage;
