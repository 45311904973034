import React from "react";
import {
    useListContext, useNotify, useRefresh,
    useUpdate,
} from "react-admin";
import Button from '@material-ui/core/Button';


const ReactivateStore = props => {
    const { filterValues, onUnselectItems } = useListContext();
    const allowReactivate = filterValues && filterValues.deleted_at && filterValues.deleted_at === 'only';

    const handleReactivate = () => props.reactivateStore(props.record.id).then(() => onUnselectItems());

    return (
            allowReactivate ?
            <form onSubmit={handleReactivate}>
                <Button size="small" variant="outlined" color="secondary" type="submit">Reactivate</Button>
            </form> : null
    )
};

export default ReactivateStore;