import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import CreateToolbar from "../../Custom/create-save-button";
import {
    validateRequired
} from "../../../utils/Validations";
import { makeStyles } from "@material-ui/core/styles";
import CustomSchoolSelect from "../CustomSchoolSelect";
import { SignetCerakoteResources, SignetSchoolCerekoteResources } from "../util";

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

const SignetSchoolCerakoteCreate = props => {
    const classes = useStyles();

    return (
        <Create {...props} className="overflow-visible" >
            <SimpleForm toolbar={<CreateToolbar />} >
                <Grid container className={classes.container} justify="space-between">
                    <Grid item xs={5}>
                        <TextInput
                            source="name" label="Name" validate={[validateRequired]} fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <p className="label">School Name</p>
                        <CustomSchoolSelect />
                    </Grid>
                    <Grid item xs={5}>
                        <p className="label">Cerakote Color</p>
                        <CustomSchoolSelect source="cerakote_color" resource={SignetCerakoteResources} />
                    </Grid>

                </Grid>
            </SimpleForm>
        </Create>
    )
};

export default SignetSchoolCerakoteCreate;