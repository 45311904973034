import React from 'react'
import CustomSchoolSelect from '../CustomSchoolSelect'
import CustomMultiSelect from '../CustomMultiSelect'
import { SignetNonSchoolCerekoteResources, SignetSchoolCerekoteResources } from '../util'
import { useInput } from 'react-admin'
import Grid from "@material-ui/core/Grid";

export const FormEngraving = ({ isEdit = false }) => {
    const { input } = useInput({ source: 'school' })


    return (
        <>
            <Grid item xs={5}>
                <p className="label">School Name</p>
                <CustomSchoolSelect />
            </Grid>

            <Grid item xs={5}>
                <CustomMultiSelect reference={input.value ? SignetSchoolCerekoteResources : SignetNonSchoolCerekoteResources} label="Cerakote Colors Allowed" source="cerakote_colors_allowed" />
            </Grid>
        </>
    )
}
