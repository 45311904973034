import React, {useState} from "react";
import {FileInput, FileField, SimpleForm, useRefresh, useNotify, fetchStart, fetchEnd, useCreate, List, Datagrid, TextField, DateField } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import axios from "axios";
import {apiUrl} from "../../dataProvider";
import { rawFileExtraction } from "../../dataProvider";


const useStyles = makeStyles(() => ({
    fileUpload: {
        width: '100%',
    },
    link: {
        marginBottom: '10px',
        cursor: 'pointer',
    },
    fetchPrice: {
        marginTop: "20px"
    },
    warnLabel: {
        fontSize: "12px",
        color: "red",
        display: "block"
    },
}));

const FileUpload = props => {
    const notify = useNotify();
    const form = useForm();
    const isDisabled = Object.keys(form.getState().values).length === 0;

    const handleUpload = async () => {
        const file = {file: form.getState().values.files};
        if(typeof file === 'undefined' || !file.file || file.file.length === 0) {
            notify("Please select a file to upload");
            return;
        }

        const formData = await rawFileExtraction(file);
        props.uploadFile(formData).then(() => {
            form.change("files", []);
        });
    }

    return <Button variant="outlined" disabled={isDisabled} onClick={handleUpload} fullWidth>Upload</Button>
};

const FileDownloadField = ({ record, source }) => {
    return (
        <a
            href={record[source]}
            download
        >
            {record.name}
        </a>
    )
};

export default () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [uploaded, setUploadStatus] = useState(false);
    const dispatch = useDispatch();
    const notify = useNotify();
    const refresh = useRefresh();
    const [create, { error }] = useCreate('fetch-price', '');

    const handlePriceFetch = () => {
        setLoading(true);
        dispatch(fetchStart());

        create( {},
            {
                onSuccess: ({ data }) => {
                    setLoading(false);
                    dispatch(fetchEnd());
                    notify('Price Fetch Completed');
                    return {
                        data: {
                            ...data
                        }
                    };
                },
                onFailure: ({response: {data: {errors}}}) => {
                    setLoading(false);
                    dispatch(fetchEnd());
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message, 2000);
                },
            }
        )
    }

    const uploadFile = async file => {
        setLoading(true);
        dispatch(fetchStart());

        let axiosConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            }
        };
        await axios
            .post(`${apiUrl}/importFile`, file, axiosConfig)
            .then(response => {
                notify('Import Started');
                refresh();
                return {
                    data: {
                        ...response.data
                    }
                };
            })
            .catch(({response: {data: {errors}}}) => {
                const message = errors[Object.keys(errors)[0]][0];
                notify(message, 'warning')
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd());
            });
    };

    const downloadSample = () => {
        let axiosConfig = {
            headers:
                {
                    'Content-Disposition': "attachment; filename=export_sample.xlsx",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }

        axios.get(`${apiUrl}/export/sample`, axiosConfig)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'export_sample.xlsx');
                document.body.appendChild(link);
                link.click();
            });
    };

    const downloadExport = () => {
        let axiosConfig = {
            headers:
                {
                    'Content-Disposition': "attachment; filename=data.csv",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'arraybuffer',
        }

        axios.get(`${apiUrl}/downloadFile`, axiosConfig)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const date = new Date();
                link.setAttribute('download', `data.csv`);
                document.body.appendChild(link);
                link.click();
            });
    };

    const handleFileReject = () => notify("Please upload the correct file format");
    return (
        <Card>
            <CardContent>
                <Grid container xs={12} justify="space-between" alignItems="center">
                    <Grid item xs={5}>
                        <SimpleForm toolbar={null}>
                            <Grid item container justify="center" className={classes.fileUpload}>
                                <Grid item xs={12}>
                                    <FileInput
                                        source="files"
                                        label="Upload Magic-Number/Size CSV"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        multiple={false}
                                        options={{onDropRejected: handleFileReject}}
                                        placeholder={
                                            <p>
                                                Drop a file to upload, or click to select it.
                                                <span className={classes.warnLabel}>
                                        *File format must be .csv
                                    </span>
                                            </p>
                                        }
                                    >
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                </Grid>
                            </Grid>
                            <FileUpload uploadFile={uploadFile} />
                        </SimpleForm>
                    </Grid>
                    <Grid container item xs={5} direction="column" justify="space-between">
                        {/*temporarily disable export button*/}
                        {/*<Button variant="outlined" onClick={downloadExport}>*/}
                        {/*    Export All Products*/}
                        {/*</Button>*/}
                        <Button variant="outlined" onClick={downloadExport} className={classes.fetchPrice} disabled={loading}>
                            Fetch Price
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};