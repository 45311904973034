import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  SelectInput,
  Filter,
  TextInput,
  ShowButton,
  Loading,
    ArrayField,
    SingleFieldList,
    ChipField,
    BooleanField,
    Button,
    useNotify,
    useDataProvider,
    useListContext
  } from "react-admin";
import Approved from '@material-ui/icons/Done';
import Rejected from '@material-ui/icons/Clear';
import Pending from '@material-ui/icons/RadioButtonUnchecked';
import { makeStyles } from "@material-ui/core/styles";
import MaterialUIPickers from './CustomDate';

const DirectOrdersFilter = props => (
  <Filter {...props}>
    <TextInput label="Email" source="customer_email" defaultValue="" />
    <TextInput label="Order ID" source="order_id" defaultValue="" />
    <TextInput label="Customer" source="customer" defaultValue="" />
    <SelectInput
        label="Checkout Method"
        source="checkout_method"
        choices={[
          { name: "Subscription", value: "Subscription" },
          { name: "Credit/Debit", value: "Credit/Debit" }
        ]}
        optionValue="value"
    />
      <MaterialUIPickers source="completed_at_gte" label="Completed After Date" />
      <MaterialUIPickers source="completed_at_lte" label="Completed Before Date" />
  </Filter>
);

const StoreOrdersFilter = props => (
    <Filter {...props}>
      <TextInput label="Email" source="customer_email" defaultValue="" />
      <TextInput label="Order ID" source="order_id" defaultValue="" />
      <TextInput label="Customer" source="customer" defaultValue="" />
      <TextInput label="Store Number" source="store_number" defaultValue="" />
      <SelectInput
          label="Checkout Method"
          source="checkout_method"
          choices={[
            { name: "On Account", value: "On Account" },
          ]}
          optionValue="value"
      />
      <SelectInput
          label="Status"
          source="status"
          choices={[
            { name: "Approved", value: "approved" },
            { name: "Rejected", value: "rejected" },
            { name: "Pending Approval", value: "pending approval" }
          ]}
          optionValue="value"
      />
    </Filter>
);

const useStyles = makeStyles(theme => ({
  root: {},
  chips: {
    backgroundColor: theme.palette.secondary.light
  },
  properCase: {
    textTransform: 'capitalize',
  }
}));

export const CustomerName = ({
  record: { customer_firstname, customer_lastname }
}) => {
  return <div>{`${customer_firstname} ${customer_lastname}`}</div>;
};

const OrderList = props => {
  const classes = useStyles();
    const {permissions} = props;
    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    const approvedRenderer = record => {
      const status = record.status;

      if(status === 'approved' || status === 'completed') return <Approved style={{ color: 'green' }}/>;
      if(status === 'rejected') return <Rejected style={{ color: 'red' }}/>;
      if(status === 'pending' || status === 'pending approval') return <Pending style={{ color: '#e6ca1c' }}/>;
    };

  return (
    <List
      {...props}
      sort={{ field: "created_at", order: "DESC" }}
      filters={props.resource === "direct/orders" ? <DirectOrdersFilter /> : <StoreOrdersFilter/> }
      exporter={false}
      bulkActionButtons={<CustomSyncOrdersButton />}
      title={props.resource === "direct/orders" ? "Direct Orders" : "Store Orders"}
    >
      <Datagrid rowClick={null}>
        <TextField source="order_id" label="Order ID" sortable={false} />
          <ArrayField source="items" sortable={false}>
              <SingleFieldList linkType={false}>
                  <ChipField source="sku" className={classes.chips} />
              </SingleFieldList>
          </ArrayField>
       {props.resource !== "direct/orders" && <TextField source="store_number" label="Store No." sortable={false} />}
        <CustomerName label="Customer" />
        <TextField source="customer_email" label="Email" />
        <FunctionField source="is_approved" label="Approved" sortable={false} render={approvedRenderer}/>
          <BooleanField source="is_synced" label="Synced" />
        <TextField className={classes.properCase} source="status" sortable={false} />
        <TextField
          source="checkout_method"
          label="Checkout Mode"
        />
        <DateField label="Created At(MST)" source="created_at" options={{timeZone: 'America/Denver'}} />
          {props.resource === "direct/orders" && <DateField label="Completed At(MST)" source="completed_at" options={{timeZone: 'America/Denver'}} />}
        <TextField source="grand_total" label="Grand Total" sortable={false} />
          {permissionsList.includes("view_orders") && <ShowButton/>}
      </Datagrid>
    </List>
  );
};

export default OrderList;


function CustomSyncOrdersButton({selectedIds}) {
    const [isLoading, setIsLoading] = useState(false)
    const dataProvider = useDataProvider();
    const {data,onUnselectItems,refetch} = useListContext();
    const notify = useNotify();

    const handleSync = async () => {
      setIsLoading(true);
      try {
        const orders = selectedIds.map(id => data[id].order_id);
        await dataProvider
          .updateMany('admin/orders/sync', {orders})
          .then(() => {
            onUnselectItems()
            refetch()
            setIsLoading(false);
            notify('Selected orders are synced', 2000);
          });
      } catch (error) {
        setIsLoading(false);
        notify('Something went wrong');
      }
    };

  return (
      <Button
        label="Sync orders"
        onClick={handleSync}
        disabled={isLoading}
      />
  );
};