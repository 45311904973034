import { makeStyles } from "@material-ui/core/styles";
import { Filters } from "./filter";
import { CustomExportButton, CustomImportButton } from "./util";
import { CreateButton, FilterButton, FilterForm, TopToolbar } from "react-admin";
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        width: '100%',
        padding: 0
    },
});


export const ListActions = (props) => {
    const filters = props.filters ? props.filters : Filters();
    const classes = useStyles();
    return (
        <TopToolbar className={classes.container}>
            <Box display={'flex'} alignItems={'flex-end'} justifyContent="space-between" width={'100%'} sx={{ paddingBottom: '14px' }}>
                <Box display={'flex'} alignItems={'center'} justifyContent="space-between">
                    <FilterForm filters={filters} />
                </Box>
                <Box display={'flex'} justifyItems={'flex-end'} sx={{gap: '20px'}}>
                    {!props.hideImportExport && <CustomImportButton {...props} />}
                    {!props.hideImportExport && <CustomExportButton {...props} />}
                    <FilterButton filters={filters} />
                    <CreateButton />
                </Box>
            </Box>
        </TopToolbar>
    )
}


export default ListActions;