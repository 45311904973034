import React from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {GRAPH_COLORS, labelFormatter} from "../Constants";

const TotalRequests = ({ data }) => {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={500}
                data={data}
                margin={{
                    top: 5,
                    right: 20,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip labelFormatter={labelFormatter} />
                <Legend />
                <Bar dataKey="requests" fill={GRAPH_COLORS.solid1} />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default TotalRequests;