import React from "react";
import {
    Create,
    Notification,
    SimpleForm,
    TextInput,
    useNotify,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import CreateToolbar from "../../Custom/create-save-button";
import {
    validateRequired
} from "../../../utils/Validations";
import { ThemeProvider } from "@material-ui/styles";

import { makeStyles } from "@material-ui/core/styles";
import {  SignetSignetOptionCategories, SignetSignetInlayTypes, SignetInlayFinishes, SignetShapeResources } from "../util";
import MultiSelect from "./MultiSelect";
import CustomSchoolSelect from "../CustomSchoolSelect";
import '../style.css';
const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

const SignetInlayCreate = props => {
    const classes = useStyles();

    const notify = useNotify();
    const onFailure = (error) => {
        notify(error.response.data.message ? error.response.data.message : 'Something went wrong', { type: 'warning' });
    };
    return (
        <ThemeProvider>
            <Create onFailure={onFailure} {...props} className="overflow-visible" >
                <SimpleForm toolbar={<CreateToolbar />} >
                    <Grid container className={classes.container} justify="space-between">


                        <Grid item xs={5}>
                            <p className="label">Inlay Type Id</p>

                            <CustomSchoolSelect idsource='inlay_type_id' resource={SignetSignetInlayTypes} source="inlay_type_name" />
                        </Grid>

                        <Grid item xs={5}>
                            <p className="label">Option Category Name</p>

                            <CustomSchoolSelect idsource='option_category_id' resource={SignetSignetOptionCategories}  source="option_category_name" />
                        </Grid>

                        <Grid item xs={5}>
                            <TextInput
                                source="name" label="Name" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput
                                source="netsuite_id" label="Netsuite Id" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput
                                source="netsuite_inlay_group" label="Netsuite Inlay Group" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput
                                source="netsuite_inlay_type" label="Netsuite Inlay Type" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <MultiSelect reference={SignetInlayFinishes} label="Supported Finishes" source="supported_finishes" />
                        </Grid>

                        <Grid item xs={5}>
                            <TextInput
                                source="sku_code" label="Sku Code" validate={[validateRequired]} fullWidth />
                        </Grid>


                    </Grid>
                </SimpleForm>
            </Create>
            <Notification />
        </ThemeProvider>
    )
};

export default SignetInlayCreate;