import React, {useCallback, useState } from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    BooleanInput,
    SelectInput,
    SaveButton,
    DeleteButton,
    Toolbar,
    useUpdate,
    useRedirect,
    useNotify,
    PasswordInput,
    ArrayInput,
    SimpleFormIterator, AutocompleteInput, Loading, useDataProvider,
} from "react-admin";
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import {
    validateRequired as validation,
    validateEmail,
    validateUsername,
    validateNumeric
} from "../../utils/Validations";
import theme from "../../customTheme";
import WithProps from "../Custom/WithProps";

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});

const CustomSave = ({ handleSubmitWithRedirect, resource, ...props }) => {
    const [update] = useUpdate(resource, props.record.id);
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath } = props;
    const form = useForm();

    const handleClick = useCallback(() => {
        const values = form.getState().values;
        const updatedValues = {...values}
        update(
            {
                payload: { data: { ...updatedValues } },
            },
            {
                onSuccess: ({ data }) => {
                    notify(data.message, 2000);
                    redirectTo(basePath);
                },
                onFailure: ({response: {data: {errors}}}) => {
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message || "Something went wrong, please try again", 2000);
                },
            }
        );

    },[form]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} disabled={form.getState().hasValidationErrors}/>;
};

const EditToolbar = props => {
    return <Toolbar {...props}>
        <CustomSave
            redirect="list"
            submitOnEnter={false}
            variant="text"
        />
        {props.delete && <DeleteButton undoable={false} />}
    </Toolbar>
};

export const StoreLocationEdit = props => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [checked, setChecked] = useState(false);
    const [isParentStore, setParent] = useState();
    const {permissions} = props;

    const selection = store_id => {
        dataProvider.getOne('stores', { id: store_id })
            .then(({ data }) => {
                setParent(data.is_parent_store);
            })
            .catch(error => {
                notify("Something went wrong, please refresh", 2000);
            })
    };

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <Edit {...props}>
            <WithProps>
                {({record, ...props}) =>
                    (<SimpleForm record={record}{...props} toolbar={<EditToolbar delete={permissionsList.includes("delete_accounts")} />}>
                        <Grid container className={classes.container} justify="space-between">
                            <Grid item xs={5}>
                                <TextInput source="name" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="store_number" parse={value => (value === "" ? null : value)} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="netsuite_id" parse={value => (value === "" ? null : value)} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="address" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="city" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="state" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="zip" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="country" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="phone" parse={value => (value === "" ? null : value)} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="website" parse={value => (value === "" ? null : value)} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="logo" parse={value => (value === "" ? null : value)} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="hours_open" parse={value => (value === "" ? null : value)} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="lat" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="lng" validate={validation} fullWidth/>
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput source="materials" parse={value => (value === "" ? null : value)} fullWidth/>
                            </Grid>
                        </Grid>
                    </SimpleForm>)
                }
            </WithProps>
        </Edit>
)};