import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {labelFormatter} from "../Constants";

const InstanceHealthLine = ({ data, color, dataKey }) => {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart
                width={500}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip labelFormatter={labelFormatter} />
                <Legend />
                <Line type="monotone" dataKey={dataKey} stroke={color} />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default InstanceHealthLine;