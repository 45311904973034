import React from "react";
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer} from "recharts";
import {GRAPH_COLORS, labelFormatter} from "../Constants";

const OrderVolume = ({ trends }) => {
    return (
        <ResponsiveContainer width="100%" height={250}>
            <BarChart
                width={500}
                data={trends}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip labelFormatter={labelFormatter} />
                <Legend />
                <Bar name="Direct Orders" dataKey="direct_orders" stackId="a" fill={GRAPH_COLORS.solid1} />
                <Bar name="Store Orders" dataKey="store_orders" stackId="a" fill={GRAPH_COLORS.solid2} />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default OrderVolume;