import React from "react";
import StoreLogins from "./StoreLogins";
import GetSiteMetrics from "../GetSiteMetrics";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Revenues from "./Revenues";
import OrderValue from "./OrderValue";
import OrderVolume from "./OrderVolume";
import DateFilters from "../DateFilters";
import GetGoogleAnalytics from "../GetGoogleAnalytics";
import GaUsers from "./GaUsers";
import BounceRate from "./BounceRate";
import PagesPerSession from "./PagesPerSession";
import AvgSessionDuration from "./AvgSessionDuration";
import TotalSessions from "./TotalSessions";
import AvgPageLoadTime from "./AvgPageLoadTime";
import TopBrowsers from "./TopBrowsers";
import TopReferrers from "./TopReferrers";
import MostVisitedPages from "./MostVisitedPages";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 18,
    },
    pos: {
        marginBottom: 12,
    },
});



const SiteMetrics = () => {
    const classes = useStyles();
    const { loading, metrics, storeLoginTrend, revenuesTrend, valuesTrend, usersTrend, ordersTrend } = GetSiteMetrics();
    const { loading: gaLoading,
        totalUsersTrend,
        avgSessionDurationTrend,
        pagesPerSessionTrend,
        bounceRateTrend,
        combinedUsers,
        totalSessions,
        avgPageLoadTime,
        topBrowsers,
        topReferrers,
        mostVisitedPages,
    } = GetGoogleAnalytics();

    if (loading && gaLoading) return null;

    return (
        <Grid container justifyContent="center" spacing={3}>
            <Grid xs={12} item>
                <DateFilters />
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {totalUsersTrend && <GaUsers trends={combinedUsers} />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Users
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {storeLoginTrend && <StoreLogins trends={storeLoginTrend} expanded />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Unique Store Users
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {metrics && revenuesTrend && <Revenues b2c={metrics.net_revenue_b2c} b2b={metrics.net_revenue_b2b} trends={revenuesTrend} expanded />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Revenues
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {valuesTrend && <OrderValue trends={valuesTrend} expanded />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Average Order Value
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {valuesTrend && <OrderVolume trends={ordersTrend} expanded />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Order Volume
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {bounceRateTrend && <BounceRate trends={bounceRateTrend} />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Bounce Rate
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {pagesPerSessionTrend && <PagesPerSession trends={pagesPerSessionTrend} />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Pages per Session
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {avgSessionDurationTrend && <AvgSessionDuration trends={avgSessionDurationTrend} />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Average Session Duration
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {avgPageLoadTime && <AvgPageLoadTime trends={avgPageLoadTime} />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Avg. Page Load Time
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card className={classes.root}>
                    <CardContent>
                        {topBrowsers && <TopBrowsers data={topBrowsers} />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Top Browsers and Sessions
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8}>
                <Card className={classes.root}>
                    <CardContent>
                        {totalSessions && <TotalSessions trends={totalSessions} />}
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Total Sessions
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Most Visited Pages
                        </Typography>
                        {mostVisitedPages && <MostVisitedPages data={mostVisitedPages} />}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" align="center">
                            Top Referrers
                        </Typography>
                        {topReferrers && <TopReferrers data={topReferrers} />}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
};

export default SiteMetrics;