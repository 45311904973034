import React, { useCallback, useEffect, useState } from "react";
import { useInput } from "react-admin";
import AsyncSelect from "react-select/async";
import axios from 'axios';
import { apiUrl } from "../../dataProvider";
import { FormHelperText } from '@material-ui/core';

import './style.css';

const CustomSchoolSelect = ({ isEdit, validate = [], pageSize = 1000, idsource = '', isDisabled = false, source = 'school', resource = 'schools', emptyCarakote = false, ...props }) => {
    const [list, setList] = useState([]);
    const [searchValue, setSearchValue] = useState();
    const [loading, setLoading] = useState(false);

    const { input,
        meta: { touched, error, }
    } = useInput({ source, validate })
    const { input: inputCarakote } = useInput({ source: 'cerakote_colors_allowed' })

    const { input: inputSourceId } = useInput({ source: idsource })

    const getLists = (params) => axios.get(`${apiUrl}/${resource}`, { params }).then(({ data }) => data.data)
    const getSearchOptions = value => getLists({ 'filter[name]': value });

    const setStoreList = useCallback(async (params) => {
        try {
            setLoading(true);
            const response = await getLists(params);
            if (isEdit) {
                const selected = response.find(item => {
                    return item.sku_code === input.value || item.name === input.value || item.attribute_type_id === input.value || item.option_category_id === input.value;
                });
                if (selected) input.onChange(selected?.sku_code || selected?.id || selected?.attribute_type_id || selected?.size_id || selected?.option_category_id);
            }
            setLoading(false);
            setList(response);
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }

    }, [isEdit, input])

    /** This effect calls initial load with  and when you clear value  */
    useEffect(() => {
        if (list.length === 0) {
            setStoreList(
                {
                    'page[number]': 1,
                    'page[size]': pageSize
                }
            );
        }
    }, [list.length, setStoreList])


    const handleChange = callback => selectedopt => {
        if (searchValue) {
            setStoreList({ 'filter[name]': searchValue });
        }

        // if edit mode, then clear all selected cerakote colors
        if (emptyCarakote) {
            inputCarakote.onChange([]);
        }

        if (idsource) {
            inputSourceId.onChange(selectedopt?.id || selectedopt?.finish_group_id || selectedopt?.attribute_type_id || selectedopt?.option_category_id); // sets the id properties
            callback(selectedopt?.name)
        } else {
            callback(selectedopt?.sku_code);
        }

    }

    return (
        <div>
            <AsyncSelect
                {...input}
                isClearable
                cacheOptions
                isLoading={loading}
                styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                }}
                defaultOptions={list}
                isDisabled={isDisabled}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.sku_code || e.id || e.attribute_type_id || e.option_category_id}
                value={list.find(item => item.sku_code === input.value || item.id === input.value || item.attribute_type_id === input.value || item.option_category_id === input.value)} // value must be object, so get selected opt from input.value
                loadOptions={getSearchOptions}
                onChange={handleChange(input.onChange)}
                onInputChange={setSearchValue}
            />
            {touched && error && <FormHelperText error>{error === 'ra.validation.required' ? "Required" : error}</FormHelperText>}
        </div>
    )
}

export default CustomSchoolSelect;