import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Loading,
} from "react-admin";

const ShippingList = props => {
    const {permissions} = props;
    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    return <List {...props} exporter={false} bulkActionButtons={false} pagination={false} title={props.resource === "account/shipping" ? "Store Shipping" : "Guest Shipping"}>
            <Datagrid rowClick={null}>
                <TextField source="service" />
                <TextField source="cost" />
                {permissionsList.includes("edit_shipping") && <EditButton />}
            </Datagrid>
        </List>
};

export default ShippingList;