import * as React from 'react';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import { FilterList, FilterListItem, useGetList } from 'react-admin';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);


const FilterSidebar = () => {
    const { data, ids } = useGetList(
        'categories',
        { page: 1, perPage: 100 },
    );
    return <Card>
            <CardContent>
                <FilterList
                    label="Category Filter"
                    icon={<LocalOfferIcon />}
                >
                    {ids.map((id) => (
                        <FilterListItem
                            label={data[id].name}
                            key={data[id].id}
                            value={{ category_id: data[id].id }}
                        />
                    ))}
                </FilterList>
            </CardContent>
        </Card>
};

export default FilterSidebar;