import React from "react";
import {
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  Create,
  required,
  minLength,
  email
} from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CreateToolbar from "../Custom/create-save-button";
import theme from "../../customTheme";

const validateName = [required(), minLength(2)];
const validateEmail = [required(), email()];
const validatePassword = required();
const validateRoles = required();

const useStyles = makeStyles(() => ({
  root: {
    width: "50%"
  },
  roles: {
    width: "30%"
  },
  marginBounds: {
    marginTop: "-20px"
  }
}));

const UserCreate = props => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <TextInput source="email" validate={validateEmail} />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="name" validate={validateName} />
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <PasswordInput source="password" validate={validatePassword} />
            <Tooltip
              title="Password must have at least 8 characters with one uppercase letter and one number"
              arrow
              leaveDelay={200}
            >
              <InfoIcon
                htmlColor={theme.palette.secondary.dark}
                className={classes.marginBounds}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayInput label="Roles" source="roles" reference="roles" resource="roles">
              <SelectArrayInput
                validate={validateRoles}
                className={classes.roles}
              >
                <ChipField source="name" />
              </SelectArrayInput>
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
