import {Filter, TextInput, NumberInput, BooleanInput, SelectInput} from "react-admin";
import React from "react";

export const StoresFilter = props => (
    <Filter {...props}>
        <TextInput label="Store Name" source="store_name" defaultValue="" />
        <NumberInput label="Retail Multiplier" source="retail_multiplier" defaultValue="" />
        <NumberInput label="Netsuite ID" source="netsuite_id" defaultValue="" />
        <BooleanInput label="Parent Store" source="is_parent_store" defaultValue={true} parse={v => v ? 1 : 0}/>
        <SelectInput
            label="Deleted Accounts"
            source="deleted_at"
            choices={[
                { name: "Show only Deleted", value: "only" },
                { name: "Show All", value: "with" }
            ]}
            optionValue="value"
        />
    </Filter>
);