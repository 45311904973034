import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useNotify } from 'react-admin';
import { useSignetStudio } from '../SignetStudioContext';
import { ACTIONS } from '../reducers/studioReducer';
import { apiUrl } from '../../../../dataProvider';
import { createMappingStructure } from '../utils/mappingUtils';

const handleAPIError = (error, notify) => {
  const status = error.response?.status;
  if (status === 401 || status === 403) {
    window.location.replace('/#/login');
    return;
  }
  const message = error.response?.data?.message || 'An error occurred';
  notify(message, { type: 'error' });
  console.error('API Error:', error);
};

/**
 * Hook for handling engraving-related API operations
 */
export const useEngravingAPI = () => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const { state, dispatch, preloadTexture } = useSignetStudio();

  const fetchConfig = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/signet-shape-config`);
      if (response?.data) {
        dispatch({ type: ACTIONS.SET_SHAPE_CONFIGS, payload: response?.data?.data  || [] });
      }
    } catch (error) {
      handleAPIError(error, notify);
    }
  }, [notify, dispatch]);

  useEffect(() => {
    if (!state.shapeConfigs || state.shapeConfigs.length === 0) {
      fetchConfig();
    }
  }, [fetchConfig, state.shapeConfigs]);

  const fetchMaterials = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/ring-materials/type/signet`);
      if (response?.data) {
        const materials = response.data;
        dispatch({ type: ACTIONS.SET_MATERIALS_LIST, payload: materials });

        // Create new mapping data with just materials
        const updatedMapping = createMappingStructure(null, null, null, materials);
        dispatch({ type: ACTIONS.SET_MAPPING_DATA, payload: updatedMapping });
      }
    } catch (error) {
      handleAPIError(error, notify);
    }
  }, [notify, dispatch]);

  useEffect(() => {
    if (!state?.materialList || state?.materialList?.length === 0) {
      fetchMaterials();
    }
  }, [fetchMaterials, state?.materialList]);

  const fetchEngravingsList = useCallback(async () => {
    if (state?.engravingsList?.length > 0) return state?.engravingsList;

    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/signet-engravings`, {
        params: {
          'page[number]': 1,
          'page[size]': 1000,
          sort: 'id',
        },
      });
      const data = response.data.data;
      dispatch({ type: ACTIONS.SET_ENGRAVINGS_LIST, payload: data });
      return data;
    } catch (error) {
      handleAPIError(error, notify);
      return [];
    } finally {
      setLoading(false);
    }
  }, [notify, dispatch, state.engravingsList]);

  useEffect(() => {
    if (state?.engravingsList?.length === 0) {
      fetchEngravingsList();
    }
  }, [fetchEngravingsList]);

  const fetchEngravingDetails = useCallback(
    async (engravingId) => {
      if (!engravingId) return null;
      setLoading(true);

      try {
        const response = await axios.get(`${apiUrl}/signet-engraving-details/${engravingId}`);
        const data = response?.data?.data;

        if (data) {
          dispatch({ type: ACTIONS.SET_SELECTED_ENGRAVING, payload: data });

          // Create new mapping data with shapes and colors
          const updatedMapping = createMappingStructure(
            data.shapes_allowed,
            data.sizes_allowed,
            data.cerakote_colors_allowed,
            null,
          );
          dispatch({ type: ACTIONS.SET_MAPPING_DATA, payload: updatedMapping });
          dispatch({ type: ACTIONS.SET_FETCHED_ENGRAVING_ID, payload: engravingId });
        }

        return data;
      } catch (error) {
        handleAPIError(error, notify);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [notify, dispatch],
  );

  const fetchTextureImages = useCallback(async () => {
    if (state?.textureImages) return;

    try {
      const response = await axios.get(`${apiUrl}/texture-images`);
      if (response?.data?.success) {
        const textureData = response.data.data;

        // Dispatch texture data immediately
        dispatch({ type: ACTIONS.SET_TEXTURE_IMAGES, payload: textureData });

        // Start background preloading without awaiting
        (async () => {
          const batchSize = 15; // Process 5 images at a time
          const urls = Object.values(textureData).map(({ preview_url }) => preview_url);

          // Process images in batches
          for (let i = 0; i < urls.length; i += batchSize) {
            const batch = urls.slice(i, i + batchSize);

            // Process batch in parallel
            await Promise.all(
              batch.map((url) =>
                preloadTexture(url).catch((error) => {
                  console.warn('Failed to preload texture image:', error);
                }),
              ),
            );
          }
        })();
      }
    } catch (error) {
      handleAPIError(error, notify);
    }
  }, [notify, dispatch, state.textureImages, preloadTexture]);

  useEffect(() => {
    if (!state?.textureImages || state?.textureImages?.length === 0) {
      fetchTextureImages();
    }
  }, [fetchTextureImages, state?.textureImages]);

  return {
    loading,
    options: state.engravingsList,
    fetchEngravingsList,
    fetchEngravingDetails,
    fetchMaterials,
    fetchConfig,
    fetchTextureImages,
  };
};
