import React from 'react'
import { ChipField, ReferenceArrayInput, SelectArrayInput, useInput } from 'react-admin'
import { validateRequired } from '../../utils/Validations'
import { SignetEngravingRingMaterialsResources, SignetSchoolCerekoteResources } from './util'

const CustomMultiSelect = ({ source = '', isDisable = false, label = '', perPage = 100, reference = '', ...props }) => {
    const { input } = useInput({ source: 'school' })

    const filter = {}

    if (reference === SignetSchoolCerekoteResources) {
        filter['school_sku_code'] = input.value
    }

    if(reference === SignetEngravingRingMaterialsResources) {
        if(input.value) {
            filter['is_collegiate'] = true;
        } else  {
            filter['is_collegiate'] = false;
        }
    }


    return (
        <ReferenceArrayInput
            label={label}
            source={source}
            reference={reference}
            perPage={perPage}
            filter={filter}
            sort={{ field: 'id', order: 'ASC' }}
        >
            <SelectArrayInput
                label={label}
                optionText="name"
                disabled={isDisable}
                optionValue={props.optionValue || (reference === SignetSchoolCerekoteResources ? 'cerakote_color' : "sku_code")}
                fullWidth
                initialValue=""
                className="width-full" validate={validateRequired}>
                <ChipField source="name" />
            </SelectArrayInput>
        </ReferenceArrayInput>
    )
}

export default CustomMultiSelect