import React from "react";
import { Show, SimpleShowLayout, ReferenceField, TextField } from "react-admin";

const AccountPanel = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="website"/>
                <TextField source="city" />
                <TextField source="country" />
                <TextField source="address" />
                <TextField source="logo" />
                <TextField source="materials" />
            </SimpleShowLayout>
        </Show>
    );
};

export default AccountPanel;
