import {
    required,
    maxLength,
    email,
    regex,
    number,
    minValue,
} from "react-admin";

export const validateRequired = required();
export const validateProductDescription = [required(), maxLength(255, "Description should be 255 characters or less")];
export const validateEmail = [required(), email()];
export const validateNumeric = number('The Value should be numeric');
export const validateNumericRequired = [required(), number('The Value should be numeric')];
export const validateUsername = [required(),
    maxLength(255, "Username should be less than 255 characters"),
    regex(/^\S+$/, "Username should not contain spaces")];
export const validateDomain = regex(/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})[\/]{1}$/, "Enter valid domain without http / https")

const isEmpty = (value) =>
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0);

export const greaterThan = (min, message) => (value, values) =>
    !isEmpty(value) && value <= min
        ? message
        : undefined

export const validateNumberGreaterThanZero = greaterThan(0, 'Value must be greater than 0');


export const validatePositiveNumber = (value) => {
    if (value <= 0) {
        return 'Value must be greater than 0';
    }
    return undefined; // No error
};
