import React from "react";
import {
    Create,
    Notification,
    SimpleForm,
    useNotify,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import CreateToolbar from "../../Custom/create-save-button";

import { ThemeProvider } from "@material-ui/styles";

import { makeStyles } from "@material-ui/core/styles";
import '../style.css';
import PriceForm from "./PriceForm";
const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

const SignetPricingCreate = props => {
    const classes = useStyles();

    const notify = useNotify();
    const onFailure = (error) => {
        notify(error.response.data.message ? error.response.data.message : 'Something went wrong', { type: 'warning' });
    };
    return (
        <ThemeProvider>
            <Create onFailure={onFailure} {...props} className="overflow-visible" >
                <SimpleForm toolbar={<CreateToolbar />} >
                    <Grid container className={classes.container} justify="space-between">
                        <PriceForm />
                    </Grid>
                </SimpleForm>
            </Create>
            <Notification />
        </ThemeProvider>
    )
};

export default SignetPricingCreate;