import axios from 'axios';
import { useCallback, useState } from 'react';
import { apiUrl } from '../../../../dataProvider';

export const useImageProcessing = () => {
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState({ current: 0, total: 0, message: '', stage: '' });

  const formatMetadata = (metadata) => ({
    ring_material: metadata.material?.sku || '',
    shape: metadata.shape || '',
    size: metadata.size || '',
    shank: metadata.shank || 'T',
    school: metadata.school || '',
    engraving: metadata.engraving || '',
    cerakote_color: metadata.color || '',
    image_number: 1,
  });

  const convertSvgToPng = (svgElement, width = 1200, height = 1200) => {
    return new Promise((resolve, reject) => {
      try {
        // Wait for next frame to ensure SVG is rendered
        requestAnimationFrame(() => {
          try {
            // Clone the SVG to avoid modifying the original
            const clonedSvg = svgElement.cloneNode(true);

            // Set dimensions explicitly
            clonedSvg.setAttribute('width', width);
            clonedSvg.setAttribute('height', height);
            clonedSvg.setAttribute('viewBox', '0 0 1200 1200');

            // Ensure all elements are rendered
            const allElements = clonedSvg.getElementsByTagName('*');
            for (const element of allElements) {
              if (element.getBBox) {
                try {
                  const bbox = element.getBBox();
                  // Add a small offset to ensure element is rendered
                  element.setAttribute('data-bbox', `${bbox.x},${bbox.y},${bbox.width},${bbox.height}`);
                } catch (e) {
                  console.warn('Could not get bbox for element:', element);
                }
              }
            }

            const svgString = new XMLSerializer().serializeToString(clonedSvg);
            const blob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(blob);

            const img = new Image();
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');

            img.onload = () => {
              ctx.clearRect(0, 0, width, height);
              ctx.drawImage(img, 0, 0, width, height);
              canvas.toBlob(
                (blob) => {
                  URL.revokeObjectURL(url);
                  resolve(blob);
                },
                'image/png',
                1.0,
              );
            };

            img.onerror = (error) => {
              URL.revokeObjectURL(url);
              reject(error);
            };

            img.src = url;
          } catch (innerError) {
            reject(innerError);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  // Upload files in batches of 5 per request
  const uploadPngBatches = async (files, uploadUrl, batchSize = 5, onProgress) => {
    const results = [];
    const errors = [];
    const totalBatches = Math.ceil(files.length / batchSize);
    const maxRetries = 0;
    const retryDelay = 1000;

    const uploadBatchWithRetry = async (batchFiles, retryCount = 0) => {
      try {
        const formData = new FormData();

        batchFiles.forEach((file, index) => {
          formData.append(`files[${index}]`, file.blob);

          // Add metadata fields for each file
          Object.entries(file.metadata).forEach(([key, value]) => {
            formData.append(`metadata[${index}][${key}]`, value);
          });
        });

        // Fix: Use axios response handling correctly
        const response = await axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // Fix: axios already returns parsed data
        return response.data;
      } catch (error) {
        if (retryCount < maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          return uploadBatchWithRetry(batchFiles, retryCount + 1);
        }
        throw error;
      }
    };

    for (let i = 0; i < files.length; i += batchSize) {
      const batch = files.slice(i, i + batchSize);

      try {
        const batchResult = await uploadBatchWithRetry(batch);
        results.push(batchResult);
      } catch (error) {
        console.error(`Failed to upload batch starting at index ${i}:`, error);
        batch.forEach((file) => {
          errors.push({ id: file.id, error });
        });
      }

      onProgress?.(Math.floor(i / batchSize) + 1, totalBatches);

      // Add delay between batches if not the last batch
      if (i + batchSize < files.length) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }

    return {
      successful: results,
      failed: errors,
    };
  };

  const processVariations = useCallback(async () => {
    console.log('Starting processVariations...');
    setProcessing(true);
    const results = [];
    const batchSize = 10; // Process 10 SVGs at a time

    try {
      await new Promise((resolve) => requestAnimationFrame(resolve));

      const svgElements = document.querySelectorAll('.variant-svg');
      console.log('Found SVG elements:', svgElements.length);

      if (svgElements.length === 0) {
        console.warn('No SVG elements found');
        return [];
      }

      // Convert NodeList to Array for easier batch processing
      const svgArray = Array.from(svgElements);
      const totalBatches = Math.ceil(svgArray.length / batchSize);

      setProgress({
        current: 0,
        total: totalBatches,
        message: 'Starting conversion...',
        stage: 'converting',
      });

      // Process SVGs in batches
      for (let i = 0; i < svgArray.length; i += batchSize) {
        const batch = svgArray.slice(i, i + batchSize);
        
        // Process batch in parallel
        const batchPromises = batch.map(async (svgElement, batchIndex) => {
          try {
            // Convert to PNG
            const pngBlob = await convertSvgToPng(svgElement);
            const previewUrl = URL.createObjectURL(pngBlob);

            // Get metadata from data attributes and dataset
            const metadata = {
              shape: svgElement.dataset.shape,
              size: svgElement.dataset.size,
              color: svgElement.dataset.color,
              material: svgElement.dataset.material ? JSON.parse(svgElement.dataset.material) : null,
              shank: svgElement.dataset.shank,
              school: svgElement.dataset.school,
              engraving: svgElement.dataset.engraving,
            };

            return {
              id: `variation_${i + batchIndex}`,
              pngBlob,
              previewUrl,
              metadata,
              shape: metadata.shape,
            };
          } catch (error) {
            console.error('Error processing SVG:', error);
            return null;
          }
        });

        // Wait for all SVGs in the batch to be processed
        const batchResults = await Promise.all(batchPromises);
        results.push(...batchResults.filter(Boolean));

        // Update progress
        setProgress({
          current: Math.floor(i / batchSize) + 1,
          total: totalBatches,
          message: `Converting batch ${Math.floor(i / batchSize) + 1} of ${totalBatches}`,
          stage: 'converting',
        });

        // Add a small delay between batches to prevent browser from freezing
        if (i + batchSize < svgArray.length) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
      }

      // Upload the processed images
      if (results.length > 0) {
        setProgress({
          current: 0,
          total: Math.ceil(results.length / 5), // Upload batch size is 5
          message: 'Starting upload...',
          stage: 'uploading',
        });

        const uploadResults = await uploadPngBatches(
          results.map((result, index) => ({
            id: result.id,
            blob: result.pngBlob,
            metadata: formatMetadata(result.metadata, index),
          })),
          `${apiUrl}/upload-files`,
          5, // Upload batch size
          (current, total) => {
            setProgress({
              current,
              total,
              message: `Uploading batch ${current} of ${total}`,
              stage: 'uploading',
            });
          },
        );

        console.log('Upload results:', uploadResults);
      }

      return results;
    } catch (error) {
      console.error('Error processing variations:', error);
      throw error;
    } finally {
      setProcessing(false);
    }
  }, []);

  return {
    processing,
    progress,
    processVariations,
  };
};
