import React, { useState } from "react";
import axios from "axios";
import { useRedirect, useNotify, fetchStart, fetchEnd } from "react-admin";
import { useDispatch } from "react-redux";
import { apiUrl } from "../../dataProvider";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const options = ["Efficiency", "Staff", "Operations", "Work-Centers"];

const BulkUpload = (...props) => {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const resource = "netsuite/dashboard/upload";
  const { basePath } = props;

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleSelect = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  };
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    dispatch(fetchStart());
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    formData.append("upload_type", selected);
    axios
      .post(`${apiUrl}/${resource}`, formData, axiosConfig)
      .then(({ data }) => {
        notify("File Uploaded Successfully");
        // redirectTo(basePath);
        window.location.reload();
        return {
          data,
        };
      })
      .catch(
        ({
          response: {
            status: { errors },
          },
        }) => {
          const message = errors[Object.keys(errors)[0]][0];
          notify(message, "warning");
        }
      )
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h3>Bulk Upload to CRM</h3>
        <br />
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={handleChange}
          required
        />
        <br /><br />
        <FormControl className={classes.formControl} required>
          <InputLabel id="mutiple-select-label">Select File Type</InputLabel>
          <Select
            labelId="mutiple-select-label"
            multiple
            value={selected}
            onChange={handleSelect}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    selected.length > 0 && selected.length < options.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                <ListItemIcon>
                  <Checkbox checked={selected.indexOf(option) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br /> <br />
        <Button type="submit" variant="outlined">Upload</Button>
      </form>
    </div>
  );
};

export default BulkUpload;
