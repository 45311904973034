import React, { useState, useCallback } from "react";
import {
    SimpleForm,
    Edit,
    TextInput,
    BooleanInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ChipField,
    SaveButton,
    DeleteWithConfirmButton,
    Toolbar,
    Notification,
    useNotify
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/styles";
import customTheme from "../../customTheme";
import {validateProductDescription, validateRequired} from "../../utils/Validations";
import WithProps from "../Custom/WithProps";

const useStyles = makeStyles(() => ({
    root: {
        width: "90%",
        margin: "auto"
    },
    toolbar: {
        justifyContent: 'space-between'
    },
    grouping: {
        border: "1px solid lightgray",
        borderRadius: "3px",
        margin: "1% auto"
    },
    warnLabel: {
        fontSize: "10px",
        color: "red",
        display: "block"
    },
    displayFlex: {
        display: "flex"
    },
    equalWidth: {
        maxWidth: "33%"
    }
}));

const EditToolbar = props => {
    const classes = useStyles();
    return <Toolbar {...props} className={classes.toolbar}>
                <SaveButton disabled={props.pristine} />
                <DeleteWithConfirmButton />
            </Toolbar>
};

const EditDynamicProduct = props => {
    const classes = useStyles();
    const [checked, setChecked] = useState();

    const handleChange = useCallback(newChecked => setChecked(newChecked), []);

    const notify = useNotify();
    const onFailure = (error) => {
        notify(error.response.data.message ? error.response.data.message : 'Something went wrong', {type:'warning'});
    };

    return (
        <ThemeProvider theme={customTheme}>
            <Edit mutationMode="pessimistic" onFailure={onFailure} {...props}>
                <WithProps>
                    {({record, ...props}) =>
                        <SimpleForm record={record} {...props} redirect="list" toolbar={<EditToolbar/>}>
                            <Grid
                                container
                                spacing={2}
                                className={classes.root}
                                justify="space-between"
                            >
                                <Grid item xs={12}>
                                    <ReferenceArrayInput
                                        source="categories"
                                        reference="categories"
                                        resource="categories"
                                        validate={validateRequired}
                                        perPage={1000}
                                    >
                                        <SelectArrayInput
                                            label="Category"
                                            optionText="name"
                                            optionValue="sku_code"
                                            fullWidth
                                            initialValue=""
                                        >
                                            <ChipField source="name" />
                                        </SelectArrayInput>
                                    </ReferenceArrayInput>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    className={classes.displayFlex}
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid item xs={12}>
                                        <TextInput
                                            source="magic_number"
                                            label="Magic Number"
                                            validate={validateRequired}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextInput
                                            source="action_type"
                                            label="Action Type"
                                            validate={validateRequired}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <BooleanInput
                                            label={((typeof checked === 'undefined' && record.active === 1) || checked) ? "Active" : "Inactive"}
                                            source="active"
                                            initialValue={1}
                                            onChange={handleChange}
                                            checked={checked}
                                            parse={v => (v ? 1 : 0)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput
                                            source="description"
                                            label="Description"
                                            fullWidth
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SimpleForm>
                    }
                </WithProps>
            </Edit>
            <Notification />
        </ThemeProvider>
    );
};

export default EditDynamicProduct;
