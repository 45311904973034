import React, { createContext, useContext, useReducer, useCallback, useRef, useMemo } from 'react';
import { studioReducer, initialState, ACTIONS } from './reducers/studioReducer';
import { getSelectedMappings, updateMappingChecked } from './utils/mappingUtils';

export const SignetStudioContext = createContext();

/**
 * Provider component that wraps app with Signet Studio context
 * Manages state for engraving selection, mapping data, and SVG uploads
 */
export const SignetStudioProvider = ({ children }) => {
  const [state, dispatch] = useReducer(studioReducer, initialState);
  const textureCache = useRef(new Map());

  const preloadTexture = useCallback(async (url) => {
    if (!url || textureCache.current.has(url)) {
      return textureCache.current.get(url);
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataUrl = canvas.toDataURL('image/png');
        textureCache.current.set(url, dataUrl);
        resolve(dataUrl);
      };

      img.onerror = reject;
      img.src = url;
    });
  }, []);

  const getTextureFromCache = useCallback((url) => {
    return textureCache.current.get(url);
  }, []);

  const selectedMappings = useMemo(() => {
    if (!state.mappingData) return [];
    return getSelectedMappings(state.mappingData);
  }, [state.mappingData]);

  const handleMappingChange = useCallback(
    (type, id, checked) => {
      if (!state.mappingData) return;
      const updatedData = updateMappingChecked(state.mappingData, type, id, checked);
      dispatch({ type: ACTIONS.SET_MAPPING_DATA, payload: updatedData });
    },
    [state.mappingData, dispatch],
  );

  const value = {
    state,
    selectedMappings,
    handleMappingChange,
    dispatch,
    preloadTexture,
    getTextureFromCache,
  };

  return <SignetStudioContext.Provider value={value}>{children}</SignetStudioContext.Provider>;
};

/**
 * Hook to access Signet Studio context
 * @throws {Error} If used outside of SignetStudioProvider
 */
export const useSignetStudio = () => {
  const context = useContext(SignetStudioContext);
  if (!context) {
    throw new Error('useSignetStudio must be used within a SignetStudioProvider');
  }
  return context;
};
