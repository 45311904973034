import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { processSvgContent } from '../utils/svgUtils';
import InnerShadowFilter from './svg/InnerShadowFilter';
import { useSignetStudio } from '../SignetStudioContext';

const useStyles = makeStyles(() => ({
  svgContainer: {
    width: '100%',
    height: 'auto',
    marginTop: 8,
  },
}));

/**
 * SizePreview
 * 
 * Renders:
 *  1) A 1200x1200 SVG.
 *  2) A main path scaled & centered in the root coordinate system.
 *  3) A mask path with optional padding, also scaled & centered identically.
 *  4) An uploaded shape, also placed in the root coordinate system (no extra <g> transform),
 *     and masked so it doesn't exceed the path boundary.
 */
const SizePreview = ({ svgFile, shapeConfig, sizeConfig, disabled }) => {
  const classes = useStyles();
  const { state, getTextureFromCache } = useSignetStudio();

  // Refs to the DOM elements
  const svgRef = useRef(null);
  const pathRef = useRef(null);         // The main displayed path
  const maskRef = useRef(null);         // The mask path that clips the shape
  const contentGroupRef = useRef(null); // A group for the user-uploaded shape
  // (or you could place the user-uploaded shape at the root <svg> level if you wish)

  const scaleRef = useRef(1); // Just to store if needed

  // Unique mask ID
  const maskId = React.useMemo(
    () => `shapeMask-${sizeConfig?.size_sku}-${shapeConfig?.shape_sku}`,
    [sizeConfig?.size_sku, shapeConfig?.shape_sku]
  );

  // Unique IDs for filters
  const shadowId = React.useMemo(
    () => `shadow-${sizeConfig?.size_sku}-${shapeConfig?.shape_sku}`,
    [sizeConfig?.size_sku, shapeConfig?.shape_sku]
  );

  useEffect(() => {
    if (!svgFile || !sizeConfig || !shapeConfig) return;

    const loadSvg = async () => {
      try {
        // Grab the "shank" config, or whichever path data you want:
        const shank = sizeConfig.shanks?.find(s => s.shank_sku === 'T');
        if (!shank) return;

        //
        // 1) Basic SVG setup
        //
        const mainSvg = svgRef.current;
        mainSvg.setAttribute('viewBox', '0 0 1200 1200');
        mainSvg.setAttribute('width', '100%');
        mainSvg.setAttribute('height', '100%');

        //
        // 2) Place & scale the main path
        //
        const mainPath = pathRef.current;
        mainPath.setAttribute('d', shank.path);
        mainPath.setAttribute('fill', disabled ? '#f5f5f5' : '#e0e0e0');
        mainPath.setAttribute('stroke', disabled ? '#eee' : '#ccc');
        mainPath.setAttribute('stroke-width', '1');

        // Measure raw bounding box
        const pathBBox = mainPath.getBBox();
        if (!pathBBox || !isFinite(pathBBox.width) || !isFinite(pathBBox.height)) return;

        // Compute how to scale to match the "real" dimension:
        const maxShapeDimension = Math.max(shank.width, shank.height);
        const pathMaxDimension = Math.max(pathBBox.width, pathBBox.height);
        const pathScale = maxShapeDimension / pathMaxDimension;
        scaleRef.current = pathScale;

        // Center the scaled path in 1200x1200
        const scaledW = pathBBox.width * pathScale;
        const scaledH = pathBBox.height * pathScale;
        const pathTranslateX = (1200 - scaledW) / 2 - pathBBox.x * pathScale;
        const pathTranslateY = (1200 - scaledH) / 2 - pathBBox.y * pathScale;

        // Set final transform on the main path
        mainPath.setAttribute(
          'transform',
          `translate(${pathTranslateX}, ${pathTranslateY}) scale(${pathScale})`
        );

        //
        // 3) Build the mask path transform
        //
        if (maskRef.current) {
          maskRef.current.setAttribute('d', shank.path);

          // By default, same transform as main path
          let maskTransform = `translate(${pathTranslateX}, ${pathTranslateY}) scale(${pathScale})`;

          // If there's padding, we shrink the mask around the path's center
          if (shank.padding > 0) {
            const centerX = pathBBox.x + pathBBox.width / 2;
            const centerY = pathBBox.y + pathBBox.height / 2;
            const paddingScale = 1 - shank.padding / 100;

            maskTransform = [
              `translate(${pathTranslateX}, ${pathTranslateY})`,
              `scale(${pathScale})`,
              `translate(${centerX}, ${centerY})`,
              `scale(${paddingScale})`,
              `translate(${-centerX}, ${-centerY})`,
            ].join(' ');
          }

          maskRef.current.setAttribute('transform', maskTransform);
        }

        //
        // 4) Parse and place the user-uploaded SVG
        //
        const reader = new FileReader();
        reader.onload = () => {
          const contentString = processSvgContent(reader.result, disabled ? '#eee' : '#666', true);
          const parser = new DOMParser();
          const doc = parser.parseFromString(contentString, 'image/svg+xml');
          const uploadedSvg = doc.querySelector('svg');
          if (!uploadedSvg) return;

          // Clear any old shape
          const contentGroup = contentGroupRef.current;
          contentGroup.innerHTML = '';

          // Copy child nodes from the uploaded SVG into our <g>
          Array.from(uploadedSvg.childNodes).forEach(node => {
            if (node.nodeType === 1) {
              contentGroup.appendChild(node.cloneNode(true));
            }
          });

          // Now measure the bounding box of the user shape
          const shapeBBox = contentGroup.getBBox();
          if (!shapeBBox || !isFinite(shapeBBox.width) || !isFinite(shapeBBox.height)) return;

          // Additional user scale factor (like if shank.scale = 20 => +20%)
          const userScaleFactor = 1 + (shank.scale || 0) / 100;

          // The real "available" size is basically the shank's width/height times userScaleFactor
          const availableW = shank.width * userScaleFactor;
          const availableH = shank.height * userScaleFactor;

          // Compute how much to scale the shape to fit in that area
          const shapeFitScale = Math.min(
            availableW / shapeBBox.width,
            availableH / shapeBBox.height
          );

          // Combine with pathScale to align with the main path in the root coordinate system
          const finalShapeScale = shapeFitScale * pathScale;

          // Center the shape in 1200×1200
          const finalW = shapeBBox.width * finalShapeScale;
          const finalH = shapeBBox.height * finalShapeScale;

          const shapeTranslateX = (1200 - finalW) / 2 - shapeBBox.x * finalShapeScale;
          const shapeTranslateY = (1200 - finalH) / 2 - shapeBBox.y * finalShapeScale;

          // Calculate shape's center point for rotation
          const shapeCenterX = shapeBBox.x + shapeBBox.width / 2;
          const shapeCenterY = shapeBBox.y + shapeBBox.height / 2;
          
          // Get rotation angle from config
          const rotation = sizeConfig?.shanks[0]?.shape_rotation || 0;

          // Apply transforms in this order: translate to position, move to shape center, rotate, move back, scale
          contentGroup.setAttribute(
            'transform',
            [
              `translate(${shapeTranslateX}, ${shapeTranslateY})`,
              `translate(${shapeCenterX * finalShapeScale}, ${shapeCenterY * finalShapeScale})`,
              `rotate(${rotation})`,
              `translate(${-shapeCenterX * finalShapeScale}, ${-shapeCenterY * finalShapeScale})`,
              `scale(${finalShapeScale})`
            ].join(' ')
          );
        };
        reader.readAsText(svgFile);

      } catch (error) {
        console.error('Error rendering SVG:', error);
      }
    };

    loadSvg();
  }, [svgFile, sizeConfig, shapeConfig, disabled]);

  return (
    <div className={classes.svgContainer}>
      <svg ref={svgRef} xmlns="http://www.w3.org/2000/svg">
        <defs>
          <mask
            id={maskId}
            maskUnits="userSpaceOnUse"
            maskContentUnits="userSpaceOnUse"
            x="0" y="0" width="1200" height="1200"
          >
            <rect x="0" y="0" width="1200" height="1200" fill="black" />
            {/* White path that defines the mask area */}
            <path ref={maskRef} fill="white" />
          </mask>
          <InnerShadowFilter 
            id={shadowId} 
            shadowColor="#000000" 
            strength={sizeConfig?.shanks[0]?.shadow || 'standard'} 
          />
        </defs>

        {/* Main visible path (the "shank") */}
        <path ref={pathRef} />
        
        {/* Group of the user-uploaded shape, masked so it cannot exceed the path boundary */}
        <g
          mask={sizeConfig?.shanks[0]?.padding > 0 ? `url(#${maskId})` : null}
        >  
          <g 
            ref={contentGroupRef}
            filter={`url(#${shadowId})`}
          /> 
        </g>
      </svg>
    </div>
  );
};

export default SizePreview;
