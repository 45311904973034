import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import { useNotify, Notification, useDataProvider } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import { useForm, Controller } from "react-hook-form";
import queryString from "query-string";
import customTheme from "../customTheme";
import Loader from "./Loader";
import URLs from "../utils/urls";

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    margin: "10% auto",
    padding: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  logo: {
    maxWidth: 200,
    margin: "auto"
  }
});

const handleKeyDown = function(event, submitCallback) {
  if (event.key === "Enter" && event.shiftKey === false) {
    event.preventDefault();
    submitCallback();
  }
};

const ConfirmForgotPassword = props => {
  let queryParams = queryString.parse(props.location.search);
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const { errors, handleSubmit, control, getValues } = useForm({});
  const submit = async data => {
    setLoading(true);
    try {
      await dataProvider
        .create(`${URLs.passwordReset}`, {
          data: { password: data.password, ...queryParams }
        })
        .then(response => {
          setLoading(false);
          notify(response.data.message, 2000);
          setTimeout(function() {
            props.history.push("/login");
          }, 4000);
        });
    } catch (error) {
      setLoading(false);
      notify(error.response.data.password);
    }
  };
  return (
    <ThemeProvider theme={customTheme}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.logo}
          component="img"
          image="LashbrookLogo.png"
          title="Lashbrook Logo"
          style={{ marginBottom: 10 }}
        />

        <CardContent>
          <form
            onSubmit={e => e.preventDefault()}
            onKeyDown={event => {
              handleKeyDown(event, handleSubmit(submit));
            }}
          >
            <Controller
              name="password"
              label="Password"
              control={control}
              rules={{
                required: "You must specify a password"
              }}
              as={<TextField name="password" type="password" />}
              fullWidth
            />
            {errors.password && <p>{errors.password.message}</p>}
            <Controller
              name="password_repeat"
              label="Confirm Password"
              control={control}
              rules={{
                required: "You must specify a password",
                validate: value => {
                  if (value === getValues()["password"]) {
                    return true;
                  } else {
                    return "The passwords do not match";
                  }
                }
              }}
              as={<TextField name="password_repeat" type="password" />}
              fullWidth
            />
            {errors.password_repeat && <p> {errors.password_repeat.message}</p>}

            <CardActions>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: 10 }}
                onClick={handleSubmit(submit)}
              >
                Submit Password
              </Button>
              <Loader timedOut={loading} />
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <Notification />
    </ThemeProvider>
  );
};

export default ConfirmForgotPassword;
