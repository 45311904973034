import React, { useState } from "react";
import {
    BooleanInput,
    Create,
    FormDataConsumer,
    NumberInput,
    SimpleForm,
    TextInput,
} from "react-admin";
import { get } from 'lodash';

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { ArrowBack } from "@material-ui/icons";

import Grid from "@material-ui/core/Grid";

import Loader from "./../Loader";
import { Box, Typography, Button as MUIButton } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { validateNumberGreaterThanZero, validateRequired } from "../../utils/Validations";
import { Save, CustomSelect, InlayType, EngravingType, AdditionalPriceButton } from "./util";
import { CUSTOM_RESOURCES, FIRST_FEATURE } from "./type";
import { useForm } from "react-final-form";

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
});

export const CustomPricing = (props) => {
    const classes = useStyles();

    const [datasource, setDatasource] = useState({
        loading: false,
        data: null,
        featureGroupCount: 1,
        additionalPrice: 1,
    })

    const renderResponse = () => {
        if (datasource.data) {
            return (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& > *': { m: 1 },
                    gap: 2,
                    padding: '2rem',
                    border: '2px solid',
                    marginTop: '1rem',
                }}>
                    <Box sx={{ display: 'flex', gap: 10, alignItems: 'center', flexDirection: 'column' }}>
                        <MUIButton style={{ alignSelf: 'flex-start' }} onClick={() => setDatasource({ loading: false, data: null })} variant="text" color="primary">
                            <ArrowBack />
                            Back
                        </MUIButton>
                        {datasource.data.price_breakdown && datasource.data.price_breakdown.length > 0 && (
                            <Box sx={{ width: '100%', display: 'flex', gap: 10, flexDirection: 'column' }}>
                                <Typography align="left" variant="h3">Price Breakdown </Typography>

                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="Price Breakdown">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>Label</TableCell>
                                                <TableCell>Value</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datasource.data.price_breakdown.map((row, i) => (
                                                <TableRow key={row.label}>
                                                    <TableCell>{i + 1}</TableCell>
                                                    <TableCell>{row.label}</TableCell>
                                                    <TableCell>{row.value}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}

                        {datasource.data.weight_breakdown && datasource.data.weight_breakdown.length > 0 && (
                            <Box sx={{ width: '100%', display: 'flex', gap: 10, flexDirection: 'column' }}>
                                <Typography align="left" variant="h3">weight Breakdown </Typography>

                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="weight_breakdown">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>Label</TableCell>
                                                <TableCell>Value</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datasource.data.weight_breakdown.map((row, i) => (
                                                <TableRow key={row.label}>
                                                    <TableCell>{i + 1}</TableCell>
                                                    <TableCell>{row.label}</TableCell>
                                                    <TableCell>{row.value}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                        <Box sx={{ width: '100%', display: 'flex', marginTop: 10 }}>
                            <Typography align="right" variant="h3">Price :   {get(datasource.data, 'price', 0)} </Typography>
                        </Box>

                    </Box>
                </Box>

            )
        }
    }


    return (
        <>
            <Create {...props} className="overflow-visible" >
                <SimpleForm toolbar={<Save datasource={datasource} setDatasource={setDatasource} />} >
                    <Grid container className={classes.container} justify="space-between">
                        {/* Added below 2 grid for work around. Due to unpredeicatable reasons, Validation message not coming. So we added two none fields */}
                        <Grid item xs={5} style={{display: 'none'}}>
                            <TextInput source="title2" defaultValue={null} disabled={datasource.data} label="Title 2" fullWidth />
                        </Grid>
                        <Grid item xs={5} style={{ display: 'none '}}>
                            <CustomSelect label="Ring Material 2" isDisabled={datasource.data} source="ring_material2" resource={CUSTOM_RESOURCES["custom-pricing/ring-materials"]} />
                        </Grid>

                        <Grid item xs={5}>
                            <TextInput source="title" validate={[validateRequired]} defaultValue={null} disabled={datasource.data} label="Title" fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput source="description" defaultValue={null} disabled={datasource.notes} label="Description" fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomSelect label="Ring Material" isDisabled={datasource.data} source="ring_material" validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/ring-materials"]} />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomSelect label="Profile" source="profile" isDisabled={datasource.data} resource={CUSTOM_RESOURCES["custom-pricing/profiles"]} />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomSelect label="Width" source="width" isDisabled={datasource.data} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/widths"]} />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomSelect label="Size" source="size" isDisabled={datasource.data} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/sizes"]} />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomSelect label="Ring Finish 1" isDisabled={datasource.data} source="ring_finish.0" resource={CUSTOM_RESOURCES["custom-pricing/finishes"]} />
                        </Grid>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData.ring_finish?.[0] ? (
                                <Grid item xs={5}>
                                    <CustomSelect label="Ring Finish 2" isDisabled={datasource.data} source="ring_finish.1" resource={CUSTOM_RESOURCES["custom-pricing/finishes"]} />
                                </Grid>
                            ) : null}
                        </FormDataConsumer>
                        <Grid item xs={5}>
                            <CustomSelect label="Weight" source="weight" isDisabled={datasource.data} resource={CUSTOM_RESOURCES["custom-pricing/weights"]} />
                        </Grid>
                        <Grid item xs={5}>
                            <CustomSelect label="Sleeve" source="sleeve" isDisabled={datasource.data} resource={CUSTOM_RESOURCES["custom-pricing/sleeves"]} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput source="thickness" defaultValue={null} disabled={datasource.data} label="Thickness" fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput source="volume" defaultValue={null} disabled={datasource.data} label="Volume" fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <BooleanInput
                                label={"Is Cast"}
                                disabled={datasource.data}
                                source="is_cast"
                                defaultValue={false}
                            />
                        </Grid>
                        <Grid container>
                            <Grid item xs={5}>
                                <TextInput source="notes" defaultValue={null} disabled={datasource.data} label="Notes" fullWidth multiline minRows={2}/>
                            </Grid>
                        </Grid>

                        <Counter
                            datasource={datasource}
                            setDatasource={setDatasource}
                            label="Outside Feature"
                            count={datasource.featureGroupCount}
                            stateKey="featureGroupCount"
                            formKey="outside_feature"
                        />

                        {[...Array(datasource.featureGroupCount).keys()].map((_, i) => (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                border: '1px solid #ddd',
                                borderRadius: 8,
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                padding: '1rem',
                                flexDirection: 'column',
                                marginTop: '1rem',
                            }} key={i}>
                                <InlayType disabled={datasource.data} key={i} count={i}
                                    type={FIRST_FEATURE}
                                />
                            </Box>))}

                            <Counter
                                datasource={datasource}
                                setDatasource={setDatasource}
                                label="Additional Price"
                                count={datasource.additionalPrice}
                                stateKey="additionalPrice"
                                formKey="additional_price"
                            />
                        {[...Array(datasource.additionalPrice).keys()].map((_, i) => (
                            <Box
                                sx={
                                    {
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        border: '1px solid #ddd',
                                        borderRadius: 8,
                                        padding: '1rem',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        marginY: '0.5rem',
                                    }
                                }
                                key={i}>
                                <Grid item xs={5}>
                                    <TextInput disabled={datasource.data} defaultValue={null} label="Label" source={`additional_price.${i}.label`} fullWidth />
                                </Grid>
                                <Grid item xs={5}>
                                    <NumberInput disabled={datasource.data} defaultValue={null} label="Price" fullWidth source={`additional_price.${i}.price`} validate={[validateNumberGreaterThanZero, (value, others) => {
                                        if (others[`additional_price.${i}.label`] && !others[`additional_price.${i}.price`]) {
                                            return 'Price is required'
                                        }
                                        return undefined; // No error
                                    }]} />
                                </Grid>
                            </Box >

                        ))}
                        <Loader timedOut={datasource.loading} />
                        <EngravingType disabled={datasource.data} />
                    </Grid>
                </SimpleForm>
            </Create>
            {renderResponse()}
        </>
    )
}


export const Counter = ({ datasource, setDatasource, label, count, stateKey, formKey }) => {
    const form = useForm();
    return (
        <Grid item xs={12}>
            <Box sx={{
                display: 'flex',
                gap: 10,
                alignItems: 'center',
                paddingY: '1rem',
                justifyContent: 'space-between',
            }}>
                <Typography>{label}</Typography>
                <Box sx={{ display: 'flex', gap: 10, alignItems: 'center' }}>

                    <MUIButton
                        variant="contained"
                        disabled={datasource.data}
                        onClick={() => {
                            if (count > 1) {
                                setDatasource({ ...datasource, [stateKey]: count - 1 });

                                const values = form.getState().values;
                                const list = values?.[formKey] || [];
                                list.pop();
                                form.change(formKey, list);
                            }
                        }}
                    >-</MUIButton>
                    <Typography>{count}</Typography>

                    <MUIButton
                        variant="contained"
                        disabled={datasource.data}
                        onClick={() => {
                            setDatasource({ ...datasource, [stateKey]: datasource[stateKey] + 1 })
                        }}
                    >+</MUIButton>  </Box>
            </Box>
        </Grid>
    )
}
