import React, {useCallback} from "react";
import { SaveButton, Toolbar, useCreate, useRedirect, useNotify } from "react-admin";
import { useForm } from 'react-final-form';

const CustomSave = ({ handleSubmitWithRedirect, resource, ...props }) => {
    const [create] = useCreate(resource);
    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath } = props;
    const form = useForm();

    const handleClick = useCallback(() => {
        const values = form.getState().values;
        create(
            {
                payload: { data: { ...values } },
            },
            {
                onSuccess: ({ data }) => {
                    notify(data.message, 2000);
                    redirectTo(basePath);
                },
                onFailure: ({response: {data: {errors}}}) => {
                    if('message' in errors) {
                        notify(errors.message, { type: 'warning' });
                        return;
                    }
                    const message = errors[Object.keys(errors)[0]][0];
                    notify(message,{ type: 'warning' });
                },
            }
        );

    },[form]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} disabled={form.getState().hasValidationErrors} />;
};

const CreateToolbar = props => (
    <Toolbar {...props}>
        <CustomSave
            redirect="list"
            submitOnEnter={false}
            variant="text"
        />
    </Toolbar>
);

export default CreateToolbar;