import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
}));

const ConsolidatedList = ({ info, title }) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h5" component="h5" align="center">
                {title}
            </Typography>
            <div className={classes.root}>
                {Object.keys(info).map(key => (
                    <Paper key={key}>
                        <Box textAlign="center" marginTop={3}>
                            <Typography
                                color="textSecondary"
                            >
                                {key}
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {typeof info[key] === 'number' ? info[key] : ' '}
                            </Typography>
                        </Box>
                    </Paper>
                ))}
            </div>
        </>
    );
}

export default ConsolidatedList;