import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    userSelect: 'none',
  },
  svg: {
    transform: 'rotate(-90deg)',
  },
  backgroundCircle: {
    fill: 'none',
    stroke: theme.palette.grey[200],
  },
  valueCircle: {
    fill: 'none',
    stroke: theme.palette.primary.main,
    strokeLinecap: 'round',
  },
  handle: {
    position: 'absolute',
    width: 10,
    height: 10,
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    boxShadow: theme.shadows[2],
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)',
  },
  value: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

const CircularRange = ({
  size = 60,
  strokeWidth = 4,
  value = 0,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const [isDragging, setIsDragging] = React.useState(false);
  const sliderRef = useRef(null);

  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  const handleMouseDown = (event) => {
    if (disabled) return;
    setIsDragging(true);
    updateValue(event);
  };

  const handleMouseMove = (event) => {
    if (isDragging && !disabled) {
      updateValue(event);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const updateValue = (event) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const center = {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      };
      const dx = event.clientX - center.x;
      const dy = event.clientY - center.y;
      let angle = Math.atan2(dy, dx) * (180 / Math.PI);
      angle = (angle + 360) % 360; // Normalize angle to 0-360
      angle = (angle + 90) % 360; // Rotate so 0 is at the top
      onChange(Math.round(angle));
    }
  };

  const getPointOnCircle = (angle) => {
    const x = radius * Math.sin((angle * Math.PI) / 180);
    const y = -radius * Math.cos((angle * Math.PI) / 180);
    return { x: x + size / 2, y: y + size / 2 };
  };

  const point = getPointOnCircle(value);

  return (
    <div
      ref={sliderRef}
      className={classes.root}
      style={{ 
        width: size, 
        height: size,
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'default' : 'pointer'
      }}
      onMouseDown={handleMouseDown}
    >
      <svg width={size} height={size} className={classes.svg}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          className={classes.backgroundCircle}
          strokeWidth={strokeWidth}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          className={classes.valueCircle}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (value / 360) * circumference}
        />
      </svg>
      <div
        className={classes.handle}
        style={{
          left: point.x,
          top: point.y,
        }}
      />
      <Typography className={classes.value} variant="body2">
        {value}°
      </Typography>
    </div>
  );
};

export default CircularRange; 