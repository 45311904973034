import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    Loading,
} from "react-admin";
import Grid from '@material-ui/core/Grid';
import WithProps from "../../Custom/WithProps";
import { makeStyles } from '@material-ui/core/styles';
import {
    validateRequired,
} from "../../../utils/Validations";
import { CustomBooleanInput, EditToolBar } from "../util";

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});

 const SignetSizeEdit = props => {
    const classes = useStyles();
    const { permissions } = props;

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    return (
        <Edit {...props} className='overflow-visible'>
            <WithProps>
                {({ record, ...props }) =>
                (<SimpleForm record={record} {...props} undoable={false} toolbar={<EditToolBar delete={permissionsList.includes("delete_material_data")} />}>
                    <Grid container className={classes.container} justify="space-between">
                        <Grid item xs={5}>
                            <TextInput
                                source="name" label="Name" validate={[validateRequired]} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput
                                source="sku_code" label="Sku Code" validate={[validateRequired]} fullWidth />
                        </Grid>

                        <Grid item xs={5}>
                            <CustomBooleanInput source="active" label="Active" />
                        </Grid>

                    </Grid>
                </SimpleForm>)
                }
            </WithProps>
        </Edit>
    )
};

export default  SignetSizeEdit;