import React, { useCallback } from "react";
import {
  Edit,
  SimpleForm,
  TextField,
  SaveButton,
  Toolbar,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  useUpdate,
  useRedirect,
  useNotify,
    DeleteButton,
} from "react-admin";
import { useForm } from "react-final-form";

const SUPER_ADMIN = 'SuperAdmin';

const CustomSave = ({ handleSubmitWithRedirect, ...props }) => {
  const [update] = useUpdate("roles", props.record.id);
  const redirectTo = useRedirect();
  const notify = useNotify();
  const form = useForm();
  const { basePath } = props;

  const handleClick = useCallback(() => {
    const { name, id, ...rest } = form.getState().values;

    update(
      {
        payload: { data: { ...rest } }
      },
      {
        onSuccess: ({ data }) => {
          notify(data.message, 2000);
          redirectTo(basePath);
        }
      }
    );
  }, [form]);

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const EditToolbar = props => {
  return <Toolbar {...props}>
    <CustomSave redirect="list" submitOnEnter={false} variant="text" />
    {props.record.name !== SUPER_ADMIN && <DeleteButton undoable={false}/>}
  </Toolbar>
};

const RoleEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source="name" />
        <ReferenceArrayInput
          label="Roles"
          reference="permissions"
          source="permissions"
          perPage={50}
        >
          <SelectArrayInput>
            <ChipField source="name" />
          </SelectArrayInput>
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default RoleEdit;
