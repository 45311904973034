/**
 * Creates initial mapping structure
 */
export const createMappingStructure = (shapes = null, sizes = null, colors = null, materials = null) => {
  const mapping = {};

  if (shapes) {
    mapping.shapes = shapes.map(shape => ({
      ...shape,
      id: `shape-${shape.id}`,
      type: 'shape',
      checked: true,
      children: sizes?.map(size => ({
        ...size,
        id: `${shape.id}-size-${size.id}`,
        type: 'size',
        checked: true,
      })) || []
    }));
  }

  if (materials) {
    mapping.materials = materials.map(material => ({
      ...material,
      id: `material-${material.ring_material_id}`,
      type: 'material',
      checked: true,
    }));
  }

  if (colors) {
    mapping.colors = colors.map(color => ({
      ...color,
      id: `color-${color.id}`,
      name: color.school_color_name || color.name,
      type: 'color',
      checked: true,
    }));
  }

  return mapping;
};

/**
 * Gets selected mappings
 */
export const getSelectedMappings = (mappingData) => {
  if (!mappingData) return [];

  const selectedMappings = [];
  const { shapes, materials, colors } = mappingData;

  const selectedMaterials = materials?.filter(m => m.checked) || [];
  const selectedColors = colors?.filter(c => c.checked) || [];

  shapes?.forEach(shape => {
    if (shape.checked) {
      shape.children?.forEach(size => {
        if (size.checked) {
          selectedMaterials.forEach(material => {
            selectedColors.forEach(color => {
              selectedMappings.push({
                key: `${shape.sku}-${material.id}-${color.id}-${size.sku}`,
                shape,
                size,
                material,
                color
              });
            });
          });
        }
      });
    }
  });

  // Sort by material name
  return selectedMappings.sort((a, b) => 
    (a.material?.name || '').localeCompare(b.material?.name || '')
  );
};

/**
 * Updates checked state for any mapping type
 */
export const updateMappingChecked = (mappingData, type, id, checked) => {
  switch (type) {
    case 'material':
      return {
        ...mappingData,
        materials: mappingData.materials?.map(material => 
          material.id === id ? { ...material, checked } : material
        )
      };
      
    case 'color':
      return {
        ...mappingData,
        colors: mappingData.colors?.map(color => 
          color.id === id ? { ...color, checked } : color
        )
      };
      
    case 'shape':
      return {
        ...mappingData,
        shapes: mappingData.shapes?.map(shape => 
          shape.id === id 
            ? updateNodeAndChildren(shape, checked)
            : shape
        )
      };

    case 'size':
      return {
        ...mappingData,
        shapes: mappingData.shapes?.map(shape => ({
          ...shape,
          children: shape.children?.map(size =>
            size.id === id ? { ...size, checked } : size
          )
        }))
      };
      
    default:
      return mappingData;
  }
};

const updateNodeAndChildren = (node, checked) => ({
  ...node,
  checked,
  children: node.children?.map(child => ({
    ...child,
    checked: checked ? child.checked : false
  }))
}); 