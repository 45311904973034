import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardHeader from '@material-ui/core/CardHeader';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    header: {
        margin: "1rem 1rem 0 1rem",
        padding: 0,
        "& .MuiCardHeader-title": {
            fontSize: "1rem",
        }
    },
}));

const GaSummary = ({ bounceRate, avgPagesPerSession, avgSessionDuration, totalUsers, totalNewUsers }) => {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader className={classes.header} title="From Google Analytics"/>
            <CardContent>
                <List className={classes.root}>
                    <ListItem>
                        <ListItemText primary={totalUsers} secondary="Total Visitors" />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                        <ListItemText primary={totalNewUsers} secondary="Total New Visitors" />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                        <ListItemText primary={`${bounceRate}%`} secondary="Avg. Bounce Rate" />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                        <ListItemText primary={avgPagesPerSession} secondary="Avg. no. of pages per session" />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem>
                        <ListItemText primary={`${avgSessionDuration}`} secondary="Avg. session duration" />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
}

export default GaSummary;