import React, { useState, useCallback } from "react";
import {
  SimpleForm,
  Create,
  useQuery,
  Error,
  AutocompleteInput,
  ImageField,
  ImageInput,
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
    ChipField,
  Notification
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Loader from "../Loader";
import { ThemeProvider } from "@material-ui/styles";
import customTheme from "../../customTheme";
import { validateRequired, validateProductDescription } from "../../utils/Validations";
import ChipsArray from "./ChipArray";
import CreateToolbar from "./CustomSave";

const useStyles = makeStyles(() => ({
  root: {
    width: "90%",
    margin: "auto"
  },
  grouping: {
    border: "1px solid lightgray",
    borderRadius: "3px",
    margin: "1% auto"
  },
  warnLabel: {
    fontSize: "10px",
    color: "red",
    display: "block"
  },
  displayFlex: {
    display: "flex"
  },
  equalWidth: {
    maxWidth: "33%"
  }
}));

const CreateProduct = () => {
  const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [chipData, setChipData] = useState([]);
  const handleChange = useCallback(newChecked => setChecked(newChecked), []);

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "ring/options",
    payload: { id: "" }
  });

  //page validations
  if (loading) return <Loader timedOut={loading} />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const optionRenderer = choice =>
    choice?.name ? `${choice.name} (${choice.sku_code})` : "None";

  return (
    <ThemeProvider theme={customTheme}>
      <Create resource="products" basePath="/products">
        <SimpleForm redirect="list" toolbar={<CreateToolbar chipData={chipData} action="create"/>}>
          <Grid
            container
            spacing={2}
            className={classes.root}
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <ReferenceArrayInput
                source="data.category_id"
                reference="products/categories"
                resource="products/categories"
                validate={validateRequired}
              >
                <SelectArrayInput
                    label="Category"
                    optionText="name"
                    optionValue="sku_code"
                    fullWidth
                    initialValue=""
                >
                  <ChipField source="name" />
                </SelectArrayInput>
              </ReferenceArrayInput>
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Profile"
                source="data.profile"
                choices={data.profile}
                optionText={optionRenderer}
                optionValue="sku_code"
                fullWidth
                initialValue=""
                validate={validateRequired}
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Width"
                source="data.width"
                choices={data.width}
                optionValue="sku_code"
                fullWidth
                initialValue=""
                validate={validateRequired}
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Ring Material"
                source="data.ring_material"
                choices={data.ring_material}
                optionText={optionRenderer}
                optionValue="sku_code"
                fullWidth
                initialValue=""
                validate={validateRequired}
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Ring Finish"
                source="data.ring_finish"
                choices={data.ring_finish}
                optionText={optionRenderer}
                optionValue="sku_code"
                fullWidth
                initialValue=""
                validate={validateRequired}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label="Default Size"
                source="data.size"
                fullWidth
                initialValue="10"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Outside Feature"
                source="data.feature_group"
                choices={data.feature_group}
                optionText={optionRenderer}
                optionValue="sku_code"
                fullWidth
                initialValue=""
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Sleeve"
                source="data.sleeve"
                choices={data.sleeve}
                optionText={optionRenderer}
                optionValue="sku_code"
                fullWidth
                initialValue=""
                allowEmpty
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Engraving"
                source="data.engraving"
                choices={data.engraving}
                optionText={optionRenderer}
                optionValue="sku_code"
                fullWidth
                initialValue=""
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Weight"
                source="data.weight"
                choices={data.weight}
                optionText={optionRenderer}
                fullWidth
                initialValue=""
                optionValue="sku_code"
                allowEmpty
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Finish"
                source="data.finish_type"
                choices={data.finish_type}
                optionText="name"
                fullWidth
                initialValue=""
                optionValue="sku_code"
                validate={validateRequired}
              />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label="Second Finish"
                source="data.second_finish"
                choices={data.second_finish}
                optionText="name"
                fullWidth
                initialValue=""
                optionValue="sku_code"
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={`${classes.grouping} ${classes.displayFlex}`}
            >
              {" "}
              <Grid
                item
                xs={4}
                className={`${classes.grouping} ${classes.equalWidth}`}
              >
                <AutocompleteInput
                  label="Inlay Style"
                  source="data.groove_type"
                  choices={data.groove_type}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                  allowEmpty
                />
                <AutocompleteInput
                  label="Inlay Width"
                  source="data.groove_group"
                  choices={data.groove_group}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                  allowEmpty
                />
                <AutocompleteInput
                  label="Inlay Material"
                  source="data.groove_inlays"
                  choices={data.groove_inlays}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                  allowEmpty
                />
                <AutocompleteInput
                  label="Inlay Finish"
                  source="data.groove_inlay_finish"
                  choices={data.groove_inlay_finish}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                />
                <AutocompleteInput
                  label="Accent Color"
                  source="data.mga"
                  choices={data.mga}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                />
              </Grid>
              <Grid
                item
                xs={4}
                className={`${classes.grouping} ${classes.equalWidth}`}
              >
                <AutocompleteInput
                  label="Design pattern"
                  source="data.machine_pattern"
                  choices={data.machine_pattern}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                  allowEmpty
                />
                <AutocompleteInput
                  label="Accent color"
                  source="data.machine_pattern_option"
                  choices={data.machine_pattern_option}
                  optionText="name"
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                />
              </Grid>
              <Grid
                item
                xs={4}
                className={`${classes.grouping} ${classes.equalWidth}`}
              >
                <AutocompleteInput
                  label="Gemstone Color"
                  source="data.gemstone"
                  choices={data.gemstone}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                  allowEmpty
                />
                <AutocompleteInput
                  label="Gemstone Shape"
                  source="data.gemstone_cut"
                  choices={data.gemstone_cut}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                  allowEmpty
                />
                <AutocompleteInput
                  label="Gemstone Setting Style"
                  source="data.gemstone_setting"
                  choices={data.gemstone_setting}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                  allowEmpty
                />
                <AutocompleteInput
                  label="Gemstone Size"
                  source="data.gemstone_dimension"
                  choices={data.gemstone_dimension}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                  allowEmpty
                />
                <AutocompleteInput
                  label="Gemstone Arrangement"
                  source="data.gemstone_arrangement"
                  choices={data.gemstone_arrangement}
                  optionText={optionRenderer}
                  fullWidth
                  initialValue=""
                  optionValue="sku_code"
                  allowEmpty
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              className={classes.displayFlex}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={5}>
                <TextInput
                  source="data.sku"
                  label="SKU"
                  validate={validateRequired}
                />
              </Grid>
              <Grid item xs={5}>
                <TextInput
                  source="data.wholesale_price"
                  label="Wholesale Price"
                  validate={validateRequired}
                />
              </Grid>
              <Grid item xs={2}>
                <BooleanInput
                  label={checked ? "Active" : "InActive"}
                  source="data.active"
                  initialValue={1}
                  onChange={handleChange}
                  checked={checked}
                  parse={v => (v ? 1 : 0)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={8}>
                <TextInput
                  source="data.description"
                  label="Description"
                  fullWidth
                  multiline
                  validate={validateProductDescription}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ChipsArray chipData={chipData} setChipData={setChipData} />
            </Grid>
            <Grid item xs={10}>
              <ImageInput
                source="data.pictures"
                label="Static Rings"
                accept="image/png"
                maxSize={1500000}
                validate={validateRequired}
                placeholder={
                  <p>
                    Upload Ring Image
                    <span className={classes.warnLabel}>
                      *File size should not exceed 1.5MB and file format must be png
                    </span>
                  </p>
                }
              >
                <ImageField source="src" title="images" />
              </ImageInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
      <Notification />
    </ThemeProvider>
  );
};

export default CreateProduct;
