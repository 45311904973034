import React, { useState } from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    BooleanInput,
    Loading,
    Toolbar, SaveButton, DeleteButton, AutocompleteInput, ArrayInput, SimpleFormIterator, NumberInput, SelectInput
} from "react-admin";
import Grid from '@material-ui/core/Grid';
import WithProps from "../Custom/WithProps";
import { makeStyles } from '@material-ui/core/styles';
import {
    validateRequired as validation,
    validateNumericRequired
} from "../../utils/Validations";
import {useLocation} from "react-router";
import queryString from 'qs';

const useStyles = makeStyles({
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    container: {
        width: '100%',
    },
    password: {
        margin: '10px 0',
        minHeight: '50px',
    },
    marginBounds: {
        marginTop: "20px"
    },
    formIterator: {
        "& ul": {
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
        },
    }
});

export const arrayInputStyles = makeStyles({
    root: {
        '& > li:last-child': {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    },
    indexContainer: {
        display: 'none'
    },
    form: { 
        display: 'flex',

        '& > div': {
            width: '100%',
            paddingRight: '20px'
        }
    },
    action: {
        display: 'flex',
    }
});

const StoreEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine} />
        {props.delete && <DeleteButton undoable={false}/>}
    </Toolbar>
);

export const StoreEdit = props => {
    const classes = useStyles();
    const arrayClasses = arrayInputStyles();
    const { search } = useLocation();
    const query = queryString.parse(search.slice(1));
    const {permissions} = props;
    const [isParent, toggleParent] = useState(query.p === "1");

    if (!permissions) return <Loading />;
    const permissionsList = permissions.split(",");

    const handleToggle = isParent => toggleParent(isParent);
    return (
        <Edit {...props}>
            <WithProps>
                {({record, ...props}) =>
                    (<SimpleForm record={record} {...props} undoable={false} toolbar={<StoreEditToolbar delete={permissionsList.includes("delete_stores")}/>}>
                            <Grid container className={classes.container} justify="space-between">
                                <Grid item xs={5}>
                                    <TextInput source="store_name" validate={validation} fullWidth/>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextInput source="store_number" validate={validation} fullWidth/>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextInput source="netsuite_id" validate={validateNumericRequired} fullWidth
                                               label="Netsuite ID"/>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextInput source="retail_multiplier" validate={validation} fullWidth/>
                                </Grid>
                                <Grid item container xs={5} alignItems="center">
                                    <Grid item>
                                        <BooleanInput source="is_parent_store" initialValue={query.p === "1"} onChange={handleToggle} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <ReferenceInput
                                        source="parent_store_id"
                                        reference="stores"
                                        filterToQuery={searchText => ({store_name: searchText, is_parent_store: true})}
                                        fullWidth
                                        disabled={isParent}
                                        format={v => isParent ? "" : v}
                                        validate={!isParent ? validation : null}
                                    >
                                        <AutocompleteInput optionText="store_name" optionValue="id" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={5}>
                                    <SelectInput
                                        source="currency"
                                        choices={[
                                            { id: 'USD', name: 'USD' },
                                            { id: 'CAD', name: 'CAD' },
                                        ]}
                                        validate={validation}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <ArrayInput source="retail_price_model">
                                        <SimpleFormIterator inline classes={{
                                            root: arrayClasses.root,
                                            form: arrayClasses.form,
                                            indexContainer: arrayClasses.indexContainer,
                                            action: arrayClasses.action
                                        }}>
                                            <NumberInput source="start" label="Start" validate={validation} fullWidth/>
                                            <NumberInput source="end" label="End" validate={validation} fullWidth/>
                                            <NumberInput source="multiplier" label="Multiplier" validate={validation} fullWidth/>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Grid>
                                <Grid item xs={9}>
                                    <ArrayInput source="precious_retail_price_model">
                                        <SimpleFormIterator inline classes={{
                                            root: arrayClasses.root,
                                            form: arrayClasses.form,
                                            indexContainer: arrayClasses.indexContainer,
                                            action: arrayClasses.action
                                        }}>
                                            <NumberInput source="start" label="Start" validate={validation} fullWidth/>
                                            <NumberInput source="end" label="End" validate={validation} fullWidth/>
                                            <NumberInput source="multiplier" label="Multiplier" validate={validation} fullWidth/>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Grid>
                                <Grid item xs={9}>
                                    <ArrayInput source="signet_retail_price_model">
                                        <SimpleFormIterator inline classes={{
                                            root: arrayClasses.root,
                                            form: arrayClasses.form,
                                            indexContainer: arrayClasses.indexContainer,
                                            action: arrayClasses.action
                                        }}>
                                            <NumberInput source="start" label="Start" validate={validation} fullWidth/>
                                            <NumberInput source="end" label="End" validate={validation} fullWidth/>
                                            <NumberInput source="multiplier" label="Multiplier" validate={validation} fullWidth/>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Grid>
                                <Grid item xs={9}>
                                    <ArrayInput source="signet_precious_retail_price_model">
                                        <SimpleFormIterator inline classes={{
                                            root: arrayClasses.root,
                                            form: arrayClasses.form,
                                            indexContainer: arrayClasses.indexContainer,
                                            action: arrayClasses.action
                                        }}>
                                            <NumberInput source="start" label="Start" validate={validation} fullWidth/>
                                            <NumberInput source="end" label="End" validate={validation} fullWidth/>
                                            <NumberInput source="multiplier" label="Multiplier" validate={validation} fullWidth/>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Grid>
                            </Grid>
                        </SimpleForm>)
                }
            </WithProps>
        </Edit>
)};