import React from "react";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer } from "recharts";
import CardWithIcon from '../CardWithIcon';
import {GRAPH_COLORS, labelFormatter} from "../Constants";

const StoreLogins = ({ net, trends, expanded = false }) => {
    return (
        expanded ?
            <ResponsiveContainer width="100%" height={250}>
                <LineChart width={500} data={trends}
                           margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, 'dataMax + 61']}/>
                    <Tooltip labelFormatter={labelFormatter} />
                    <Legend verticalAlign="top" />
                    <Line type="monotone" name="users" dataKey="logins" stroke={GRAPH_COLORS.line1} />
                </LineChart>
            </ResponsiveContainer>:
            <CardWithIcon
                to="/dashboard/site"
                icon={LockOpenIcon}
                title="Unique Store Users"
                subtitle={net}
            />
    )
};

export default StoreLogins;