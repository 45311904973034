import React from 'react';

const TextureFilter = ({ id, textureUrl }) => {
  return (
    <filter id={id} x="-50%" y="-50%" width="200%" height="200%">
      <feImage result="texture" href={textureUrl} x="0" y="0" width="100%" height="100%" preserveAspectRatio="none" />
      <feTile in="texture" result="tiledTexture" />
      <feComposite in="tiledTexture" in2="SourceGraphic" operator="in" />
    </filter>
  );
};

export default TextureFilter;
