import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    instructions: {
        '& li': {
            marginTop:'5px',
        }
    }
}));

export default () => {
    const classes = useStyles();

    return (
        <Card>
            <CardContent>
               <h3>How to Use:</h3>
                <ol className={classes.instructions}>
                    <li>
                        Use the template as a reference to import/upload new dynamic products. Make sure the format is correct.
                        <ul>
                            <li>Each row will add a new product to the application.</li>
                            <li>For multiple category association for any product make a new row entry with a different category association.</li>
                            <li>For incorrect spelling of category or any new category, the entry will get skipped.</li>
                            <li>For multiple entries of the same product the last “Active” status and “Description” will overwrite the entries updated before.</li>
                        </ul>
                    </li>
                    <li>Click on fetch price to update category page prices for newly added products.</li>
                    <li>For last 3 successful uploads you can download the files from the table below.</li>
                </ol>
            </CardContent>
        </Card>
    );
};
