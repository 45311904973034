/**
 * Collection of utilities for SVG manipulation
 */

/**
 * Applies color to SVG elements
 * @param {Element} element - SVG element to update
 * @param {string} color - Color to apply
 * @param {boolean} forceFill - Whether to force fill color
 */
export const updateElementColor = (element, color, forceFill = false) => {
  if (element.hasAttribute('fill') || forceFill) {
    element.setAttribute('fill', color);
  }
  if (element.hasAttribute('stroke')) {
    element.setAttribute('stroke', color);
  }

  if (element.hasAttribute('style')) {
    let style = element.getAttribute('style') || '';
    style = style.replace(/fill:\s*[^;]+/g, `fill: ${color}`);
    style = style.replace(/stroke:\s*[^;]+/g, `stroke: ${color}`);
    element.setAttribute('style', style);
  }
};

/**
 * Processes SVG content and applies initial color
 * @param {string} content - Raw SVG content
 * @param {string} color - Initial color to apply
 * @returns {string} Processed SVG content
 */
export const processSvgContent = (content, color, forceFill = false) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'image/svg+xml');

  // Generate a unique base ID
  

  // List of elements that can have ID references
  const elementsWithRefs = {
    'clipPath': ['clip-path', 'url(#', ')'],
    'mask': ['mask', 'url(#', ')'],
    'linearGradient': ['fill', 'url(#', ')'],
    'radialGradient': ['fill', 'url(#', ')'],
    'pattern': ['fill', 'url(#', ')'],
    'filter': ['filter', 'url(#', ')']
  };

  // Update IDs and their references for each type of element
  Object.entries(elementsWithRefs).forEach(([elementType, [attrName, urlPrefix, urlSuffix]]) => {
    const uniqueBase = `svg-${Math.random().toString(36).slice(2, 9)}`;
    const elements = doc.querySelectorAll(elementType);
    elements.forEach((element) => {
      const oldId = element.getAttribute('id');
      if (oldId) {
        const newId = `${uniqueBase}-${oldId}`;
        element.setAttribute('id', newId);

        // Update all elements that reference this ID
        const selector = `[${attrName}*="#${oldId}"]`;
        doc.querySelectorAll(selector).forEach((refElement) => {
          const attrValue = refElement.getAttribute(attrName);
          if (attrValue) {
            const newValue = attrValue.replace(
              `${urlPrefix}${oldId}${urlSuffix}`,
              `${urlPrefix}${newId}${urlSuffix}`
            );
            refElement.setAttribute(attrName, newValue);
          }
        });

        // Also check style attribute for references
        doc.querySelectorAll(`[style*="#${oldId}"]`).forEach((refElement) => {
          const style = refElement.getAttribute('style');
          const newStyle = style.replace(
            `${urlPrefix}${oldId}${urlSuffix}`,
            `${urlPrefix}${newId}${urlSuffix}`
          );
          refElement.setAttribute('style', newStyle);
        });
      }
    });
  });

  // Apply colors to elements
  ['g', 'path', 'circle', 'rect', 'ellipse', 'line', 'polyline', 'polygon'].forEach((tag) => {
    doc.querySelectorAll(tag).forEach((element) => updateElementColor(element, color, forceFill));
  });

  return new XMLSerializer().serializeToString(doc);
};

/**
 * Extracts attributes from SVG element
 * @param {string} content - SVG content
 * @returns {Object} SVG attributes
 */
export const extractSvgAttributes = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'image/svg+xml');
  const svgElement = doc.querySelector('svg');

  if (!svgElement) return {};

  return Array.from(svgElement.attributes).reduce((acc, attr) => {
    acc[attr.name] = attr.value;
    return acc;
  }, {});
};
