import React, { Component } from "react";
import { UserMenu, MenuItemLink } from "react-admin";
import Person from "@material-ui/icons/Person";

class CustomUserMenu extends Component {
  render() {
    return (
      <UserMenu {...this.props}>
        <MenuItemLink
          to="/profile"
          primaryText="My profile"
          leftIcon={<Person />}
        />
      </UserMenu>
    );
  }
}

export default CustomUserMenu;
