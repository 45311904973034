import { Filter, TextInput } from "react-admin";
import React from "react";

export const StoreFilter = props => {
    return (
        <Filter {...props}>
            <TextInput source="name" label="Name" defaultValue="" />
            {!props.hideSkuCode && <TextInput source="sku_code" label="Sku Code" defaultValue="" />}
        </Filter>
    );
}

export const Filters = (hideSkuCode=false) => {
    const list = [
        <TextInput source="name" label="Name" defaultValue="" />
    ]
    if (!hideSkuCode) {
        list.push(<TextInput source="sku_code" label="Sku Code" defaultValue="" />)
    }
    return list
}