import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import { ThemeProvider } from '@material-ui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';
import customTheme from '../../../customTheme';
import { useSignetStudio } from './SignetStudioContext';
import LazyMasonryGrid from './components/LazyMasonryGrid';
import { useImageProcessing } from './hooks/useImageProcessing';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: 'calc(100% - 40px)',
    maxWidth: 'calc(100vw - 240px)',
    overflowX: 'hidden',
  },
  backButton: {
    marginBottom: theme.spacing(3),
  },
  summaryCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.divider}`,
    maxWidth: 600,
    position: 'relative',
  },
  editButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  summaryContent: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
  svgPreview: {
    width: 80,
    height: 80,
    padding: theme.spacing(1),
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
  },
  summaryInfo: {
    flex: 1,
    paddingRight: theme.spacing(2.5),
  },
  schoolInfo: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
    fontSize: '0.875rem',
  },
  variantsCount: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  dialogContent: {
    minWidth: 300,
  },
  variationDetails: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  detailsTable: {
    width: '100%',
    borderCollapse: 'collapse',
    '& th': {
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontSize: '0.875rem',
      textAlign: 'center',
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& td': {
      color: theme.palette.text.primary,
      fontSize: '0.875rem',
      paddingTop: theme.spacing(1),
      textAlign: 'center',
    },
  },
  tabsContainer: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiTabs-scroller': {
      overflow: 'hidden !important',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },
    width: '100%',
  },
  tab: {
    minWidth: 120,
    '&.Mui-selected': {
      fontWeight: 500,
    },
  },
  uploadSection: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  uploadInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  uploadButton: {
    minWidth: 120,
  },
  processingStatus: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const EngravingMappingView = () => {
  const classes = useStyles();
  const history = useHistory();
  const notify = useNotify();
  const { state, selectedMappings } = useSignetStudio();
  const { processing, progress, processVariations } = useImageProcessing();
  const [deleteDialog, setDeleteDialog] = useState({ open: false, index: null });
  const [selectedTab, setSelectedTab] = useState(() => {
    return state.mappingData?.shapes?.find((shape) => shape.checked)?.id || 0;
  });
  const [showPathPreview, setShowPathPreview] = useState(true);
  const [isCapturingImage, setIsCapturingImage] = useState(false);
  const [isProcessingQueued, setIsProcessingQueued] = useState(false);
  const [deletedVariants, setDeletedVariants] = useState(new Set());

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const previewUrl = state.svgFile ? URL.createObjectURL(state.svgFile) : null;
  const selectedFilteredMappings = useMemo(() => {
    return selectedMappings.filter((mapping) => mapping.shape.id === selectedTab);
  }, [selectedMappings, selectedTab]);

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleBack = () => {
    history.push('/signet-studio');
  };

  const handleDeleteClick = (variant) => {
    setDeleteDialog({ open: true, variant });
  };

  const handleDeleteConfirm = () => {
    if (deleteDialog.variant) {
      // Keep this variantKey consistent as this is used to identify total remaining variations.
      const variantKey = `${deleteDialog.variant.shape.sku}-${deleteDialog.variant.material.sku_code}-${deleteDialog.variant.color.sku}-${deleteDialog.variant.size.sku}`;
      setDeletedVariants((prev) => new Set([...prev, variantKey]));
    }
    setDeleteDialog({ open: false, variant: null });
  };

  // Handle state changes before processing
  useEffect(() => {
    if (isProcessingQueued && isCapturingImage && !showPathPreview) {
      const processImages = async () => {
        try {
          console.log('Processing variations after state updates...');
          await processVariations();
          notify('Successfully converted all variations', { type: 'success' });
        } catch (error) {
          console.error('Error processing variations:', error);
          notify('Error processing variations', { type: 'error' });
        } finally {
          setIsCapturingImage(false);
          setShowPathPreview(true);
          setIsProcessingQueued(false);
        }
      };

      processImages();
    }
  }, [isProcessingQueued, isCapturingImage, showPathPreview]);

  const handleProcessVariations = async () => {
    const currentShape = state.mappingData?.shapes?.find((shape) => shape.id === selectedTab);

    if (!currentShape) {
      console.warn('No shape selected or grid ref not available');
      return;
    }

    try {
      // Set states and queue processing
      setIsCapturingImage(true);
      setShowPathPreview(false);
      setIsProcessingQueued(true);
    } catch (error) {
      console.error('Error in handleProcessVariations:', error);
      notify('Error preparing variations', { type: 'error' });
      // Reset states on error
      setIsCapturingImage(false);
      setShowPathPreview(true);
      setIsProcessingQueued(false);
    }
  };

  const handlePathPreviewToggle = (event) => {
    setShowPathPreview(event.target.checked);
  };

  // Add this memoized value to calculate active variations
  const activeVariationsCount = useMemo(() => {
    const deletedForCurrentShape = Array.from(deletedVariants).filter(
      (variantKey) =>
        variantKey.startsWith(selectedTab + '-') || // Assuming shape.id is at start of key
        variantKey.startsWith(`${state.mappingData?.shapes?.find((s) => s.id === selectedTab)?.sku}-`), // Or using shape.sku
    ).length;

    return selectedFilteredMappings.length - deletedForCurrentShape;
  }, [selectedFilteredMappings.length, deletedVariants, selectedTab, state.mappingData?.shapes]);

  if (!state.selectedEngraving) {
    return (
      <ThemeProvider theme={customTheme}>
        <div className={classes.root}>
          <Button startIcon={<ArrowBackIcon />} onClick={handleBack} className={classes.backButton}>
            Back to Studio
          </Button>
          <Typography variant="h6" align="center">
            No engraving data available. Please go back and select an engraving.
          </Typography>
        </div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={customTheme}>
      <div className={classes.root}>
        <Button startIcon={<ArrowBackIcon />} onClick={handleBack} className={classes.backButton}>
          Back to Studio
        </Button>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
          <Paper className={classes.summaryCard}>
            <Tooltip title="Edit Selection" placement="top">
              <IconButton className={classes.editButton} size="small" onClick={handleBack}>
                <EditIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <div className={classes.summaryContent}>
              <div className={classes.svgPreview}>{previewUrl && <img src={previewUrl} alt="SVG Preview" />}</div>
              <div className={classes.summaryInfo}>
                <Typography variant="h6">{String(state.selectedEngraving.name)}</Typography>
                {state.selectedEngraving.school && (
                  <Typography variant="subtitle1" className={classes.schoolInfo}>
                    {String(state.selectedEngraving.school)}
                  </Typography>
                )}
                <Typography className={classes.variantsCount}>
                  {selectedMappings?.length - deletedVariants?.size} variations created
                </Typography>
              </div>
            </div>
          </Paper>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" color="textSecondary">
              Show Shape Preview
            </Typography>
            <Switch checked={showPathPreview} onChange={handlePathPreviewToggle} color="primary" size="small" />
          </Box>
        </Box>
        <div className={classes.tabsContainer}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabs}
            aria-label="material tabs"
          >
            {state.mappingData?.shapes
              ?.filter((shape) => shape.checked)
              .map((shape) => (
                <Tab key={shape.id} label={shape.name} className={classes.tab} value={shape.id} />
              ))}
          </Tabs>
        </div>

        <div className={classes.accordionContainer}>
          <LazyMasonryGrid
            items={selectedFilteredMappings}
            svgFile={state.svgFile}
            onDelete={handleDeleteClick}
            showPathPreview={showPathPreview}
            isCapturingImage={isCapturingImage}
            deletedVariants={deletedVariants}
          />
          {processing && (
            <div className={classes.processingStatus}>
              <LinearProgress variant="determinate" value={(progress.current / progress.total) * 100} />
              <Box mt={1}>
                <Typography variant="body2" color="textSecondary">
                  {progress.message}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {progress.stage === 'converting' ? 'Converting SVGs to PNGs' : 'Uploading files'}
                </Typography>
              </Box>
            </div>
          )}
          <div className={classes.uploadSection}>
            <div className={classes.uploadInfo}>
              <Typography variant="subtitle1">Ready to process {activeVariationsCount} variations</Typography>
              <Typography variant="body2" color="textSecondary">
                {state.mappingData?.shapes?.find((shape) => shape.id === selectedTab)?.name} shape variations will be
                processed
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.uploadButton}
              onClick={handleProcessVariations}
              disabled={processing || activeVariationsCount === 0}
              startIcon={processing ? <CircularProgress size={20} /> : <CloudUploadIcon />}
            >
              {processing ? 'Processing...' : 'Process Images'}
            </Button>
          </div>
        </div>

        <Dialog
          open={deleteDialog.open}
          onClose={() => setDeleteDialog({ open: false, index: null })}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="delete-dialog-title">Delete Variation</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {deleteDialog?.variant !== null && (
              <>
                <Typography variant="body1">Are you sure you want to delete this variation?</Typography>
                <Box className={classes.variationDetails}>
                  <table className={classes.detailsTable}>
                    <thead>
                      <tr>
                        <th>Shape</th>
                        <th>Material</th>
                        <th>Color</th>
                        <th>Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{deleteDialog?.variant?.shape?.name}</td>
                        <td>{deleteDialog?.variant?.material?.name}</td>
                        <td>{deleteDialog?.variant?.color?.name}</td>
                        <td>{deleteDialog?.variant?.size?.name}</td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog({ open: false, index: null })} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="secondary" variant="contained" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default EngravingMappingView;
