import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import VariantCard from './VariantCard';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  materialSection: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
    gap: theme.spacing(2),
    width: '100%',
    padding: theme.spacing(1, 0),
  },
  largeItem: {
    gridColumn: 'span 2',
    gridRow: 'span 2',
    height: '100%',
    '& $materialSection': {
      height: '100%',
    },
  },
}));

const LazyMasonryGrid = ({ items, svgFile, onDelete, showPathPreview, isCapturingImage, deletedVariants }) => {
  const classes = useStyles();

  // Filter out deleted variants before grouping
  const filteredItems = items.filter((item) => {
    const variantKey = `${item?.shape?.sku}-${item?.material?.sku_code}-${item?.color?.sku}-${item?.size?.sku}`;
    return !deletedVariants.has(variantKey);
  });

  // Group filtered items by material
  const groupedItems = filteredItems.reduce((acc, item) => {
    const materialId = item.material.id;
    if (!acc[materialId]) {
      acc[materialId] = {
        material: item.material,
        items: [],
      };
    }
    acc[materialId].items.push(item);
    return acc;
  }, {});

  return (
    <div className={classes.gridContainer}>
      {Object.entries(groupedItems).map(([materialId, { items }]) => (
        <div key={materialId} className={classes.materialSection}>
          {items?.map((mapping, index) => (
            <div className={index === 0 ? classes.largeItem : undefined} key={index}>
              <VariantCard
                mapping={mapping}
                svgFile={svgFile}
                onDelete={() => onDelete(mapping)}
                isLarge={index === 0}
                showPathPreview={showPathPreview}
                className="variant-svg"
                isCapturingImage={isCapturingImage}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default LazyMasonryGrid;
