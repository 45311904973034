import React, { useEffect } from 'react'
import Grid from "@material-ui/core/Grid";
import CustomSchoolSelect from '../CustomSchoolSelect';
import { NumberInput, useInput } from 'react-admin';
import { SignetRingAttributeTypeResources, SignetRingMaterialResources, SignetSignetInlayResources, SignetSizeResources } from '../util';
import { validateNumberGreaterThanZero, validateRequired } from '../../../utils/Validations';
import CustomSelect from './CustomSelect';

const PriceForm = ({ isEdit = false }) => {

    const { input } = useInput({ source: 'attribute_type_name' })

    const { input: InputAttributeId } = useInput({
        source: 'attribute_id', initialValue: 0
    })


    const isInlay = [0, 2].includes(InputAttributeId.value);

    useEffect(() => {
      
        if (typeof input.value === 'string' && input.value !== 'Inlay' && input.value !== 'Live Tool/Laser') {
            InputAttributeId.onChange(0)
        }

        if (typeof input.value === 'string' && input.value === 'Live Tool/Laser') {
            InputAttributeId.onChange(2)
        }

        if (input.value === 'Inlay' && !isInlay) {
            InputAttributeId.onChange(InputAttributeId.value)
        }
        if (input.value === 'Inlay' && isInlay) {
            InputAttributeId.onChange(1)
        }
    }, [input.value])


    return (
        <>
            <Grid item xs={5}>
                <p className="label">Attribute Type Name</p>
                <CustomSchoolSelect isDisabled={isEdit} isEdit={isEdit} idsource='attribute_type_id' source="attribute_type_name" label="Attribute Type Name" resource={SignetRingAttributeTypeResources} validate={[validateRequired]} fullWidth />
            </Grid>
            <Grid item xs={5}>
                <p className="label">Ring Material Name</p>
                <CustomSchoolSelect isDisabled={isEdit} isEdit={isEdit} idsource='ring_material_id' source="ring_material_name" label="Ring Material Name" resource={SignetRingMaterialResources} validate={[validateRequired]} fullWidt />
            </Grid>

            <Grid item xs={5}>
                <NumberInput label="Amount" source="amount" defaultValue="1" validate={[validateRequired, validateNumberGreaterThanZero]} fullWidth />
            </Grid>

            {input.value !== "Live Tool/Laser" ?
                <Grid item xs={5}>
                    <p className="label">Size</p>
                    <CustomSchoolSelect isEdit={isEdit} isDisabled={isEdit} idsource='size_id' source="size_name" resource={SignetSizeResources} fullWidth />
                </Grid> : <Grid item xs={5} />
            }

            {!isInlay ? <Grid item xs={5}>
                <p className="label">Inlay</p>
                <CustomSelect isEdit={isEdit} isDisabled={isEdit} idsource={isEdit ? 'inlay_type_id' : 'attribute_id'} source={isEdit ? 'attribute_id' : "Inlay"} resource={SignetSignetInlayResources} validate={[validateRequired]} fullWidth />
            </Grid> : <Grid item xs={5} />}
        </>
    )
}


export default PriceForm