import React, { useCallback, useEffect, useState } from "react";
import { NumberInput, TextInput, useInput, useRedirect, useRefresh, useUpdate } from "react-admin";
import AsyncSelect from "react-select/async";
import axios from 'axios';
import { apiUrl } from "../../dataProvider";
import { FormHelperText, Button, Box, Typography, Button as MUIButton } from '@material-ui/core';
import { SaveButton, Toolbar, useNotify, useCreate } from "react-admin";
import { useForm } from 'react-final-form';
import Grid from "@material-ui/core/Grid";
import './style.css';
import { CUSTOM_RESOURCES, DESIGN_TYPE } from "./type";
import { validateRequired } from "../../utils/Validations";
import { da } from "date-fns/locale";


export const CustomSelect = ({ label, isEdit, isMulti = false, validate = [], query = {}, pageSize = 1000, paramId, idsource = '', isDisabled = false, source, resource, defaultValue = null, ...props }) => {
    const [list, setList] = useState(
        {
            data: [],
            raw: [],
        }
    );
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);

    const { input, isRequired,
        meta: { touched, error, }
    } = useInput({ source, validate, defaultValue })

    const { input: inputSourceId } = useInput({ source: idsource })

    const getLists = (params) => {
        const url = paramId ? `${apiUrl}/${resource}/${paramId}` : `${apiUrl}/${resource}`;
        return axios.get(url, { params }).then(({ data }) => data.data)
    }


    const filterOptions = (value) => {
        const filterData = list.raw.filter(item => item.name.toLocaleLowerCase().includes(value.toLowerCase()) || item.sku_code.toLocaleLowerCase().includes(value.toLowerCase()));
        setList(prev => ({ ...prev, data: filterData }))

        return filterData;
    }

    const getSearchOptions = (value, callback) => {

        setTimeout(() => {
            callback(filterOptions(value));
        }, 500);
    };

    const setStoreList = useCallback(async (params) => {
        try {
            setLoading(true);
            const response = await getLists(params);
            setLoading(false);
            setList({ data: response, raw: response });
        } catch ({ response }) {
            setLoading(false);

            const status = response?.status;
            if (status === 401 || status === 403) {
                window.location.replace('/#/login');
            }
        } finally {
            setLoading(false);
        }

    }, [paramId])

    /** This effect calls initial load with  and when you clear value  */
    useEffect(() => {
        if (list.raw.length === 0) {
            setStoreList(
                {
                    'page[number]': 1,
                    'page[size]': pageSize,
                    ...query
                }
            );
        }
    }, [list.raw.length, setStoreList, paramId])


    const handleChange = callback => selectedopt => {
        if (idsource) {
            inputSourceId.onChange(selectedopt?.name)
        }
        if(isMulti) {
            callback(selectedopt.map(item => item.sku_code));
        } else {
            callback(selectedopt?.sku_code);
        }
    }

    return (
        <div style={{ marginBottom: '4px'}}>
            {label ? <p className="label">{label} {isRequired ? '*' : ''}</p> : null}
            <AsyncSelect
                {...input}
                isClearable
                cacheOptions
                styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                }}
                isMulti={isMulti}
                isLoading={loading}
                inputValue={searchValue}
                defaultOptions={list.data}
                isDisabled={isDisabled}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.sku_code || e.id}
                loadOptions={getSearchOptions}
                value={list.data.find(item => item.sku_code == input.value || item.id == input.value || item.sku_code == input.value?.feature_group)} // value must be object, so get selected opt from input.value
                onChange={handleChange(input.onChange)}
                onInputChange={(value) => {
                    setSearchValue(value)
                }}
            />
            {touched && error ? <FormHelperText error>{error === 'ra.validation.required' ? "Required" : error}</FormHelperText> : null}
        </div>
    )
}


function getKeysWithSimilarName({ obj, substring }) {
    return Object.keys(obj)
        .filter(key => key.includes(substring))
        .reduce((acc, key) => {
            acc[key] = obj[key];
            return acc;
        }, {});
}

const getGroupData = (data) => {

    const groupedData = {};

    // Iterate over each entry in the input data
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            // Split the key to extract index and property name
            const [index, prop] = key.split('@');
            if (!groupedData[index]) {
                groupedData[index] = {};
            }
            groupedData[index][prop] = data[key];
        }
    }

    return groupedData;
}

const getOutsideFeature = (data) => {
    const list = data.map(item => {
        if(item.feature_group === 'N') {
            return null;
        } else if (item.feature_group === 'G' || item.feature_group === 'CG') {
            return {
                feature_group: item.feature_group,
                inlay_material: item.inlay_material,
                inlay_width: item.inlay_width,
                inlay_finish: item.inlay_finish,
                mga: item.mga,
                groove_group: item.groove_group,
                inlay_qty: item.inlay_qty,
                inlay_thickness: item.inlay_thickness,
                inlay_volume: item.inlay_volume,
            }
        } else if (item.feature_group === 'M') {
            return {
                feature_group: item.feature_group,
                machine_pattern: item.machine_pattern,
                cerakote_color: item.cerakote_color,
                ring_material: item.ring_material,
            }
        } else if (item.feature_group === 'A') {
            return {
                feature_group: item.feature_group,
                cerakote: item.cerakote,
            }
        } else if (item.feature_group === 'D') {
            return {
                feature_group: item.feature_group,
                gemstone_arrangement: item.gemstone_arrangement,
                gemstone_material: item.gemstone_material,
                quantity: item.quantity,
                gemstone_cut: item.gemstone_cut,
                gemstone_setting: item.gemstone_setting,
                gemstone_size: item.gemstone_size,
            }
        }
    });

    const filteredList = list.filter(item => item !== null);
    return filteredList;
}

const getEngraving = (data) => {
    if(data?.type === 'NONE') {
        return null;
    } else if(data?.type === 'D') {
        return {
            type: data.type,
            design_pattern: data.design_pattern,
            ring_material: data.ring_material,
        }
    }
    return null;
}


const CustomSave = ({ handleSubmitWithRedirect, resource, setDatasource, datasource
    , ...props }) => {
    const notify = useNotify();
    const form = useForm();
    const refresh = useRefresh();
    const redirect = useRedirect();


    const [create] = useCreate(resource);
    const [update]  = useUpdate(resource, props.record.id);

    const handleClick = useCallback(async (type = 'price') => {

        try {
            const values = form.getState().values;
            values.outside_feature = getOutsideFeature(values.outside_feature);
            values.engraving = getEngraving(values.engraving);
            if(!values.ring_finish?.[0]) values.ring_finish = [];

            console.log(values);
            setDatasource((prev) => ({ ...prev, loading: true }))

            if (type === 'save') {
                const callback = values.id ? update : create;
                callback(
                    {
                        payload: {
                            data: {
                                data: values,
                                price: datasource.data,
                                final_price: datasource.data.price
                            }
                        },
                    },
                    {
                        onSuccess: ({ data }) => {
                            notify(data.message, { type: 'success' });
                            setDatasource((prev) => ({ ...prev, loading: false, data: null }))
                            redirect(`/${resource}`);
                        },
                        onFailure: ({ response: { data: { errors } } }) => {
                            if ('message' in errors) {
                                notify(errors.message, { type: 'warning' });
                                return;
                            }
                            setDatasource((prev) => ({ ...prev, loading: false }))
                            const message = errors[Object.keys(errors)[0]][0];
                            notify(message, { type: 'warning' });
                        },
                    }
                );
            } else {
                const res = await axios.post(`${apiUrl}/getPrice`, values)
                setDatasource((prev) => ({ ...prev, loading: false, data: res.data }))

                notify("Price request Successfull", "success", 2000);
            }

        } catch (errors) {
            setDatasource((prev) => ({ ...prev, loading: false }))
            notify("Combination not found.", "error", 2000);
        }

    }, [form, datasource]);

    return (
        <Box>
            <Button style={{
                marginLeft: 10,
                marginRight: 10,
            }}
                variant='text'
                disabled={!form.getState().dirty}
                onClick={() => {
                    refresh()
                    setDatasource((prev) => ({
                        ...prev, data: null, featureGroupCount: 1,
                        additionalPrice: 1,
                    }))

                }}>
                Reset
            </Button>
            <SaveButton  {...props} handleSubmitWithRedirect={handleClick} label="Price" disabled={form.getState().hasValidationErrors} />
            <SaveButton {...props} handleSubmitWithRedirect={() => handleClick('save')} label="Save" disabled={form.getState().hasValidationErrors || datasource.data === null} />

        </Box>)
};

export const Save = props => {
    return (
        <Toolbar {...props}>
            <CustomSave
                redirect="list"
                submitOnEnter={false}
                variant="text"
                {...props}
            />
        </Toolbar>
    );
}

export const InlayType = ({ type, disabled = false, val = null, count, ...props }) => {

    const source = `outside_feature.${count}`

    const { input } = useInput({ source: `${source}.feature_group` })

    useEffect(() => {
        if (!val) {
            input.onChange('N')
        }
    }, [val])

    console.log('>>>', input.value);
    return (
        < >
            <Grid item xs={12} style={{ paddingTop: 15 }}>
                <CustomSelect label="" isDisabled={disabled} idsource={`${source}.feature_group`} source={`${source}.feature_group`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/feature-groups"]} />
            </Grid>

            {(input.value === 'G' || input.value === 'CG') &&
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 10
                }}   >
                    <Grid item xs={5}>
                        <CustomSelect label="Inlay Material" isDisabled={disabled} source={`${source}.inlay_material`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/inlays"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <NumberInput disabled={disabled} source={`${source}.inlay_width`} defaultValue={null} validate={[validateRequired]} label="Inlay Width" fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Inlay Finish" isDisabled={disabled} source={`${source}.inlay_finish`} resource={CUSTOM_RESOURCES["custom-pricing/finishes"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Mga Color Option" isDisabled={disabled} source={`${source}.mga`} resource={CUSTOM_RESOURCES['custom-pricing/mga-color-options']} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Groove Group" isDisabled={disabled} source={`${source}.groove_group`} resource={CUSTOM_RESOURCES['custom-pricing/groove-groups']} />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput disabled={disabled} source={`${source}.inlay_qty`} defaultValue={null} validate={[validateRequired]} label="Inlay Quantity" fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source={`${source}.inlay_thickness`} defaultValue={null} disabled={disabled} label="Inlay Thickness" fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source={`${source}.inlay_volume`} defaultValue={null} disabled={disabled} label="Inlay Volume" fullWidth />
                    </Grid>
                </Box>
            }
            {input.value === 'M' &&
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 10
                }}   >
                    <Grid item xs={5}>
                        <CustomSelect label="Machine Pattern" isDisabled={disabled} source={`${source}.machine_pattern`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/machine-patterns"]} />
                    </Grid>

                    <Grid item xs={5}>
                        <CustomSelect label="Cerakote Color" isDisabled={disabled} source={`${source}.cerakote_color`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/machine-pattern-color-options"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Ring Material" isDisabled={disabled} source={`${source}.ring_material`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/ring-materials"]} />
                    </Grid>
                </Box>
            }

            {input.value === 'A' &&
                <>
                    <Grid item xs={5}>
                        <CustomSelect label="All Cerakote Option" isDisabled={disabled} source={`${source}.cerakote`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/all-cerakote-options"]} />
                    </Grid>
                </>
            }

            {input.value === "D" &&
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 10
                }}   >
                    <Grid item xs={5}>
                        <CustomSelect label="Gemstone Arrangement" isDisabled={disabled} source={`${source}.gemstone_arrangement`} resource={CUSTOM_RESOURCES["custom-pricing/gemstone-arrangements"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <p className="label"></p>
                        <CustomSelect label="Gemstone Material" isDisabled={disabled} source={`${source}.gemstone_material`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/gemstones"]} />
                    </Grid>

                    <Grid item xs={5}>
                        <TextInput disabled={disabled} source={`${source}.quantity`} defaultValue={null} label="Quantity" fullWidth />
                    </Grid>

                    <Grid item xs={5}>
                        <CustomSelect label="Gemstone Cut" isDisabled={disabled} source={`${source}.gemstone_cut`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/gemstone-cuts"]} />
                    </Grid>

                    <Grid item xs={5}>
                        <CustomSelect label="Gemstone Settings" isDisabled={disabled} source={`${source}.gemstone_setting`} resource={CUSTOM_RESOURCES["custom-pricing/gemstone-settings"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Gemstone Size" isDisabled={disabled} source={`${source}.gemstone_size`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/gemstone-dimensions"]} />
                    </Grid>
                </Box>
            }
        </>)

}

export const InlayTypeShow = ({ type, disabled = false, val = null, count, ...props }) => {
    const source = `outside_feature.${count}`
    return (
        < >
            <Grid item xs={12} style={{ paddingTop: 15 }}>
                <CustomSelect label="" isDisabled={disabled} source={`${source}.feature_group`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/feature-groups"]} />
            </Grid>

            {val.feature_group === 'G' &&
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 10
                }}   >
                    <Grid item xs={5}>
                        <CustomSelect label="Width" isDisabled={disabled} source={`${source}.inlay_width`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/widths"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput disabled={disabled} source={`${source}.inlay_qty`} defaultValue={null} validate={[validateRequired]} label="Inlay Quantity" fullWidth />

                    </Grid>

                    <Grid item xs={5}>
                        <p className="label"></p>
                        <CustomSelect label="Inlay Material" isDisabled={disabled} source={`${source}.inlay_material`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/inlays"]} />
                    </Grid>

                    <Grid item xs={5}>
                        <p className="label"></p>
                        <CustomSelect label="Mga Color Option" isDisabled={disabled} source={`${source}.mga`} resource={CUSTOM_RESOURCES['custom-pricing/mga-color-options']} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Groove Group" isDisabled={disabled} source={`${source}.groove_group`} resource={CUSTOM_RESOURCES['custom-pricing/groove-groups']} />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source={`${source}_inlay_thickness`} defaultValue={null} disabled={disabled} label="Inlay Thickness" fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <TextInput source={`${source}_inlay_volume`} defaultValue={null} disabled={disabled} label="Inlay Volume" fullWidth />
                    </Grid>

                </Box>
            }
            {val.feature_group === 'M' &&
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 10
                }}   >
                    <Grid item xs={5}>
                        <p className="label"></p>
                        <CustomSelect label="Machine Pattern" isDisabled={disabled} source={`${source}.machine_pattern`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/machine-patterns"]} />
                    </Grid>

                    <Grid item xs={5}>
                        <CustomSelect label="Cerakote Color" isDisabled={disabled} source={`${source}.cerakote_color`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/machine-pattern-color-options"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Ring Material" isDisabled={disabled} source={`${source}.ring_material`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/ring-materials"]} />
                    </Grid>
                </Box>
            }

            {val.feature_group === "A" &&
                <>
                    <Grid item xs={5}>
                        <CustomSelect label="All Cerakote Option" isDisabled={disabled} source={`${source}.cerakote`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/all-cerakote-options"]} />
                    </Grid>
                </>
            }

            {val.feature_group === "D" &&
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 10
                }}   >
                    <Grid item xs={5}>
                        <p className="label"></p>
                        <CustomSelect label="Gemstone Arrangement" isDisabled={disabled} source={`${source}.gemstone_arrangement`} resource={CUSTOM_RESOURCES["custom-pricing/gemstone-arrangements"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Gemstone Material" isDisabled={disabled} source={`${source}.gemstone_material`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/gemstones"]} />
                    </Grid>

                    <Grid item xs={5}>
                        <TextInput disabled={disabled} source={`${source}.quantity`} defaultValue={null} label="Quantity" fullWidth />
                    </Grid>

                    <Grid item xs={5}>
                        <CustomSelect label="Gemstone Cut" isDisabled={disabled} source={`${source}.gemstone_cut`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/gemstone-cuts"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Gemstone Settings" isDisabled={disabled} source={`${source}_gemstone-settings`} resource={CUSTOM_RESOURCES["custom-pricing/gemstone-settings"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Gemstone Size" isDisabled={disabled} source={`${source}_gemstone-dimensions`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/gemstone-dimensions"]} />
                    </Grid>
                </Box>
            }
        </>)

}


export const EngravingType = ({ disabled = false, value = null, ...props }) => {

    const source = 'engraving';
    const { input } = useInput({ source: `${source}.type` })

    useEffect(() => {
        if (!value) {
            input.onChange('NONE')
        } else {
            input.onChange('D')
        }
    }, [value])

    return (
        <>
            <Grid item xs={12}>
                <CustomSelect label="Engraving Option" isDisabled={disabled} idsource={`${source}.type`} source={`${source}.type`} resource={CUSTOM_RESOURCES["custom-pricing/engraving-options"]} />
            </Grid>

            {input.value === 'D' ?
                <>
                    <Grid item xs={5}>
                        <CustomSelect label="Machine Pattern" isDisabled={disabled} source={`${source}.design_pattern`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/machine-patterns"]} />
                    </Grid>
                    <Grid item xs={5}>
                        <CustomSelect label="Ring Material" isDisabled={disabled} source={`${source}.ring_material`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/ring-materials"]} />
                    </Grid>
                </>
            : null}
        </>)
}

export const EngravingTypeShow = ({ disabled = false, val, ...props }) => {

    const source = `engraving`
    return (
        <>
            <Grid item xs={12}>
                <p className="label">Engraving Option</p>
            </Grid>

            <Grid item xs={5}>
                <CustomSelect label="Machine Pattern" isDisabled={disabled} source={`${source}.design_pattern`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/machine-patterns"]} />
            </Grid>
            <Grid item xs={5}>
                <CustomSelect label="Ring Material" isDisabled={disabled} source={`${source}.ring_material`} validate={[validateRequired]} resource={CUSTOM_RESOURCES["custom-pricing/ring-materials"]} />
            </Grid>
        </>)
}

export const PERMISSION_LIST_SIGNET = {
    delete_data: 'delete_data',
    edit_data: 'edit_data',
    view_data: 'view_data',
    add_data: 'add_data',
}

export const RingsResources = 'nc-rings';

export const AdditionalPriceButton = ({ setDatasource, datasource, disabled =false }) => {
    const form = useForm();


    return (<Box sx={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        <MUIButton
            variant="contained"
            disabled={disabled}
            onClick={() => {
                if (datasource.additionalPrice > 0) {

                    const values = form.getState().values;

                    const i =datasource.additionalPrice - 1

                    const fields = values
                    delete fields[`${i}@additionalPrice_price`]
                    delete fields[`${i}@additionalPrice_label`]

                    form.reset(fields)


                    setDatasource({ ...datasource, additionalPrice: datasource.additionalPrice - 1 })
                }
            }}
        >-</MUIButton>
        <Typography>{datasource.additionalPrice}</Typography>
        <MUIButton
            disabled={disabled}
            variant="contained"
            onClick={() => {
                setDatasource({ ...datasource, additionalPrice: datasource.additionalPrice + 1 })
            }}
        >+</MUIButton>  </Box>)
}