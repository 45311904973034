import React, { useState, useEffect } from "react";
import {
    useListContext,
    usePermissions,
} from "react-admin";
import TextField from '@material-ui/core/TextField';

const PositionEdit = props => {
    const {data} = useListContext();
    const { category_id, id, type } = props.record;
    const [position, setPosition] = useState((data[id].position === 111111 || data[id].position === 0) ? "" : data[id].position);
    const { permissions } = usePermissions();

    useEffect(() => {
        setPosition((data[id].position === 111111 || data[id].position === 0) ? "" : data[id].position);
    },[data[id]]);

    if(!permissions) return null;
    const permissionsList = permissions.split(",");

    const onBlurHandler = () => {
        if (position !== data[id].position) {
            submitHandler();
        }
    };
    const submitHandler = e => {
        typeof e !== 'undefined' && e.preventDefault();
        //removes product type from id
        const product_id = id ? id.split("_")[0] : null;
        const data = { category_id, product_id, position, type};
        props.updatePosition(data);
    };

    const onChangeHandler = e => {
        const { value } = e.target;
        const position = (value === '0' || value === '') ? null : e.target.value;
        setPosition(position)
    };

  return <form onSubmit={submitHandler}>
      {permissionsList.includes("edit_positions") && props.record.active ?
          <TextField
              id={`position-${props.record.id}`}
              key={`position-${props.record.id}`}
              value={position}
              onBlur={onBlurHandler}
              onChange={onChangeHandler}
              type="number"
              variant="outlined"
              inputProps={{min: "1"}}
          /> :
          <TextField
              id={`position-${props.record.id}`}
              key={`position-${props.record.id}`}
              value={position}
              variant="outlined"
              disabled
          />
      }
  </form>
};

export default PositionEdit;